import _ from 'lodash'

import {
  Column,
  ColumnCreation,
  Feed,
  ColumnSeedState,
  isNewsFeedDataSemanticallyIdentical,
  NewsFeedColumn,
  NewsFeedPost,
  normalizeColumns,
  FeedVisibility,
} from '@devhub/core'
import { FeedWithPostsResponse, Reducer } from '../types'
import immer from 'immer'
import {
  StringToDataExpressionWrapper,
  convertFeedResponseSubSourcesToSources,
} from '../helpers/column'
import { COLUMN_POSTS_LIMIT } from '../../utils/constants'
import { isNewerTimestamp, isOlderTimestamp } from '../../utils/time'
import { STATIC_COLUMNS, STATIC_COLUMN_IDS } from '../../constants'

export interface State {
  /////////////////////////////////////
  //          Column Related         //
  /////////////////////////////////////

  // All user subscribed column ids, each id is a hex string. The rendering order will be the
  // same as the list order.
  allColumnIds: string[]

  // All shared column ids
  sharedColumnIds: string[]

  // User favorite feed ids
  favoriteFeedIds: string[]

  // byId maps the hex string column id to the Column type, where details of the
  // Column such as column header, type, are defined. Note that this is only a
  // definition of the column, the mapping between column->data are defined in
  // each Column, and actual data is stored in data reducer.
  columnById: Record<string, Column>

  feedById: Record<string, Feed>

  /////////////////////////////////////
  //           Data Related          //
  /////////////////////////////////////

  // Contains all data IDs, that can be referenced by multiple columns.
  allDataIds: string[]
  // Contains data id to actual data mapping.
  dataById: Record<string, NewsFeedPost>
  // Saved ID list that can be rendered together in the Saved column.
  savedDataIds: string[]
  // Last time the data list is updated.
  dataUpdatedAt: string | undefined
  // loading data id
  loadingDataId: string
  // ItemNodeId of a post which is capturing its view to the clipboard
  viewCapturingItemNodeId: string
  // if column was moved
  columnMoved: boolean
}

export const initialState: State = {
  /*===== Column Initial State =====*/
  allColumnIds: [],
  sharedColumnIds: [],
  favoriteFeedIds: [],
  columnById: {},
  feedById: {},
  /*===== Data Initial State =====*/
  allDataIds: [],
  dataById: {},
  savedDataIds: [],
  dataUpdatedAt: undefined,
  loadingDataId: '',
  viewCapturingItemNodeId: '',
  columnMoved: false,
}

export const getDefaultEmptyColumn = () => {
  const column: NewsFeedColumn = {
    id: '',
    title: '',
    updatedAt: '',
    refreshedAt: '',
    type: 'COLUMN_TYPE_NEWS_FEED',
    state: 'loaded',
    options: {
      enableAppIconUnreadIndicator: false,
      webNotification: false,
      mobileNotification: false,
    },
    icon: {
      family: '',
      name: '',
    },
    visibility: 'PRIVATE',
    subscriberCount: 1,
    feedIds: [],
    itemListIds: [],
    oldestItemId: '',
    newestItemId: '',
    delayedNewItemIds: [],
    showDelayedNewItems: true,
  }
  return column
}

export const getDefaultEmptyPost = () => {
  const post: NewsFeedPost = {
    id: '',
    cursor: 0,
  }
  return post
}

function insertNewsFeedDataIfNotExist(
  allDataIds: string[],
  dataById: Record<string, NewsFeedPost>,
  singleData: NewsFeedPost,
  forceUpdate?: boolean,
): void {
  // Insert data itself
  if (singleData.id in dataById && !forceUpdate) return
  dataById[singleData.id] = singleData
  allDataIds.push(singleData.id)

  // Insert repost data.
  if (!!singleData.repostedFrom) {
    insertNewsFeedDataIfNotExist(
      allDataIds,
      dataById,
      singleData.repostedFrom,
      forceUpdate,
    )
  }

  // Insert thread data.
  if (!!singleData.thread && singleData.thread.length != 0) {
    saveNewsFeedPostsData(allDataIds, dataById, singleData.thread, forceUpdate)
  }
}

function saveNewsFeedPostsData(
  allDataIds: string[],
  dataById: Record<string, NewsFeedPost>,
  data: NewsFeedPost[],
  forceUpdate?: boolean,
) {
  for (const singleData of data) {
    insertNewsFeedDataIfNotExist(allDataIds, dataById, singleData, forceUpdate)
  }
}

// update oldest and newest id by posts' published time
// it scans all data and their duplicated data
function updateColumnOldestAndNewestIds(
  column: NewsFeedColumn,
  dataById: Record<string, NewsFeedPost>,
  data: NewsFeedPost[],
) {
  data.forEach((post) => {
    if (
      !column.newestItemId ||
      !dataById[column.newestItemId] ||
      isNewerTimestamp(post.postTime, dataById[column.newestItemId]?.postTime)
    ) {
      column.newestItemId = post.id
    }
    if (
      !column.oldestItemId ||
      !dataById[column.oldestItemId] ||
      isOlderTimestamp(post.postTime, dataById[column.oldestItemId]?.postTime)
    ) {
      column.oldestItemId = post.id
    }

    // also need to check their duplicate ids
    if (post.duplicateIds) {
      post.duplicateIds.forEach((id) => {
        const post = dataById[id]
        if (
          !column.newestItemId ||
          !dataById[column.newestItemId] ||
          isNewerTimestamp(
            post?.postTime,
            dataById[column.newestItemId]?.postTime,
          )
        ) {
          column.newestItemId = post.id
        }
        if (
          !column.oldestItemId ||
          !dataById[column.oldestItemId] ||
          isOlderTimestamp(
            post?.postTime,
            dataById[column.oldestItemId]?.postTime,
          )
        ) {
          column.oldestItemId = post.id
        }
      })
    }
  })
}

// reset feed itemListIds based on given current columnPostIds which partially belong to the feed.
function resetFeedItems(feed: Feed, columnPostIds: string[]) {
  // construct a set for look up
  const feedOriginalPostIdSet = new Set<string>()
  feed.itemListIds.forEach((id) => feedOriginalPostIdSet.add(id))

  // calculate and update new feed post ids
  const newFeedPostIds = columnPostIds.filter((id) =>
    feedOriginalPostIdSet.has(id),
  )
  feed.itemListIds = newFeedPostIds
}

export const DEFAULT_SEMANTIC_HASHING =
  '00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'

// check if provided post is duplicate to any in provided list
export function isDuplicateToExisting(
  post: NewsFeedPost,
  existingItemIds: string[],
  dataById: Record<string, NewsFeedPost>,
  updateExisting?: boolean,
): boolean {
  let isDuplicate = false

  for (const existingDataId of existingItemIds) {
    // append of insert front based on the direction. Assuming there's no
    // overlap between returned data and original data. We don't insert the
    // same data into column item list to keep this reducer idempotent.
    if (existingDataId === post.id) {
      isDuplicate = true
      break
    }

    // If the newly fetched data is semantically identical to some
    // existing data in the column, using the existing data as the root
    // and append the new data as deduplication for the existing data.
    const existingData: NewsFeedPost = dataById[existingDataId]
    // right now the semanticHashing is all 0s, need to enable sementic
    // deduplication once it's fixed by backend
    const skipSemanticCheck = !post.embedding
    if (post.id === 'post1DupSameSemantic') {
      console.log(
        `YZ postId=${
          post.id
        }, skip: ${skipSemanticCheck}, isIdentical: ${isNewsFeedDataSemanticallyIdentical(
          existingData,
          post,
        )}`,
      )
    }
    if (
      !skipSemanticCheck &&
      isNewsFeedDataSemanticallyIdentical(existingData, post)
    ) {
      isDuplicate = true
      if (updateExisting) {
        if (!existingData.duplicateIds) existingData.duplicateIds = []
        if (!existingData.duplicateIds.includes(post.id))
          existingData.duplicateIds?.push(post.id)
        // based on read status, if at least one similar message is unread, set it as false, otherwise set it as true
        if (!post.isRead && existingData.isDuplicationRead) {
          existingData.isDuplicationRead = false
        }
      }
      break
    }
  }
  return isDuplicate
}

// Insert data into column if:
// 1. it isn't already existed in column
// 2. it isn't semantically identical to any data in the column.
//    - in this case, add the data as children to the existing data.
// 3. it doesn't check duplicate ids amonog data because convertColumnsResponseToNewsFeedPosts()
//    handles removing duplicate ids
function insertDataIntoColumn(
  column: NewsFeedColumn,
  data: NewsFeedPost[],
  direction: string,
  draft: State,
): void {
  // Because:
  // 1. Returned batch is always in chronological order, and
  // 2. We are appending/prepending one by one
  // we need to reorder the returning data when direction is new so that oldest
  // message get processed first.
  // e.g. if we received [now, 1m, 2m, 3m] from backend, and direction is new,
  // without process the incoming data first, we would prepend them one by one
  // and result in: [3m, 2m, 1m, now, ...existing data...], which is wrong.
  const reorderedData = data.slice()
  if (direction == 'NEW') {
    reorderedData.reverse()
  }
  for (let i = 0; i < reorderedData.length; i++) {
    const newData = reorderedData[i]
    const shouldPushIntoColumn = !isDuplicateToExisting(
      newData,
      column.itemListIds,
      draft.dataById,
      true,
    )
    // let shouldPushIntoColumn = true
    // for (const existingDataId of column.itemListIds) {
    //   // append of insert front based on the direction. Assuming there's no
    //   // overlap between returned data and original data. We don't insert the
    //   // same data into column item list to keep this reducer idempotent.
    //   if (existingDataId === newData.id) {
    //     shouldPushIntoColumn = false
    //     break
    //   }

    //   // If the newly fetched data is semantically identical to some
    //   // existing data in the column, using the existing data as the root
    //   // and append the new data as deduplication for the existing data.
    //   const existingData: NewsFeedPost = draft.dataById[existingDataId]
    //   // right now the semanticHashing is all 0s, need to enable sementic
    //   // deduplication once it's fixed by backend
    //   const skipSemanticCheck =
    //     newData.semanticHashing ===
    //     '00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
    //   if (
    //     !skipSemanticCheck &&
    //     isNewsFeedDataSemanticallyIdentical(existingData, newData)
    //   ) {
    //     if (!existingData.duplicateIds) existingData.duplicateIds = []
    //     if (!existingData.duplicateIds.includes(newData.id))
    //       existingData.duplicateIds?.push(newData.id)
    //     // based on read status, if at least one similar message is unread, set it as false, otherwise set it as true
    //     if (!newData.isRead && existingData.isDuplicationRead) {
    //       existingData.isDuplicationRead = false
    //     }
    //     shouldPushIntoColumn = false
    //     break
    //   }
    // }

    // Finally, this is a new data and should be inserted into the frond/end of
    // column, based on direction.
    if (shouldPushIntoColumn) {
      if (direction == 'NEW') {
        column.itemListIds.unshift(newData.id)
      } else {
        column.itemListIds.push(newData.id)
      }
    }
  }
}

// update posts readstatus as read in a column based on received readed post ids
function updatePostsReadStatus(
  itemListIds: string[],
  readed: string[],
  dataById: Record<string, NewsFeedPost>,
) {
  const readedSet = new Set(readed)
  itemListIds
    .filter((id) => readedSet.has(id))
    .forEach((id) => {
      if (dataById[id]) {
        dataById[id].isRead = true
      }
    })
}

export const columnsReducer: Reducer<State> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'ADD_COLUMN':
      return immer(state, (draft) => {
        // Initialize state byId it's not already initialized.
        draft.columnById = draft.columnById || {}

        // Get normalized state expression for the action payload, which
        // basically converts from the action payload to actual state.
        const normalized = normalizeColumns([{ ...action.payload }])

        // Must only contain a single column id.
        if (!(normalized.allIds.length === 1)) return

        // Is id exists, this is an attribute modification and we should replace
        // the value with new payload and return.
        if (draft.allColumnIds.includes(normalized.allIds[0])) {
          draft.columnById[normalized.allIds[0]] =
            normalized.byId[normalized.allIds[0]]
          return
        }
        draft.allColumnIds.push(normalized.allIds[0])
        _.merge(draft.columnById, normalized.byId)
      })

    case 'DELETE_COLUMN':
      return immer(state, (draft) => {
        if (draft.allColumnIds)
          draft.allColumnIds = draft.allColumnIds.filter(
            (id) => id !== action.payload.columnId,
          )
        if (
          draft.columnById &&
          draft.columnById[action.payload.columnId]?.visibility === 'PRIVATE'
        )
          delete draft.columnById[action.payload.columnId]
      })

    case 'MOVE_COLUMN':
      return immer(state, (draft) => {
        if (!draft.allColumnIds) return
        // search column is not movable
        if (action.payload.columnId === STATIC_COLUMNS.SEARCH_COLUMN) return

        const currentIndex = draft.allColumnIds.findIndex(
          (id) => id === action.payload.columnId,
        )
        if (!(currentIndex >= 0 && currentIndex < draft.allColumnIds.length))
          return

        const newIndex = Math.max(
          1, // 0 is search column, not movable
          Math.min(action.payload.columnIndex, draft.allColumnIds.length - 1),
        )
        if (Number.isNaN(newIndex)) return

        // move column inside array
        const columnId = draft.allColumnIds[currentIndex]
        draft.allColumnIds = draft.allColumnIds.filter((id) => id !== columnId)
        draft.allColumnIds.splice(newIndex, 0, columnId)
        draft.columnMoved = true
      })
    case 'UPDATE_SEED_STATE':
      return immer(state, (draft) => {
        let columnSeedStates = action.payload.columnSeedState
        // check if columns order was just changed, columns order in feedSeedState and
        // userSeedState could be outdated, in order to avoid order flipping during rendering
        // recover columns order in store
        if (draft.columnMoved) {
          if (columnSeedStates.length === draft.allColumnIds.length) {
            const sameIds = columnSeedStates
              .map((f) => draft.allColumnIds.indexOf(f.id) > -1)
              .reduce((prev, cur) => prev && cur)
            const sameOrder = columnSeedStates
              .map((f, idx) => f.id === draft.allColumnIds[idx])
              .reduce((prev, cur) => prev && cur)
            if (!sameOrder && sameIds) {
              // restore columns order from local store
              // corner case: same number of columns but different column id
              console.log('will restore columns order from local')
              const feedSeedStatesColumnsOrderRecovered: ColumnSeedState[] = []
              draft.allColumnIds.forEach((id) => {
                const column = columnSeedStates.find((o) => o.id === id)
                if (column) {
                  feedSeedStatesColumnsOrderRecovered.push(column)
                } else {
                  console.error(
                    'unexpected invalid column during column order recovery',
                  )
                }
              })
              columnSeedStates = feedSeedStatesColumnsOrderRecovered
            } else {
              // different ids or same order
              draft.columnMoved = false
            }
          } else {
            // complicated case, reset columnMoved and allow order flipping
            draft.columnMoved = false
          }
        }

        const newAllIds = columnSeedStates.map((v) => v.id)

        // Get all added columns as objects
        const addColumns = columnSeedStates.filter(
          (v) => !draft.allColumnIds.includes(v.id),
        )

        // All existing feeds that might require update on feed seed state
        const updateColumns = columnSeedStates.filter(
          (v) => draft.allColumnIds.includes(v.id) && newAllIds.includes(v.id),
        )

        // Get all deleted column as ids except static ones
        const delIds = draft.allColumnIds.filter(
          (v) =>
            !newAllIds.includes(v) &&
            !draft.sharedColumnIds.includes(v) &&
            !STATIC_COLUMN_IDS.includes(v),
        )

        // Only update when the ids in feed change. It should:
        // 1. substitude the ids
        // 2. remove deleted ones
        // 3. update common feeds
        // 4. add new ids
        if (!draft.allColumnIds.includes(STATIC_COLUMNS.SEARCH_COLUMN)) {
          if (!_.isEqual(newAllIds, draft.allColumnIds)) {
            draft.allColumnIds = newAllIds
          }
        } else {
          const nonStaticColumnIds = draft.allColumnIds.filter(
            (id) => id !== STATIC_COLUMNS.SEARCH_COLUMN,
          )
          if (!_.isEqual(newAllIds, nonStaticColumnIds)) {
            draft.allColumnIds = [STATIC_COLUMNS.SEARCH_COLUMN, ...newAllIds]
          }
        }

        delIds.forEach((v) => {
          if (draft.columnById) delete draft.columnById[v]
        })

        updateColumns.forEach((v) => {
          if (draft.columnById[v.id]) {
            draft.columnById[v.id].title = v.name
          } else {
            console.error(`column id: ${v.id}  does not exist in store`)
          }
        })

        addColumns.forEach((v) => {
          const columnCreation: ColumnCreation = {
            title: v.name,
            type: 'COLUMN_TYPE_NEWS_FEED',
            icon: {
              // TODO(chenweilunster): Since SeedState doesn't include icon as
              // of today, for newly added feeds, we don't really know the icon
              // for them, thus using default rss-feed instead. Later we should
              // change this to be deduct from seedState once icon information
              // is included.
              family: 'material',
              name: 'rss-feed',
            },
            id: v.id,
            feedIds: [],
            itemListIds: [],
            delayedNewItemIds: [],
            showDelayedNewItems: false,
            newestItemId: '',
            oldestItemId: '',
            state: 'not_loaded',
            options: {
              enableAppIconUnreadIndicator: true,
              webNotification: false,
              mobileNotification: false,
            },
            visibility: 'PRIVATE',
            subscriberCount: 1,
          }
          const normalized = normalizeColumns([{ ...columnCreation }])

          if (!(normalized.allIds.length === 1)) return

          draft.columnById[normalized.allIds[0]] =
            normalized.byId[normalized.allIds[0]]
        })
      })
    // clear column data and set filter, request to get latest posts
    // will be triggered by saga side.
    case 'REPLACE_COLUMN_FILTER':
    case 'REPLACE_SEARCH_COLUMN_FILTER':
      return immer(state, (draft) => {
        const { columnId, filter } = action.payload
        if (draft.columnById[columnId]) {
          draft.columnById[columnId].filters = filter
          draft.columnById[columnId].itemListIds = []
          // keep delayedNewItemIds since it deserves user's attention
          // draft.columnById[columnId].delayedNewItemIds = []
          draft.columnById[columnId].newestItemId = ''
          draft.columnById[columnId].oldestItemId = ''
          draft.columnById[columnId].firstVisibleItemId = undefined
          draft.columnById[columnId].lastVisibleItemId = undefined
        }
      })

    case 'SET_COLUMN_SAVED_FILTER':
      return immer(state, (draft) => {
        const { columnId, saved, unread } = action.payload
        if (saved !== undefined) {
          if (draft.columnById[columnId]) {
            draft.columnById[columnId].filters = {
              ...draft.columnById[columnId].filters,
              saved: saved,
            }
          }
        }
        if (unread !== undefined) {
          if (draft.columnById[columnId]) {
            draft.columnById[columnId].filters = {
              ...draft.columnById[columnId].filters,
              unread: unread,
            }
          }
        }
      })
    case 'FETCH_COLUMN_DATA_REQUEST':
    case 'FETCH_SEARCH_POSTS_REQUEST':
    case 'SET_COLUMN_LOADING':
      return immer(state, (draft) => {
        const { columnId } = action.payload
        if (draft.columnById[columnId]) {
          draft.columnById[columnId].state = 'loading'
        }
      })

    case 'SET_FAVORITE_FEEDS':
      return immer(state, (draft) => {
        draft.favoriteFeedIds = action.payload.favoriteFeeds.map((f) => f.id)
        action.payload.favoriteFeeds.forEach((v) => {
          draft.feedById[v.id] = {
            ...draft.feedById[v.id],
            id: v.id,
            title: v.name,
            columnId: '', // do we really need this field?
            updatedAt: v.updatedAt,
            refreshedAt: '',
            itemListIds: [], // posts data not fetched
            isUpdate: true,
            creator: {
              id: v.creator.id,
              name: v.creator.name,
              email: v.creator.email,
            },
            dataExpression: v.filterDataExpression
              ? StringToDataExpressionWrapper(v.filterDataExpression)
              : draft.feedById[v.id].dataExpression,
            sources: v.subSources
              ? convertFeedResponseSubSourcesToSources(v.subSources)
              : draft.feedById[v.id].sources,
          }
        })
      })

    case 'SET_SHARED_COLUMNS':
      return immer(state, (draft) => {
        if (!action.payload.columns || action.payload.columns.length === 0)
          return
        draft.sharedColumnIds = action.payload.columns.map((c) => c.id)
        action.payload.columns.forEach((v) => {
          draft.columnById[v.id] = {
            ...draft.columnById[v.id],
            id: v.id,
            icon: draft.columnById[v.id]?.icon ?? v.icon,
            creator: v.creator,
            title: v.title,
            feedIds: v.feedIds,
            visibility: v.visibility,
            updatedAt: v.updatedAt,
            subscriberCount: v.subscriberCount,
            itemListIds: draft.columnById[v.id]?.itemListIds || [],
            newestItemId: draft.columnById[v.id]?.newestItemId || '',
            oldestItemId: draft.columnById[v.id]?.oldestItemId || '',
          }
        })
        action.payload.feeds.forEach((v) => {
          draft.feedById[v.id] = {
            ...draft.feedById[v.id],
            id: v.id,
            title: v.name,
            columnId: '', // do we really need this field?
            updatedAt: v.updatedAt,
            refreshedAt: '',
            itemListIds: [], // posts data not fetched
            isUpdate: true,
            creator: {
              id: v.creator.id,
              name: v.creator.name,
              email: v.creator.email,
            },
            visibility: v.visibility,
            dataExpression: v.filterDataExpression
              ? StringToDataExpressionWrapper(v.filterDataExpression)
              : draft.feedById[v.id].dataExpression,
            sources: v.subSources
              ? convertFeedResponseSubSourcesToSources(v.subSources)
              : draft.feedById[v.id].sources,
          }
        })
      })
    case 'UPDATE_COLUMN_ID': {
      return immer(state, (draft) => {
        const { prevId, updatedId } = action.payload
        if (prevId === updatedId) {
          // This could happen when updating feed, where same feedId is returned
          return
        }
        const idx = draft.allColumnIds.indexOf(prevId)
        if (idx === -1) {
          console.error('cannot find the original id: ' + prevId)
          return
        }
        draft.allColumnIds[idx] = updatedId
        draft.columnById[updatedId] = draft.columnById[prevId]
        draft.columnById[updatedId].id = updatedId
        delete draft.columnById[prevId]
      })
    }

    case 'UPDATE_FEED': {
      return immer(state, (draft) => {
        const {
          prevId,
          updatedId,
          prevColumnId,
          updatedColumnId,
          name,
          filterDataExpression,
          subSources,
          visibility,
          addToColumn,
        } = action.payload
        const currentTimeStamp = new Date().toISOString()
        // update feed id
        if (prevId !== updatedId) {
          // new feed case
          draft.feedById[updatedId] = draft.feedById[prevId]
          if (draft.feedById[updatedId] == null) {
            // new feed case
            draft.feedById[updatedId] = {
              id: updatedId,
              title: name,
              columnId: updatedColumnId,
              updatedAt: '',
              refreshedAt: currentTimeStamp,
              itemListIds: [],
              sources: [],
              isUpdate: false,
              visibility: visibility as FeedVisibility,
            }
          }
          draft.feedById[updatedId].id = updatedId
          delete draft.feedById[prevId]
        }
        // updated feed
        draft.feedById[updatedId].title = name
        if (filterDataExpression && filterDataExpression.length > 0) {
          draft.feedById[updatedId].dataExpression =
            StringToDataExpressionWrapper(filterDataExpression)
        }
        draft.feedById[updatedId].sources =
          convertFeedResponseSubSourcesToSources(subSources)

        // update column id
        if (prevColumnId !== updatedColumnId) {
          const idx = draft.allColumnIds.indexOf(prevColumnId)
          if (idx === -1) {
            // create feed in a new column case
            draft.allColumnIds.push(updatedColumnId)
          }
          draft.columnById[updatedColumnId] = draft.columnById[prevColumnId]
          if (draft.columnById[updatedColumnId] == null) {
            draft.columnById[updatedColumnId] = {
              id: '',
              title: '',
              updatedAt: '',
              refreshedAt: currentTimeStamp,
              state: 'not_loaded',
              options: {
                enableAppIconUnreadIndicator: false,
                webNotification: false,
                mobileNotification: false,
              },
              type: 'COLUMN_TYPE_NEWS_FEED',
              icon: { family: 'octicon', name: 'bell' },
              visibility: 'PRIVATE',
              subscriberCount: 1,
              feedIds: [],
              itemListIds: [],
              delayedNewItemIds: [],
              showDelayedNewItems: false,
              oldestItemId: '',
              newestItemId: '',
            }
          }
          draft.columnById[updatedColumnId].id = updatedColumnId
          delete draft.columnById[prevColumnId]
        }
        if (prevId !== updatedId || !prevColumnId || addToColumn) {
          draft.columnById[updatedColumnId].feedIds.push(updatedId)
        }
      })
    }

    case 'FETCH_COLUMN_DATA_SUCCESS':
      return immer(state, (draft) => {
        const {
          columnId,
          updatedAt,
          data,
          delayedNewPosts,
          column,
          direction,
          dropExistingData,
          // dataByNodeId, ?
          mobileNotification,
          webNotification,
          enableAppIconUnreadIndicator,
        } = action.payload

        const columnDraft = draft.columnById[columnId] || {}
        const refreshedAt = new Date().toISOString()

        // insert into data reducer if not already exist. This will also apply
        // to reposted data (e.g. Tweeter, Weibo), as well as thread.
        // handle column
        if (dropExistingData) {
          columnDraft.itemListIds = []
          // if don't reset oldestItemId, oldestItemId will not be updated in updateColumnOldestAndNewestIds
          // and result in 0 cursor when constructing fetch column request if outdated oldestItemId's data
          // is cleaned from store
          columnDraft.oldestItemId = ''
          columnDraft.newestItemId = ''
          columnDraft.firstVisibleItemId = undefined
          columnDraft.lastVisibleItemId = undefined
          columnDraft.delayedNewItemIds = []
          columnDraft.showDelayedNewItems = false
        }

        columnDraft.updatedAt = updatedAt
        columnDraft.refreshedAt = refreshedAt
        columnDraft.state = 'loaded'
        columnDraft.options = {
          mobileNotification,
          webNotification,
          enableAppIconUnreadIndicator,
        }
        columnDraft.feedIds = column.feeds.map(
          (feed: FeedWithPostsResponse) => feed.id,
        )

        saveNewsFeedPostsData(
          draft.allDataIds,
          draft.dataById as Record<string, NewsFeedPost>,
          [...data, ...delayedNewPosts],
        )

        // filter out delayed new post if direction is NEW
        // it needs to be after saving posts data into dataById
        // and before updating column's oldest/newest items and itemListIds
        if (delayedNewPosts.length > 0) {
          if (direction === 'OLD') {
            console.error(
              'should not have delayed new posts when direction is OLD',
            )
          } else {
            let delayedNewPostIds = delayedNewPosts.map((post) => post.id)
            delayedNewPostIds = [
              ...(columnDraft.delayedNewItemIds || []),
              ...delayedNewPostIds,
            ]
            // filter out duplicate
            columnDraft.delayedNewItemIds = delayedNewPostIds.filter(
              (id, idx) => delayedNewPostIds.indexOf(id) === idx,
            )
          }
        }

        // updating depends on dataById, do following after data inserted into dataById
        updateColumnOldestAndNewestIds(
          columnDraft as NewsFeedColumn,
          draft.dataById as Record<string, NewsFeedPost>,
          data,
        )

        insertDataIntoColumn(
          columnDraft as NewsFeedColumn,
          data,
          direction,
          draft as State,
        )

        // mark as readed for items in the column, it will not mark delayed new posts
        if (column.readed && column.readed.length > 0) {
          updatePostsReadStatus(
            columnDraft.itemListIds,
            column.readed,
            draft.dataById as Record<string, NewsFeedPost>,
          )
        }

        // handle feeds
        column.feeds.forEach((feedUpdate: FeedWithPostsResponse) => {
          let localFeed = draft.feedById[feedUpdate.id]

          if (!localFeed) {
            draft.feedById[feedUpdate.id] = {
              id: feedUpdate.id,
              title: feedUpdate.name,
              columnId,
              updatedAt: feedUpdate.updatedAt,
              refreshedAt: refreshedAt,
              itemListIds: [],
              sources: [],
              isUpdate: true,
              creator: feedUpdate.creator,
              visibility: feedUpdate.visibility,
            }
            localFeed = draft.feedById[feedUpdate.id]
          }

          // if data expression or sources is returned, update them.
          localFeed.title = feedUpdate.name
          localFeed.dataExpression = feedUpdate.filterDataExpression
            ? StringToDataExpressionWrapper(feedUpdate.filterDataExpression)
            : localFeed.dataExpression
          localFeed.sources = feedUpdate.subSources
            ? convertFeedResponseSubSourcesToSources(feedUpdate.subSources)
            : localFeed.sources
          localFeed.visibility = feedUpdate.visibility
          localFeed.updatedAt = feedUpdate.updatedAt
        })

        // update reachEnd flag
        columnDraft.reachEnd = data.length === 0
      })

    case 'FETCH_SEARCH_POSTS_SUCCESS':
      return immer(state, (draft) => {
        const { columnId, data, direction, dropExistingData } = action.payload

        const columnDraft = draft.columnById[columnId] || {}
        const currentDate = new Date().toISOString()

        // insert into data reducer if not already exist. This will also apply
        // to reposted data (e.g. Tweeter, Weibo), as well as thread.
        // handle column
        if (dropExistingData) {
          columnDraft.itemListIds = []
          // if don't reset oldestItemId, oldestItemId will not be updated in updateColumnOldestAndNewestIds
          // and result in 0 cursor when constructing fetch column request if outdated oldestItemId's data
          // is cleaned from store
          columnDraft.oldestItemId = ''
          columnDraft.newestItemId = ''
          columnDraft.firstVisibleItemId = undefined
          columnDraft.lastVisibleItemId = undefined
          columnDraft.delayedNewItemIds = []
          columnDraft.showDelayedNewItems = false
        }

        columnDraft.updatedAt = currentDate
        columnDraft.refreshedAt = currentDate
        columnDraft.state = 'loaded'
        columnDraft.options = {
          mobileNotification: false,
          webNotification: false,
          enableAppIconUnreadIndicator: false,
        }
        columnDraft.feedIds = []

        saveNewsFeedPostsData(
          draft.allDataIds,
          draft.dataById as Record<string, NewsFeedPost>,
          data,
          true,
        )

        // updating depends on dataById, do following after data inserted into dataById
        updateColumnOldestAndNewestIds(
          columnDraft as NewsFeedColumn,
          draft.dataById as Record<string, NewsFeedPost>,
          data,
        )

        insertDataIntoColumn(
          columnDraft as NewsFeedColumn,
          data,
          direction,
          draft as State,
        )

        // update reachEnd flag
        columnDraft.reachEnd = data.length === 0
      })

    case 'FETCH_COLUMN_DATA_FAILURE':
      return immer(state, (draft) => {
        const { columnId } = action.payload
        const column = draft.columnById[columnId]
        if (!column) return

        column.refreshedAt = new Date().toISOString()
        column.state = 'not_loaded'
      })
    case 'SET_ITEM_SAVED_STATUS':
      return immer(state, (draft) => {
        const { itemNodeId, save } = action.payload
        const now = new Date().toISOString()
        if (!(itemNodeId in draft.dataById)) {
          // if the item isn't in the data list, it indicates that we might
          // encountered an error and should return directly.
          console.warn(
            "trying to favorite/unfavorite an item that's not in the data list: ",
            itemNodeId,
          )
          return
        }
        const entry = draft.dataById[itemNodeId]
        entry.isSaved = save
        draft.dataUpdatedAt = now

        // update savedIds array
        if (save && !draft.savedDataIds.includes(itemNodeId)) {
          draft.savedDataIds.push(itemNodeId)
        } else if (!save && draft.savedDataIds.includes(itemNodeId)) {
          const index = draft.savedDataIds.findIndex((id) => id === itemNodeId)
          if (index > -1) {
            draft.savedDataIds.splice(index, 1)
          }
        } else {
          console.warn(`
              item ${itemNodeId} was already ${save ? 'saved' : 'unsaved'}`)
        }
      })
    case 'SET_ITEMS_READ_STATUS':
      return immer(state, (draft) => {
        const { itemNodeIds, read } = action.payload
        const now = new Date().toISOString()
        for (const itemNodeId of itemNodeIds) {
          if (!(itemNodeId in draft.dataById)) {
            // if the item isn't in the data list, it indicates that we might
            // encountered an error and should return directly.
            console.warn(
              "trying to read/unread an item that's not in the data list: ",
              itemNodeId,
            )
            continue
          }
          const entry = draft.dataById[itemNodeId]
          entry.isRead = read
          if (entry.duplicateIds != undefined) {
            // Assuming similarity is transitive
            entry.isDuplicationRead = true
          }
          draft.dataUpdatedAt = now
        }
      })
    case 'SET_ITEM_DUPLICATION_READ_STATUS':
      return immer(state, (draft) => {
        const { itemNodeId, read } = action.payload
        if (!(itemNodeId in draft.dataById)) {
          // if the item isn't in the data list, it indicates that we might
          // encountered an error and should return directly.
          console.warn(
            "trying to read/unread an item that's not in the data list: ",
            itemNodeId,
          )
          return
        }
        const entry = draft.dataById[itemNodeId]
        entry.isDuplicationRead = read
      })
    case 'FETCH_POST':
      return immer(state, (draft) => {
        const { id } = action.payload
        draft.loadingDataId = id
      })
    case 'FETCH_POST_SUCCESS':
      return immer(state, (draft) => {
        const { data } = action.payload
        // replace it if it already exists
        draft.dataById[data.id] = data
        if (!draft.allDataIds.includes(data.id)) {
          draft.allDataIds.push(data.id)
        }
        // save repostedFrom post
        if (data.repostedFrom) {
          draft.dataById[data.repostedFrom.id] = data.repostedFrom
          if (!draft.allDataIds.includes(data.repostedFrom.id)) {
            draft.allDataIds.push(data.repostedFrom.id)
          }
        }
        draft.loadingDataId = ''
      })
    case 'FETCH_POST_FAILURE':
      return immer(state, (draft) => {
        const { id } = action.payload
        draft.loadingDataId = ''
      })

    case 'UPDATE_COLUMN_VISIBLE_ITEMS':
      return immer(state, (draft) => {
        const { columnId, firstVisibleItemId, lastVisibleItemId } =
          action.payload
        if (columnId == null) {
          return
        }
        const column = draft.columnById[columnId]
        if (!column) {
          console.error('column id does not exist: ', columnId)
          return
        }
        column.firstVisibleItemId = firstVisibleItemId
        column.lastVisibleItemId = lastVisibleItemId
      })
    case 'RESET_COLUMN_VISIBLE_ITEMS':
      return immer(state, (draft) => {
        const { columnId } = action.payload
        const column = draft.columnById[columnId]
        if (!column) {
          console.error('column id does not exist: ', columnId)
          return
        }
        column.firstVisibleItemId = undefined
        column.lastVisibleItemId = undefined
      })
    case 'CAPTURE_VIEW': {
      return immer(state, (draft) => {
        draft.viewCapturingItemNodeId = action.payload.itemNodeId
      })
    }
    case 'CAPTURE_VIEW_COMPLETED': {
      return immer(state, (draft) => {
        draft.viewCapturingItemNodeId = ''
      })
    }
    case 'SET_COLUMN_NOTIFICATION_SETTINGS_SUCCESS': {
      const {
        columnId,
        enableMobileNotification,
        enableAppIconUnreadIndicator,
        enableWebNotification,
      } = action.payload
      return immer(state, (draft) => {
        if (draft.columnById[columnId]) {
          draft.columnById[columnId].options = {
            webNotification: enableWebNotification,
            enableAppIconUnreadIndicator,
            mobileNotification: enableMobileNotification,
          }
        }
      })
    }
    // update column's itemListIds and update feeds in it
    case 'CLEAN_COLUMN_OLD_POSTS': {
      const { columnId } = action.payload
      return immer(state, (draft) => {
        const column = draft.columnById[columnId]
        if (!column) {
          console.error(`cannot find column to clean, column id: ${columnId}`)
          return
        }

        const columnPostsLen = column.itemListIds.length
        if (columnPostsLen <= COLUMN_POSTS_LIMIT) {
          console.debug(
            `do nothing for post cleaning since column posts number is ${columnPostsLen}, <= threshold ${COLUMN_POSTS_LIMIT}`,
          )
          return
        }
        // remove old post ids from column
        const cleanedPostIds = column.itemListIds.splice(
          COLUMN_POSTS_LIMIT,
          columnPostsLen - COLUMN_POSTS_LIMIT,
        )
        // update column oldest & newest post ids
        // it should scan all itemListIds and posts data in duplicateIds
        // reset newest and oldest item ids, prepare current itemListIds data
        // call updateColumnOldestAndNewestIds() to update the newest and oldest item ids
        column.newestItemId = ''
        column.oldestItemId = ''
        const itemListData = column.itemListIds
          .map((id) => draft.dataById[id])
          .filter((post) => post != null)
        updateColumnOldestAndNewestIds(column, draft.dataById, itemListData)

        console.debug(
          `removed ${
            columnPostsLen - COLUMN_POSTS_LIMIT
          } post ids from column ${column.title}, newestId: ${
            column.newestItemId
          }, oldestId: ${column.oldestItemId}`,
        )
        // update feeds item list ids in column using current column posts
        column.feedIds.forEach((feedId) => {
          const feed: Feed = draft.feedById[feedId] as Feed
          if (!feed) {
            console.error(
              `feed ${feedId} not found in feedById map during column posts clearning`,
            )
            return
          }
          resetFeedItems(feed, column.itemListIds)
        })

        // remove deleted post ids from delayedNewPosts
        draft.allColumnIds.forEach((cId) => {
          const column = draft.columnById[cId]
          if (!column) {
            console.error(`column data should exist for ${cId}`)
            return
          }
          column.delayedNewItemIds = column.delayedNewItemIds.filter(
            (delayedId) => !cleanedPostIds.includes(delayedId),
          )
        })
      })
    }

    // clean posts which are not in columns, and not related to repostedFrom, parentId, duplicatedIds
    // from allDataIds and dataByIds
    case 'CLEAN_UNUSED_POSTS_DATA': {
      return immer(state, (draft) => {
        // get all posts and feeds in column along with repostedFrom and child posts
        const postIdsInColumns = new Set<string>()
        draft.allColumnIds.forEach((columnId) => {
          const column = draft.columnById[columnId]
          if (!column) {
            console.error(`cannot find column data, id: ${columnId}`)
            return
          }
          column.itemListIds.forEach((id) => {
            if (!postIdsInColumns.has(id)) {
              postIdsInColumns.add(id)
            }
            const repostedFromPostId = draft.dataById[id]?.repostedFrom?.id
            if (
              repostedFromPostId &&
              !postIdsInColumns.has(repostedFromPostId)
            ) {
              postIdsInColumns.add(repostedFromPostId)
            }
            // but it looks like parent id is not used anywhere
            const parentId = draft.dataById[id]?.parentId
            if (parentId && !postIdsInColumns.has(parentId)) {
              postIdsInColumns.add(parentId)
            }
            const duplicateIds: string[] | undefined =
              draft.dataById[id]?.duplicateIds
            if (duplicateIds) {
              duplicateIds.forEach((dupId) => {
                if (!postIdsInColumns.has(dupId)) {
                  postIdsInColumns.add(dupId)
                }
              })
            }
          })
        })

        // update allDataIds and dataById
        let deletedPostsNum = 0
        draft.allDataIds = draft.allDataIds.filter((postId) => {
          if (postIdsInColumns.has(postId)) {
            return true
          }
          delete draft.dataById[postId]
          deletedPostsNum++
          return false
        })
        // in case that some post data in dataById but not in allDataIds
        if (draft.allDataIds.length !== Object.keys(draft.dataById).length) {
          Object.keys(draft.dataById).forEach((id) => {
            if (!postIdsInColumns.has(id)) {
              delete draft.dataById[id]
              deletedPostsNum++
            }
          })
        }

        if (deletedPostsNum > 0) {
          console.debug(
            `deleted ${deletedPostsNum} posts data from store, with ${draft.allDataIds.length} left`,
          )
        }
      })
    }

    case 'CLEAN_UNUSED_FEEDS_DATA': {
      return immer(state, (draft) => {
        const feedIdsInColumns = new Set<string>()
        draft.allColumnIds.forEach((columnId) => {
          const column = draft.columnById[columnId]
          if (!column) {
            console.error(`cannot find column data, id: ${columnId}`)
            return
          }
          column.feedIds.forEach((id) => {
            if (!feedIdsInColumns.has(id)) {
              feedIdsInColumns.add(id)
            }
          })
        })
        // remove feeds from feedById if feed is not reference by any columns if size is different
        let deletedFeedsNum = 0
        if (feedIdsInColumns.size !== Object.keys(draft.feedById).length) {
          Object.keys(draft.feedById).forEach((feedId) => {
            if (!feedIdsInColumns.has(feedId)) {
              delete draft.feedById[feedId]
              deletedFeedsNum++
            }
          })
        }

        if (deletedFeedsNum > 0) {
          console.debug(
            `deleted ${deletedFeedsNum} feeds data from store, with ${feedIdsInColumns.size} left`,
          )
        }
      })
    }

    case 'TOGGLE_COLUMN_DELAYED_NEW_POSTS_VISIBILITTY': {
      return immer(state, (draft) => {
        const { columnId } = action.payload
        const column = draft.columnById[columnId]
        if (column) {
          column.showDelayedNewItems = !column.showDelayedNewItems
        }
      })
    }

    case 'TOGGLE_FEED_FAVORITE': {
      return immer(state, (draft) => {
        const { feedId, isFavorite } = action.payload
        const feed = draft.feedById[feedId]
        if (feed) {
          if (isFavorite) {
            // very unlikely that we need to check this but just in case
            if (!draft.favoriteFeedIds.includes(feedId)) {
              draft.favoriteFeedIds.push(feedId)
            }
          } else {
            draft.favoriteFeedIds.splice(
              draft.favoriteFeedIds.indexOf(feedId),
              1,
            )
          }
        }
      })
    }

    case 'CLEAN_OR_INSERT_COLUMN_DELAYED_NEW_POSTS': {
      return immer(state, (draft) => {
        const { columnId } = action.payload
        const column = draft.columnById[columnId]

        if (column) {
          // insert delayed new posts ids into column if it's new compared to oldest post in column
          // and need to do deduplication
          const newPostsToInsert = column.delayedNewItemIds
            .map((id) => draft.dataById[id])
            .filter(
              (post) =>
                post?.postTime &&
                draft.dataById[column.oldestItemId]?.postTime &&
                isNewerTimestamp(
                  post?.postTime,
                  draft.dataById[column.oldestItemId].postTime,
                ),
            )

          console.log(
            `Will insert ${newPostsToInsert.length} delayed new posts into column`,
          )

          if (newPostsToInsert.length > 0) {
            insertDataIntoColumn(
              column,
              newPostsToInsert,
              'NEW', // only NEW direction will give delayed new posts
              draft as State,
            )

            // insertDataIntoColumn always insert at one end and does not re-order
            // need to reorder here
            column.itemListIds = column.itemListIds.sort(
              (
                a,
                b, // [newest, ..., oldest]
              ) =>
                isNewerTimestamp(
                  draft.dataById[a]?.postTime,
                  draft.dataById[b]?.postTime,
                )
                  ? -1
                  : 1,
            )
          }

          // clear delayed items ids
          column.delayedNewItemIds = []
        }
        // no need to clean the posts data in dataById since we already have
        // a way to routinely clean it.
      })
    }

    case 'TOGGLE_SEARCH_COLUMN': {
      return immer(state, (draft) => {
        const searchColumnId = STATIC_COLUMNS.SEARCH_COLUMN

        // toggle the existence of column id in allColumnIds
        const searchColumnExists = draft.allColumnIds.includes(searchColumnId)
        if (searchColumnExists) {
          // remove search column id from allColumnIds
          draft.allColumnIds = draft.allColumnIds.filter(
            (id) => id !== searchColumnId,
          )
        } else {
          // add search column id to allColumnIds as the first one
          draft.allColumnIds.unshift(searchColumnId)
        }

        // add search column to columnById if not there
        if (!draft.columnById[searchColumnId]) {
          draft.columnById[searchColumnId] = {
            id: searchColumnId,
            title: 'Search',
            updatedAt: '',
            refreshedAt: '',
            state: 'not_loaded',
            options: {
              enableAppIconUnreadIndicator: false,
              webNotification: false,
              mobileNotification: false,
            },
            type: 'COLUMN_TYPE_SEARCH',
            icon: { family: 'octicon', name: 'search' },
            visibility: 'PRIVATE',
            subscriberCount: 1,
            feedIds: [],
            itemListIds: [],
            delayedNewItemIds: [],
            showDelayedNewItems: false,
            oldestItemId: '',
            newestItemId: '',
          }
        } else {
          // leave the search column data as it is
        }
      })
    }
    default:
      return state
  }
}
