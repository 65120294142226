import {
  NewsFeedDataExpressionWrapper,
  guid,
  Predicate,
  NewsFeedDataExpression,
  AllOf,
  AnyOf,
  NotTrue,
} from '@devhub/core'

const LOCAL_DEBUG_MODE = false

const AND = '&'
const OR = '|'
const NOT = '!'
const BRACKET_LEFT = '('
const BRACKET_RIGHT = ')'

type parentType = 'AND' | 'NOT' | 'OR'

type expressionType = parentType | 'PRED'

export function isPredicate(expr: NewsFeedDataExpression): expr is Predicate {
  return (<Predicate>expr).pred !== undefined
}

export function isAllOf(expr: NewsFeedDataExpression): expr is AllOf {
  return (<AllOf>expr).allOf !== undefined
}

export function isAnyOf(expr: NewsFeedDataExpression): expr is AnyOf {
  return (<AnyOf>expr).anyOf !== undefined
}

export function isNotTrue(expr: NewsFeedDataExpression): expr is NotTrue {
  return (<NotTrue>expr).notTrue !== undefined
}

const isOperator = (c: string): boolean => {
  if (c === AND || c === OR || c === NOT) {
    return true
  }
  return false
}

const isBracket = (c: string): boolean => {
  if (c === BRACKET_LEFT || c === BRACKET_RIGHT) {
    return true
  }
  return false
}

class ExpressionNode {
  public type: 'text' | 'operator' | 'empty'
  public value: string
  public children: ExpressionNode[]
  public hasBracket: boolean

  constructor(type: 'text' | 'operator' | 'empty', value: string, hasBracket = false) {
    this.type = type
    this.value = value
    this.children = []
    this.hasBracket = hasBracket
  }

  public addChild(child: ExpressionNode) {
    // for NOT case, it should always has 1 child, here we replace it
    if (this.type === 'operator' && this.value === NOT) {
      this.children = [child]
    } else {
      this.children.push(child)
    }
  }

  public toString(): string {
    return `type: ${this.type}, value: ${this.value}, children: [${
      (this.children.length > 0 &&
        this.children
          .map((child) => child.toString())
          .reduce((prev, cur) => prev + cur)) ||
      ''
    }]`
  }
}

export const logicalExpressionToDataExpression = (
  expression: string,
): NewsFeedDataExpressionWrapper => {
  if (!expression) return {}
  try {
    const expressionChars = expression.split('').reverse()
    let char = ''
    let word = ''
    const stack: ExpressionNode[] = []
    let currentNode: ExpressionNode | undefined

    // validation purpose
    let numOfLeftBracket = 0
    let numOfRightBracket = 0

    // construct node tree
    while (expressionChars.length > 0) {
      // popping up until bracket or operator
      do {
        word = word + char
        char = expressionChars.pop() || ''
      } while (char && !isBracket(char) && !isOperator(char))
      if (LOCAL_DEBUG_MODE) {
        console.debug(`expression ${expression} debug cycle start`, {
          char,
          word,
          currentNode,
          stack,
        })
      }

      if (!char) {
        // ending with word charactor
        if (!currentNode) {
          // only text case e.g. 'a'
          currentNode = new ExpressionNode('text', word)
          // if parent exists, add current node to parent
          if (stack.length > 0) {
            stack[stack.length - 1].addChild(currentNode)
          }
        } else {
          // add word to current node
          currentNode.addChild(new ExpressionNode('text', word))
          // recursively add current node to parent node
          while (stack.length > 0 && currentNode) {
            const parentNode = stack.pop()
            if (parentNode) {
              parentNode.addChild(currentNode)
            }
            currentNode = parentNode
          }
        }

        // continue?
        continue
      }

      // ===== case that char is not empty =====
      if (isOperator(char)) {
        // if operator is OR, push current node to stack unless char is also OR
        if (currentNode?.type === 'operator' && currentNode.value === OR && currentNode.value !== char) {
          stack.push(currentNode)
          currentNode = undefined
        }
        // if current node is there, add word to currrent node, create a parent add currrent node as child of parent node
        // use parent node as current node
        if (currentNode) {
          if (word) {
            currentNode.addChild(new ExpressionNode('text', word))
            if (currentNode.value === NOT && stack.length > 0) {
              const poppedNode = stack.pop()
              if (poppedNode) {
                poppedNode.addChild(currentNode)
                currentNode = poppedNode
              } else {
                console.error('poppedNode should not be invalid')
              }
            }
          } else {
            // word is empty, this case happens when left is a bracket, e.g. (a|b)&c
            // todo: throw error for case a&&b
            if (char === NOT) {
              // a&!b
              stack.push(currentNode)
            }
          }

          // add parent node to current node if char is differnt than parent's operation
          if (currentNode.value !== char) {
            // if there's no parent in stack or current char operation should be the parent
            // set current char as the parent
            let parentNode: ExpressionNode = new ExpressionNode('operator', char)
            if (stack.length > 0) {
              if (char === OR) {
                // keep popping up AND parents, this logic might have some problems for some corner cases
                let poppedAndNode: ExpressionNode | undefined
                while (stack.length > 0 && stack[stack.length - 1].value === AND) {
                  const popped = stack.pop()
                  if (popped) {
                    poppedAndNode = popped
                  }
                }
                if (stack.length > 0) {
                  parentNode = stack[stack.length - 1]
                } else {
                  // if no poppedAndNode do nothing here, this will result in the
                  // initialization case of adding a new root node,
                  // if poppedAndNode exists, add it as the child of the new parent node
                  if (poppedAndNode) {
                    parentNode.addChild(poppedAndNode)
                    poppedAndNode.addChild(currentNode)
                    currentNode = undefined
                  }
                }
              } else {
                parentNode = new ExpressionNode('operator', char)
              }
            }
            if (currentNode) {
              parentNode.addChild(currentNode)
            }
            currentNode = parentNode
  
            // for OR, push current node into stack
            if (char === OR) {
              stack.push(currentNode)
              currentNode = undefined
            }
          } else {
            // do nothing here since the char is same as parent, keep currentNode unchanged
          }
        } else {
          currentNode = new ExpressionNode('operator', char)
          if (word) {
            currentNode.addChild(new ExpressionNode('text', word))
          } else {
            if (char === NOT) {
              // do nothing about the word, since word is to be read next cycle
            } else {
              throw new Error(
                `& or | should have a text before it, operation: ${char} , text: ${word}`,
              )
            }
          }
        }
      } else if (isBracket(char)) {
        if (char === BRACKET_LEFT) {
          numOfLeftBracket++
          if (currentNode) {
            stack.push(currentNode)
            currentNode = undefined
          }
        } else if (char === BRACKET_RIGHT) {
          // BRACKET_RIGHT
          numOfRightBracket++
          if (currentNode) {
            currentNode.addChild(new ExpressionNode('text', word))
            const parentNode = stack.pop()

            if (parentNode) {
              parentNode.addChild(currentNode)
              parentNode.hasBracket = true
              currentNode = parentNode
            } else {
              // case (a&b)
            }
          } else {
            throw new Error('right bracket should have valid node on left')
          }
        } else {
          throw new Error(`unexpected none text char: ${char}`)
        }
      }

      // reset
      char = ''
      word = ''

      if (LOCAL_DEBUG_MODE) {
        console.debug(`expression ${expression} debug cycle end`, {
          char,
          word,
          currentNode,
          stack,
          currentNodeChildren: currentNode?.children && currentNode?.children.length > 0 &&
          currentNode?.children.map(item => item.toString()).reduce((prev, cur) => prev + ', ' + cur),
          nodesInStack: stack.length > 0 && stack.map(item => item.toString()).reduce((prev, cur) => prev + ', ' + cur)
        })
      }
    }

    // validation
    if (numOfLeftBracket !== numOfRightBracket) {
      throw new Error(
        `numbers of left and right brackets don't match, left: ${numOfLeftBracket}, right: ${numOfRightBracket}`,
      )
    }

    // todo for the logicalExpression14
    // if expression is incomplete, need to handle currentNode, e.g. !a&!b&
    // if (currentNode) {
    //   // AND OR cases
    //   if ([AND, OR].includes(currentNode.value) && currentNode.children.length === 1) {
    //     console.log('YZ a')
    //     currentNode.addChild(new ExpressionNode('empty', ''))
    //   } else if(currentNode.value === NOT && currentNode.children.length === 0) {
    //     console.log('YZ b')
    //     currentNode.addChild(new ExpressionNode('empty', ''))
    //   } else {
    //     console.log('YZ c')
    //     // currnet node is complete, no need to add any ampty child node
    //   }
    // }

    // get root node
    const rootNode = (stack.length && stack[0]) || currentNode

    // convert root node tree to NewsFeedDataExpressionWrapper
    const dataExpression = convertNodeTreeToDataExpression(rootNode)
    return dataExpression
  } catch (e) {
    console.error('logicalExpressionToDataExpression exception', e)
    return {}
  }
}

export const appendEmptyNodeToDataExpression = (
  exprWrapper: NewsFeedDataExpressionWrapper,
): NewsFeedDataExpressionWrapper => {
  const expr = exprWrapper.expr
  if (!expr) return exprWrapper
  if (isPredicate(expr)) {
    return exprWrapper
  } else if (isAllOf(expr)) {
    const emptyWrapper: NewsFeedDataExpressionWrapper = { id: guid() }
    expr.allOf.push(emptyWrapper)
  } else if (isAnyOf(expr)) {
    const emptyWrapper: NewsFeedDataExpressionWrapper = { id: guid() }
    expr.anyOf.push(emptyWrapper)
  } else if (isNotTrue(expr)) {
  } else {
    console.error('unexpected data experssion type, expression:', expr)
  }
  return exprWrapper
}

function convertNodeTreeToDataExpression(
  node: ExpressionNode | undefined,
): NewsFeedDataExpressionWrapper {
  if (!node) {
    return {}
  }
  if (node.type === 'operator') {
    const childrenExpressions: NewsFeedDataExpressionWrapper[] =
      node.children.map((node) => convertNodeTreeToDataExpression(node))
    let expression: AllOf | AnyOf | NotTrue
    switch (node.value) {
      case AND:
        const allOf: AllOf = {
          allOf: childrenExpressions,
        }
        expression = allOf
        break
      case OR:
        const anyOf: AnyOf = {
          anyOf: childrenExpressions,
        }
        expression = anyOf
        break
      case NOT:
        const notTrue: NotTrue = {
          notTrue: childrenExpressions[0],
        }
        expression = notTrue
        break
      default:
        return {}
        break
    }
    return {
      id: guid(),
      expr: expression,
    }
  } else if (node.type === 'text') {
    const predicate: Predicate = {
      pred: {
        type: 'LITERAL',
        param: { text: node.value },
      },
    }
    return {
      id: guid(),
      expr: predicate,
    }
  } else {
    // empty
    return {
      id: guid(),
    }
  }
}

// need to add bracket if following node:
// 1. node has multiple children and is a child of NOT
// 2. node is OR with multiple children and is a child of AND
export const dataExpressionToLogicalExpression = (
  dataExpression: NewsFeedDataExpressionWrapper,
  parentType?: parentType,
): string => {
  if (!dataExpression?.expr) return ''
  const expr = dataExpression.expr
  let exprStr = ''
  let exprType: expressionType = 'PRED' // default too PRED
  let exprChildrenNum = 0

  try {
    if (isPredicate(expr)) {
      exprStr = expr.pred.param?.text || ''
      exprChildrenNum = 1
    } else if (isAllOf(expr)) {
      exprType = 'AND'
      const exprs: NewsFeedDataExpressionWrapper[] = expr.allOf
      // no need to filter out empty expression in order to support empty node, which is used as add button
      // in rendered tree
      // .filter(
      //   (e) => e.expr != null,
      // )
      exprChildrenNum = exprs.length
      if (exprs.length > 0) {
        exprStr = exprs
          .map((e) => dataExpressionToLogicalExpression(e, 'AND'))
          .reduce((prev, cur) => `${prev}&${cur}`)
      }
    } else if (isAnyOf(expr)) {
      exprType = 'OR'
      const exprs: NewsFeedDataExpressionWrapper[] = expr.anyOf
      // no need to filter out empty expression in order to support empty node, which is used as add button
      // in rendered tree
      // .filter(
      //   (e) => e.expr != null,
      // )
      exprChildrenNum = exprs.length
      if (exprs.length > 0) {
        exprStr = exprs
          .map((e) => dataExpressionToLogicalExpression(e, 'OR'))
          .reduce((prev, cur) => `${prev}|${cur}`)
      }
    } else if (isNotTrue(expr)) {
      exprType = 'NOT'
      const notTrueExpr: NewsFeedDataExpressionWrapper = expr.notTrue
      exprChildrenNum = 1
      exprStr = `!${dataExpressionToLogicalExpression(notTrueExpr, 'NOT')}`
    } else {
      console.error(
        'unexpected data experssion type, expression:',
        dataExpression,
      )
    }

    // add bracket logic
    if (
      (parentType === 'NOT' && exprChildrenNum > 1) ||
      (parentType === 'AND' && exprType === 'OR' && exprChildrenNum > 1)
    ) {
      return `(${exprStr})`
    }
  } catch (e) {
    console.error('dataExpressionToLogicalExpression exception ', e)
  }

  // remove last char if expression ending wih AND, OR, NOT, BRACKET_LEFT
  if (exprStr.length > 0) {
    const lastChar = exprStr[exprStr.length - 1]
    if ([AND, OR, NOT, BRACKET_LEFT].includes(lastChar)) {
      exprStr = exprStr.substring(0, exprStr.length - 1)
    }
  }

  return exprStr
}

// invalid cases:
// 1. left and right brackets numbera do not match
// 2. bracket right comes before left
// 3. adjacent operators except *!
// 4. end with one of following ! | & (
export const isLogicalExpressionValid = (expression: string): boolean => {
  try {
    // exam expression directly to check its validity
    const chars = expression.split('')
    let numOfLeftBracket = 0
    let numOfRightBracket = 0
    for (let i = 0, prevChar = ''; i < chars.length; i++) {
      const char = chars[i]
      if (char === NOT) {
        if (prevChar === NOT || ![AND, OR, BRACKET_LEFT, BRACKET_RIGHT, ''].includes(prevChar)) {
          return false
        }
      } else if (
        isOperator(char) &&
        isOperator(prevChar)
      ) {
        console.debug('invalid logical expression: adjacent operators')
        return false
      }
      if (char === BRACKET_LEFT) numOfLeftBracket++
      if (char === BRACKET_RIGHT) numOfRightBracket++
      if (numOfRightBracket > numOfLeftBracket) {
        console.debug(
          'invalid logical expression: bracket right comes before left',
        )
        return false
      }
      prevChar = char
    }
    if (numOfLeftBracket !== numOfRightBracket) {
      console.debug(
        'invalid logical expression: left and right brackets numbera do not match',
      )
      return false
    }

    const endingChar = expression[expression.length - 1]
    if (['!', '|', '&', '('].includes(endingChar)) {
      return false
    }
  } catch (e) {
    console.error('isLogicalExpressionValid exception', e)
    return false
  }

  return true
}

export const normalizeLogicalExpression = (expression: string): string => {
  return expression
    .replace('()', '')
    .replace('&&', '&')
    .replace('||', '|')
    .replace('!!', '')
    .replace(' ', '')
}
