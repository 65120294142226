// https://docs.influxdata.com/influxdb/cloud/write-data/developer-tools/api/?t=Node.js

/**
 * @description InfluxDB API Measurement names
 */
export enum Measurement {
  ColumnSearch = 'column_search',
  // Usage case related e.g. GlobalSearch = 'global_search',
}

/**
 *
 * Best practices
 * - Use Fields for Measurable Data: Actual measurable data, like CPU usage,
 *    temperature readings, and response times, should be stored as fields.
 *    This data is usually what you're performing mathematical operations on
 *    (like SUM, MEAN, etc.).
 * - Limit the Number of Fields: Although fields are not indexed, having a very
 *    large number of fields can still impact query performance. It's usually a
 *    good practice to have a reasonable number of fields per measurement.
 *    Remember, the key to a successful schema design in InfluxDB is understanding
 *    the characteristics and trade-offs of fields and tags, and aligning your design
 *    with the requirements and access patterns of your application.
 */
export enum Field {
  Latency = 'latency', // float, milliseconds
  Count = 'count', // integer, count
  Error = 'error', // integer, count
  // Success = 'success',
}

/**
 * - Tags are always stored as strings.
 * - Tags are indexed. This allows for much faster querying when
 *    filtering or grouping by tag values.
 * - Tags are immutable. Once a data point with a particular tag
 *    is written, its value cannot be changed without rewriting the data point.
 * - Tags contribute to the series cardinality in a database. A unique
 *    combination of measurement + tag set creates a new "series" in InfluxDB.
 *    High series cardinality can impact performance and memory usage.
 *
 * Best practices
 * - Use Tags for Metadata: Common metadata that is frequently queried and
 *    is often part of the WHERE clause in queries should be stored as tags.
 *    Examples include host names, device IDs, region names, etc.
 * - Avoid High Cardinality with Tags: Be cautious about using tags that can
 *    have a large number of unique values, like user IDs or timestamps.
 *    High cardinality can negatively impact InfluxDB's performance and memory usage.
 * - Limit Tag Values: Even though tags are indexed and fast to query on, having
 *    too many unique tag values (high cardinality) can lead to performance and
 *    memory issues. Avoid using tags for data with a large or unpredictable set
 *    of unique values.
 */
export enum Tag {
  Platform = 'platform',
  UserId = 'user_id',
  UserName = 'username',
  Keyword = 'keyword',
}
