import { constants } from '@devhub/core'

export const EMPTY_ARRAY = constants.EMPTY_ARRAY
export const EMPTY_OBJ = constants.EMPTY_OBJ

export const COLUMN_POSTS_LIMIT = 60

export const MAX_HTML_TITLE_LENGTH = 50
export const MAX_HTML_DESCRIPTION_LENGTH = 200

export const SCROLL_WAIT_MS = 320
export const NOTIFICATION_VISIBLE_DURATION_MS = 1000
export const NOTIFICATION_LOCAL_MSG_RESET_DELAY_MS = 200

export type SlackChannel =
  | 'cannabis'
  | 'cloud'
  | 'cloud_讨论'
  | 'covid'
  | 'crypto'
  | 'general'
  | 'new_energy'
  | 'taiwan'
  | 'internet'
  | 'random'
  | 'ukraine'

const PANORAMICHILLS_WORKSPACE_URL = `https://hooks.slack.com/services/T02FFM6U950/`
export const SLACK_WEBHOOKS: { [k in SlackChannel]: string } = {
  cannabis: `${PANORAMICHILLS_WORKSPACE_URL}B04N3BR3NGK/vZIpAGqcZCdeTWME1UI7dBro`,
  cloud: `${PANORAMICHILLS_WORKSPACE_URL}B04N3BRCYM9/gmFIsDfULREHPUBbAoZq5FVI`,
  cloud_讨论: `${PANORAMICHILLS_WORKSPACE_URL}B04N3F3DBGA/9izJru2KJPFcxkRWwpk5dTEF`,
  covid: `${PANORAMICHILLS_WORKSPACE_URL}B04NT62KBME/b83Q2ez8yJe9tI4W9SH7TXSO`,
  crypto: `${PANORAMICHILLS_WORKSPACE_URL}B04MNUSBHDM/lPGE8zayksPJiAr2iAyUPxcN`,
  general: `${PANORAMICHILLS_WORKSPACE_URL}B04MWSUEK8W/070ukmGu5Ic8dELAHYadfqyt`,
  new_energy: `${PANORAMICHILLS_WORKSPACE_URL}B04NG4GNLD7/XyEBXco3nhNiRiivwz3wZ1TT`,
  taiwan: `${PANORAMICHILLS_WORKSPACE_URL}B04N5UY9JJY/qwzM52VWqwrSFEuw0YmIueCY`,
  internet: `${PANORAMICHILLS_WORKSPACE_URL}B04NG4KEJ1F/pw8AqLqrPg5LZ8Sg3BgG3DIx`,
  random: `${PANORAMICHILLS_WORKSPACE_URL}B04MNUWCRQF/Ofep961EZZasAskpBIwEip7y`,
  ukraine: `${PANORAMICHILLS_WORKSPACE_URL}B034KLNJQH4/d9ZXqEWfpbtJ0jBZrx8x9NOR`,
}
