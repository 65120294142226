import { PixelRatio, StyleSheet } from 'react-native'
import {
  extraLargeTextSize,
  normalTextSize,
  scaleFactor,
  smallAvatarSize,
  smallerTextSize,
  smallTextSize,
} from '../../styles/variables'
import { smallLabelHeight } from '../common/Label'
import { sizes } from './BaseCard.shared'

export const baseCardStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  innerContainer: {
    paddingHorizontal: sizes.cardPaddingHorizontal,
    paddingVertical: sizes.cardPaddingVertical,
  },

  smallAvatarContainer: {
    // width: avatarSize,
    height: smallAvatarSize,
    paddingRight: 10 * scaleFactor,
  },

  fileContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10 * scaleFactor,
    justifyContent: 'flex-start',
  },

  deduplicationBarContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 6 * scaleFactor,
    justifyContent: 'flex-start',
  },

  avatarContainer: {
    width: sizes.avatarContainerWidth,
    height: sizes.avatarContainerHeight,
  },

  authorName: {
    fontSize: smallTextSize,
    lineHeight: sizes.titleLineHeight,
    flexGrow: 1,
    overflow: 'hidden',
    fontWeight: '800',
    paddingTop: 2 * scaleFactor,
  },

  avatar: { marginBottom: 5 * scaleFactor },

  iconContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    width: sizes.iconContainerSize,
    height: sizes.iconContainerSize,
    borderRadius: sizes.iconContainerSize / 2,
    borderWidth: 2 * scaleFactor,
  },

  icon: {
    marginLeft: 1 * scaleFactor,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: PixelRatio.roundToNearestPixel(
      sizes.iconContainerSize * (sizes.iconSize / sizes.iconContainerSize),
    ),
  },

  title: {
    flex: 1,
    lineHeight: sizes.titleLineHeight,
    fontSize: normalTextSize,
    fontWeight: 'bold',
  },

  subtitle: {
    flexGrow: 1,
    lineHeight: sizes.subtitleLineHeight,
    fontSize: smallerTextSize,
    // fontWeight: '400',
    overflow: 'hidden',
  },

  showMoreOrLessText: {
    lineHeight: sizes.textLineHeight,
    fontSize: smallTextSize,
    fontWeight: '300',
    overflow: 'hidden',
  },

  reason: {
    lineHeight: sizes.textLineHeight,
    fontSize: smallerTextSize,
    fontWeight: '300',
    textAlign: 'right',
  },

  timestampText: {
    lineHeight: sizes.titleLineHeight,
    fontSize: smallerTextSize,
    fontWeight: '300',
    overflow: 'hidden',
    // ...Platform.select({ web: { fontFeatureSettings: '"tnum"' } }),
  },

  actionContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    height: sizes.actionContainerHeight,
  },

  action: {
    flex: 1,
    lineHeight: sizes.actionFontSize + 2 * scaleFactor,
    fontSize: sizes.actionFontSize,
    fontWeight: '300',
    overflow: 'hidden',
  },

  labelText: {
    lineHeight: smallLabelHeight,
    fontSize: smallerTextSize,
    fontWeight: '300',
    overflow: 'hidden',
  },

  subitemContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    height: sizes.subitemContainerHeight,
  },

  subitem: {
    flex: 1,
    maxWidth: '100%',
    lineHeight: sizes.subitemLineHeight,
    fontSize: sizes.subitemFontSize,
    fontWeight: '400',
    overflow: 'hidden',
  },

  githubAppMessageContainer: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    height: sizes.githubAppMessageContainerHeight,
    minHeight: sizes.githubAppMessageContainerHeight,
  },

  githubAppMessage: {
    flexGrow: 1,
    maxWidth: '100%',
    lineHeight: sizes.subitemLineHeight,
    fontSize: sizes.subitemFontSize,
    fontWeight: '300',
    fontStyle: 'italic',
    overflow: 'hidden',
  },

  actionIcon: {
    marginLeft: 12 * scaleFactor,
  },

  marginTop6: {
    marginTop: 6 * scaleFactor,
  },

  lineHeightNormalForNormalText: {
    lineHeight: 16.352, // px, 14px font * 1.168 (normal lineHeight)
  },

  lineHeightNormalForExtraLargeText: {
    lineHeight: extraLargeTextSize,
  },
})
