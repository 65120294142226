import { jsonToGraphQLQuery, EnumType } from 'json-to-graphql-query'

import {
  Attachment,
  Feed,
  FeedCreation,
  FeedSource,
  NewsFeedColumn,
  NewsFeedColumnCreation,
  NewsFeedDataExpressionWrapper,
  NewsFeedPost,
  constants,
} from '@devhub/core'
import { EMPTY_ARRAY } from '@devhub/core/src/utils/constants'
import { ColumnResponse, FeedSubSourceResponse, Post } from '../types'
import { getResizedImageUrl } from '../../utils/image'
import { postSortByLatest } from '../../utils/time'
import { SearchMetricInput } from '../../components/metric'
import { Platform } from '../../libs/platform'

// clean data when:
// 1. mismatch updatedAt
// 2. received unique non-delayed column posts' length >= limit
export function shouldDropExistingData(
  column: ColumnResponse,
  originalUpdatedAt: string,
  currentUpdatedAt: string,
  direction: 'NEW' | 'OLD',
): boolean {
  if (column.feeds.length === 0) return false
  const postIdSet = new Set<string>()
  column.feeds.forEach((feed) =>
    feed.posts.forEach((post) => {
      // Only drop existing data when newer posts length >= limit
      // in which case we'll start fresh from the newest.
      if (!post.delayed && !postIdSet.has(post.id)) {
        postIdSet.add(post.id)
      }
    }),
  )
  const shouldDrop =
    originalUpdatedAt != currentUpdatedAt ||
    (direction === 'NEW' && postIdSet.size >= constants.FEED_FETCH_LIMIT)
  return shouldDrop
}

export function convertFeedResponseSubSourcesToSources(
  feedSubSourcesResponse: FeedSubSourceResponse[],
): FeedSource[] {
  const sources: FeedSource[] = []
  if (feedSubSourcesResponse.length === 0) return sources
  for (const subSource of feedSubSourcesResponse) {
    const source = sources.find((s) => s.sourceId == subSource.source.id)
    if (source) {
      source.subSourceIds.push(subSource.id)
      continue
    }
    sources.push({
      sourceId: subSource.source.id,
      subSourceIds: [subSource.id],
    })
  }
  return sources
}

// convert post from API response to NewsFeedPost and save in store,
// and generate tiny img url and save to post
export const postToNewsFeedPost = (post: Post): NewsFeedPost => {
  let attachments: Attachment[] = []
  if (post.imageUrls.length !== 0) {
    attachments = attachments.concat(
      post.imageUrls.map((url) => {
        return {
          id: url,
          dataType: 'img',
          url,
          tinyImageUrl: getResizedImageUrl(url),
        } as Attachment
      }),
    )
  }
  if (post.fileUrls && post.fileUrls.length !== 0) {
    attachments = attachments.concat(
      post.fileUrls.map((url) => {
        return {
          id: url,
          dataType: 'file',
          name: url.split('/')[url.split('/').length - 1],
          url,
        } as Attachment
      }),
    )
  }

  const newsFeedData = {
    id: post.id,
    title: post.title,
    text: post.content,
    crawledTime: post.crawledAt,
    postTime: post.contentGeneratedAt ? post.contentGeneratedAt : undefined,
    cursor: post.cursor,
    subSource: {
      id: post.subSource.id,
      name: post.subSource.name,
      avatarURL: post.subSource.avatarUrl,
      profileURL: post.subSource.originUrl,
    },
    repostedFrom: post.sharedFromPost
      ? postToNewsFeedPost(post.sharedFromPost)
      : undefined,
    url: post.originUrl,
    isRead: post.isRead,
    isSaved: false,
    attachments: attachments,
    semanticHashing: post.semanticHashing,
    embedding: post.embedding,
    tags: post.tags,
    thread: !!post.replyThread
      ? post.replyThread.map((p) => postToNewsFeedPost(p))
      : undefined,
  }

  return newsFeedData
}

export function getColumnCursors(
  column: NewsFeedColumn,
  direction: 'NEW' | 'OLD',
  dataByNodeId: Record<string, NewsFeedPost>,
  resetCursor?: boolean,
): {
  columnCursor: number // oldest one, smaller cursor
  columnOtherEndCursor: number // latest one, larger cursor
} {
  let columnCursor = 0
  let columnOtherEndCursor = 0
  if (!resetCursor) {
    columnCursor =
      dataByNodeId[
        direction == 'NEW' ? column.newestItemId : column.oldestItemId
      ]?.cursor
    columnOtherEndCursor =
      dataByNodeId[
        direction == 'NEW' ? column.oldestItemId : column.newestItemId
      ]?.cursor
  }

  // this logic is not needed if oldestItemId is correct and post data is there
  // put it here to prevent cursor 0 for load more case
  if (direction === 'OLD' && !columnCursor) {
    console.warn('constructColumnRequest: cursor should not be 0', column)
    // calculate columnCursor as the oldest one and columnOtherEndCursor as the latest one
    // basedd on column's itemListIds and dataByNodeId
    if (column.itemListIds.length > 0 && dataByNodeId[column.itemListIds[0]]) {
      columnCursor = dataByNodeId[column.itemListIds[0]].cursor
      columnOtherEndCursor = dataByNodeId[column.itemListIds[0]].cursor
      column.itemListIds.forEach((id) => {
        if (dataByNodeId[id] && dataByNodeId[id].cursor < columnCursor) {
          columnCursor = dataByNodeId[id].cursor
        }
        if (
          dataByNodeId[id] &&
          dataByNodeId[id].cursor > columnOtherEndCursor
        ) {
          columnOtherEndCursor = dataByNodeId[id].cursor
        }
      })
    }
    if (!columnCursor || !columnOtherEndCursor) {
      console.error(
        'cursor or columnOtherEndCursor is still 0 after fallback process',
      )
    }
  }

  return {
    columnCursor,
    columnOtherEndCursor,
  }
}

// convert columns response into posts with unique ids
// ordered by postTime, [now, 1m, 2m]
export function convertColumnsResponseToNewsFeedPosts(
  columns: ColumnResponse[],
): [NewsFeedPost[], NewsFeedPost[]] {
  if (columns.length === 0) return [EMPTY_ARRAY, EMPTY_ARRAY]
  const postIdMap: Set<string> = new Set()
  const posts: NewsFeedPost[] = []
  const delayedNewPosts: NewsFeedPost[] = []

  columns.forEach((column) => {
    column.feeds.forEach((feed) => {
      feed.posts.forEach((post) => {
        if (!postIdMap.has(post.id)) {
          postIdMap.add(post.id)
          const newsFeedPost = postToNewsFeedPost(post)
          if (post.delayed) {
            delayedNewPosts.push(newsFeedPost)
          } else {
            posts.push(newsFeedPost)
          }
        } else {
          console.log(
            `removed duplicate post id: ${post.id} from received new posts`,
          )
        }
      })
    })
  })

  posts.sort(postSortByLatest)
  delayedNewPosts.sort(postSortByLatest)
  return [posts, delayedNewPosts]
}

export function StringToDataExpressionWrapper(
  jsonString: string,
): NewsFeedDataExpressionWrapper {
  const wrapper: NewsFeedDataExpressionWrapper = JSON.parse(jsonString)
  return wrapper
}

// Helper function to extract all subSources to create a column.
export function ExtractSubSourceIdsFromFeedCreation(
  payload: FeedCreation,
): string[] {
  const subSourceIds: string[] = []
  for (const source of payload.sources) {
    for (const subSourceId of source.subSourceIds) {
      subSourceIds.push(subSourceId)
    }
  }
  return subSourceIds
}

export function getUpsertFeedRequest(
  feedCreation: FeedCreation,
  userId: string,
  isUpdate: boolean,
  filterDataExpression: string,
  subSourceIds: string[],
): string {
  const upsertFeedResponseQuery = {
    id: true,
    columns: {
      id: true,
      // updated time? no need to add here since fetch column routine will
      // eventually drop column's posts if
    },
    name: true,
    filterDataExpression: true,
    subSources: {
      id: true,
      source: {
        id: true,
      },
    },
  }

  let upsertFeedInput: any = {
    userId: userId,
    name: feedCreation.title,
    filterDataExpression,
    subSourceIds,
    addToColumn: feedCreation.addToColumn,
  }
  if (isUpdate) {
    upsertFeedInput = {
      ...upsertFeedInput,
      feedId: feedCreation.id,
    }
  }
  if (feedCreation.columnId && feedCreation.columnId !== '') {
    upsertFeedInput = {
      ...upsertFeedInput,
      columnId: feedCreation.columnId,
    }
  }

  return jsonToGraphQLQuery({
    mutation: {
      upsertFeed: {
        __args: {
          input: upsertFeedInput,
        },
        ...upsertFeedResponseQuery,
      },
    },
  })
}

// The only difference is that input should contain 'columnId' on column update,
// otherwise no columnId should be provided on column creation. Due to the
// limitation of jsonToGraphQLQuery, there isn't an easy way of omitting
// certain field at runtime, thus we have to duplicate it.
// P.S. It might be possible to ignoring certain field with the following
// method, but I've not tested whether nested field can adopt the same approach.
// https://github.com/vkolgi/json-to-graphql-query#ignoring-fields-in-the-query-object
export function getUpsertColumnRequest(
  columnCreation: NewsFeedColumnCreation,
  userId: string,
  isUpdate: boolean,
): string {
  let upsertColumnInput: any = {
    userId: columnCreation.creator?.id || userId,
    name: columnCreation.title,
    feedIds: columnCreation.feedIds,
    visibility: new EnumType(columnCreation.visibility),
  }
  if (isUpdate) {
    upsertColumnInput = {
      ...upsertColumnInput,
      columnId: columnCreation.id,
    }
  }
  return jsonToGraphQLQuery({
    mutation: {
      upsertColumn: {
        __args: {
          input: upsertColumnInput,
        },
        id: true,
      },
    },
  })
}

export function EncodeDataExpressionFromFeedCreation(
  payload: FeedCreation,
): string {
  if (!payload.dataExpression) return ''
  return JSON.stringify(payload.dataExpression)
}

export function constructColumnRequest(
  userId: string,
  column: NewsFeedColumn,
  feeds: Feed[],
  direction: 'NEW' | 'OLD',
  dataByNodeId: Record<string, NewsFeedPost>,
  resetCursor?: boolean,
): string {
  const feedIds: string[] = []
  const feedUpdatedTimes: any[] = []
  feeds.forEach((feed) => {
    if (!feed) {
      console.error('Feed is null for column', column)
      return
    }
    const feedUpdatedTime = !feed.updatedAt
      ? new Date().toISOString()
      : feed.updatedAt
    feedIds.push(feed.id)
    feedUpdatedTimes.push(feedUpdatedTime)
  })

  const { columnCursor, columnOtherEndCursor } = getColumnCursors(
    column,
    direction,
    dataByNodeId,
    resetCursor,
  )

  return jsonToGraphQLQuery({
    query: {
      columns: {
        __args: {
          input: {
            userId,
            columnsRefreshInputs: [
              {
                columnId: column.id,
                columnUpdatedTime: column.updatedAt,
                limit: constants.FEED_FETCH_LIMIT,
                direction: new EnumType(direction),
                query: column.filters?.query ?? '',
                cursor: columnCursor || 0,
                otherEndCursor: columnOtherEndCursor || 0,
                feedUpdatedTimes,
                feedIds,
                filter: {
                  unread: !!column.filters?.unread,
                },
              },
            ],
          },
        },
        id: true,
        updatedAt: true,
        name: true,
        creator: {
          id: true,
          name: true,
        },
        feeds: {
          id: true,
          name: true,
          posts: {
            id: true,
            title: true,
            content: true,
            cursor: true,
            subSource: {
              id: true,
              name: true,
              avatarUrl: true,
              externalIdentifier: true,
              originUrl: true,
            },
            originUrl: true,
            imageUrls: true,
            fileUrls: true,
            contentGeneratedAt: true,
            sharedFromPost: {
              id: true,
              title: true,
              content: true,
              subSource: {
                id: true,
                name: true,
                avatarUrl: true,
                externalIdentifier: true,
                originUrl: true,
              },
              imageUrls: true,
              contentGeneratedAt: true,
              originUrl: true,
              tags: true,
            },
            delayed: true,
            semanticHashing: true,
            embedding: true,
            tags: true,
            isRead: true,
            replyThread: {
              id: true,
              title: true,
              content: true,
              cursor: true,
              subSource: {
                id: true,
                name: true,
                avatarUrl: true,
                originUrl: true,
              },
              originUrl: true,
              imageUrls: true,
              fileUrls: true,
              contentGeneratedAt: true,
              sharedFromPost: {
                id: true,
                title: true,
                content: true,
                subSource: {
                  id: true,
                  name: true,
                  avatarUrl: true,
                  originUrl: true,
                },
                imageUrls: true,
                contentGeneratedAt: true,
                originUrl: true,
              },
            },
          },
          subSources: {
            id: true,
            source: {
              id: true,
            },
          },
          filterDataExpression: true,
          visibility: true,
          creator: {
            id: true,
            name: true,
          },
          updatedAt: true,
        },
        visibility: true,
        mobileNotification: true,
        webNotification: true,
        showUnreadIndicatorOnIcon: true,
        readed: true,
      },
    },
  })
}

export function constructSearchPostsRequest(
  userId: string,
  column: NewsFeedColumn,
  direction: 'NEW' | 'OLD',
  dataByNodeId: Record<string, NewsFeedPost>,
  resetCursor?: boolean,
): string {
  const { columnCursor, columnOtherEndCursor } = getColumnCursors(
    column,
    direction,
    dataByNodeId,
    resetCursor,
  )
  return jsonToGraphQLQuery({
    query: {
      posts: {
        __args: {
          input: {
            userId,
            searchPostsRefreshInput: {
              limit: constants.FEED_FETCH_LIMIT,
              direction: new EnumType(direction),
              query: column.filters?.query ?? '',
              cursor: columnCursor || 0,
              otherEndCursor: columnOtherEndCursor || 0,
              filter: {
                unread: !!column.filters?.unread,
              },
            },
          },
        },
        id: true,
        title: true,
        content: true,
        cursor: true,
        subSource: {
          id: true,
          name: true,
          avatarUrl: true,
          externalIdentifier: true,
          originUrl: true,
        },
        originUrl: true,
        imageUrls: true,
        fileUrls: true,
        contentGeneratedAt: true,
        sharedFromPost: {
          id: true,
          title: true,
          content: true,
          subSource: {
            id: true,
            name: true,
            avatarUrl: true,
            externalIdentifier: true,
            originUrl: true,
          },
          imageUrls: true,
          contentGeneratedAt: true,
          originUrl: true,
          tags: true,
        },
        delayed: true,
        semanticHashing: true,
        embedding: true,
        tags: true,
        isRead: true,
        replyThread: {
          id: true,
          title: true,
          content: true,
          cursor: true,
          subSource: {
            id: true,
            name: true,
            avatarUrl: true,
            originUrl: true,
          },
          originUrl: true,
          imageUrls: true,
          fileUrls: true,
          contentGeneratedAt: true,
          sharedFromPost: {
            id: true,
            title: true,
            content: true,
            subSource: {
              id: true,
              name: true,
              avatarUrl: true,
              originUrl: true,
            },
            imageUrls: true,
            contentGeneratedAt: true,
            originUrl: true,
          },
        },
      },
    },
  })
}

export const getInitialSearchMetricsInput = (
  userId: string,
  columnId: string,
  columnName: string,
  keyword?: string,
  userName?: string,
): SearchMetricInput => {
  return {
    keyword: keyword || '',
    userId: userId,
    latency: -1,
    userName: userName || '',
    platform: Platform.OS,
    columnId,
    columnName,
    resultCount: 0,
  }
}

export function constructSetColumnNotificationSettingsRequest(
  columnId: string,
  userId: string,
  enableMobileNotification: boolean,
  enableWebNotification: boolean,
  showUnreadIndicatorOnIcon: boolean,
) {
  return jsonToGraphQLQuery({
    mutation: {
      setNotificationSetting: {
        __args: {
          input: {
            userId,
            columnId,
            mobile: enableMobileNotification,
            web: enableWebNotification,
            unreadIndicatorOnIcon: showUnreadIndicatorOnIcon,
          },
        },
      },
    },
  })
}

export function constructSetItemsReadStatusRequest(
  itemNodeIds: string[],
  userId: string,
  read: boolean,
  type: EnumType,
) {
  return jsonToGraphQLQuery({
    mutation: {
      setItemsReadStatus: {
        __args: {
          input: {
            userId,
            itemNodeIds,
            read,
            type,
          },
        },
      },
    },
  })
}

export function constructSetFeedFavorite(
  feedId: string,
  userId: string,
  isFavorite: boolean,
) {
  return jsonToGraphQLQuery({
    mutation: {
      setFeedFavorite: {
        __args: {
          input: {
            userId,
            feedId,
            isFavorite,
          },
        },
      },
    },
  })
}

export function constructFetchPostByIdRequest(id: string): string {
  return jsonToGraphQLQuery({
    query: {
      post: {
        __args: {
          input: {
            id,
          },
        },

        id: true,
        title: true,
        content: true,
        cursor: true,
        subSource: {
          id: true,
          name: true,
          avatarUrl: true,
          externalIdentifier: true,
        },
        sharedFromPost: {
          id: true,
          title: true,
          content: true,
          cursor: true,
          subSource: {
            id: true,
            name: true,
            avatarUrl: true,
            externalIdentifier: true,
          },
          imageUrls: true,
          fileUrls: true,
          contentGeneratedAt: true,
          crawledAt: true,
          originUrl: true,
          tags: true,
        },
        imageUrls: true,
        fileUrls: true,
        contentGeneratedAt: true,
        crawledAt: true,
        originUrl: true,
        tags: true,
      },
    },
  })
}
