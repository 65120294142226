export const SELECT_ALL = 'Select all'
export const UNSELECT_ALL = 'Unselect all'

export const NEWS_FEED = 'NewsFeed'
export const NEWS_FEED_DESCRIPTION = 'Your personal len to the global news'

export const LOG_IN_OR_SIG_UP = 'Log in or sign up'

export const NEWS_FEED_COLUMN_EMPTY = 'No new feed'

export const LOADING = 'Loading'

export const SEARCH_COLUMN_TYPE_TO_SEARCH = 'Type keyword to search'

export const SEARCH_COLUMN_NO_RESULT = 'No result found'
