import { useCallback, useMemo, useRef } from 'react'

import { NewsFeedPost } from '@devhub/core'
import { STATIC_COLUMNS } from '../constants'
import { useReduxState } from './use-redux-state'
import * as selectors from '../redux/selectors'
import { useColumn } from './use-column'
import { dataMatchesFilters } from './use-column-data'
import { EMPTY_ARRAY } from '../utils/constants'
import { usePreviousRef } from './use-previous-ref'

export function useSearchColumnData<ItemT extends NewsFeedPost>() {
  const columnId = STATIC_COLUMNS.SEARCH_COLUMN

  const columnDataSelector = useMemo(selectors.createColumnDataSelector, [
    columnId,
  ])

  const searchQuery = useReduxState((state) =>
    selectors.columnFilterQuerySelector(state, columnId),
  )

  const { column, hasCrossedColumnsLimit } = useColumn(columnId)

  const dataByNodeIdOrId = useReduxState(selectors.dataByNodeIdOrId)

  const _allItemsIds = useReduxState((state) => {
    if (!(column && column.id)) return EMPTY_ARRAY
    return columnDataSelector(state, column.id)
  })

  const allItemsIds = useMemo(() => _allItemsIds, [_allItemsIds.join(',')])

  const filteredItemsIds = useMemo(() => {
    const filters = column?.filters
    if (!filters) return allItemsIds
    return allItemsIds.filter((id) => {
      const data = dataByNodeIdOrId[id]
      return dataMatchesFilters(data, filters)
    })
  }, [
    allItemsIds,
    column && column.filters,
    column && column.type,
    hasCrossedColumnsLimit,
  ])

  const duplicatedIds = new Set<string>()
  for (const postId of filteredItemsIds) {
    const postData = dataByNodeIdOrId[postId]

    postData?.duplicateIds?.forEach((item) => {
      duplicatedIds.add(item)
    })
  }
  const duplicatedIdsOfFilterItems = Array.from(duplicatedIds)

  // what are these for?
  const previousDataByNodeIdOrIdRef = usePreviousRef(dataByNodeIdOrId)
  const getItemByNodeIdOrIdChangeCountRef = useRef(0)
  useMemo(() => {
    const changed = filteredItemsIds.some(
      (id) =>
        !previousDataByNodeIdOrIdRef.current ||
        previousDataByNodeIdOrIdRef.current[id] !== dataByNodeIdOrId[id],
    )
    if (changed)
      getItemByNodeIdOrIdChangeCountRef.current =
        getItemByNodeIdOrIdChangeCountRef.current + 1
  }, [dataByNodeIdOrId, filteredItemsIds])

  const getItemByNodeIdOrId = useCallback(
    (nodeIdOrId: string) => {
      return (dataByNodeIdOrId[nodeIdOrId] && dataByNodeIdOrId[nodeIdOrId]!) as
        | ItemT
        | undefined
    },
    [getItemByNodeIdOrIdChangeCountRef.current],
  )

  const hasMoreItems: boolean = column === undefined ? false : !column.reachEnd

  return useMemo(
    () => ({
      allItemsIds,
      filteredItemsIds,
      getItemByNodeIdOrId,
      hasCrossedColumnsLimit,
      duplicatedIdsOfFilterItems,
      searchQuery,
      hasMoreItems,
    }),
    [
      allItemsIds,
      filteredItemsIds,
      getItemByNodeIdOrId,
      hasCrossedColumnsLimit,
      duplicatedIdsOfFilterItems,
      searchQuery,
      hasMoreItems,
    ],
  )
}
