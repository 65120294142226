import React from 'react';
import { LayoutChangeEvent, PanResponder, View, Platform } from 'react-native';
import { StyleProp, ViewStyle } from 'react-native-web';

import { useTheme } from '../context/ThemeContext';

export interface DraggableScrollBarProps {
  style?: StyleProp<ViewStyle>
  viewPct: number // viweable percentage 0~1, e.g. 0.3
  positionPct: number // position of list 0~1, e.g. 0.3
  pxToScreenLeft: number
  steps: number
  scrollingToIndex: boolean
  thickness:  number
  onMove?: (position: number) => void
}

// native experience is very bad, need to optimize it in the future before
// enable this scroll bar
export const useDraggableScrollBar = () => {
  const show = Platform.OS === 'web'
  return show
}

export const DraggableScrollBar = (props: DraggableScrollBarProps) => {
  const { style, viewPct, positionPct, pxToScreenLeft, steps, scrollingToIndex, onMove, thickness } = props
  const [width, setWidth] = React.useState<number>(0)
  // px left position of current forground scroll bar before dragging
  const [currentPosition, setCurrentPosition] = React.useState<number>(0)
  // px position dragged
  const [dragChangedPosition, setDragChangedPosition] = React.useState<number>(0)
  // if forground scroll bar is being dragging
  const [dragging, setDragging] = React.useState<boolean>(false)
  const [dragCompleted, setDragCompleted] = React.useState<boolean>(false)
  // px position of the target set by background bar
  const [targetPosition, setTargetPosition] = React.useState<number>(-1)
  const [currentStep, setCurrentStep] = React.useState<number>(0)
  const theme = useTheme()
  const foregroundBarPanResponder = React.useRef(
    PanResponder.create({
      // Ask to be the responder:
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,

      onPanResponderGrant: (evt, gestureState) => {
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now
        setDragging(true)
      },
      onPanResponderMove: (evt, gestureState) => {
        // The most recent move distance is gestureState.move{X,Y}
        // The accumulated gesture distance since becoming responder is
        // gestureState.d{x,y}
        setDragChangedPosition(gestureState.dx)
      },
      onPanResponderTerminationRequest: (evt, gestureState) =>
        true,
      onPanResponderRelease: (evt, gestureState) => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
        setDragging(false)
        setDragCompleted(true)
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        // should be cancelled
        setDragging(false)
        setDragCompleted(true)
      },
      onShouldBlockNativeResponder: (evt, gestureState) => {
        // Returns whether this component should block native components from becoming the JS
        // responder. Returns true by default. Is currently only supported on android.
        return true;
      }
    })
  ).current;

  const backgroundBarPanResponder = React.useRef(
    PanResponder.create({
      // Ask to be the responder:
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,

      onPanResponderGrant: (evt, gestureState) => {
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now
        setTargetPosition(gestureState.x0)
      },
      onPanResponderMove: (evt, gestureState) => {
        // The most recent move distance is gestureState.move{X,Y}
        // The accumulated gesture distance since becoming responder is
        // gestureState.d{x,y}
      },
      onPanResponderTerminationRequest: (evt, gestureState) => true,
      onPanResponderRelease: (evt, gestureState) => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        // should be cancelled
      },
      onShouldBlockNativeResponder: (evt, gestureState) => {
        // Returns whether this component should block native components from becoming the JS
        // responder. Returns true by default. Is currently only supported on android.
        return true;
      }
    })
  ).current;

  // drag complete effect to merge dragChangedPosition into current position
  React.useEffect(() => {
    if (dragCompleted) {
      setDragCompleted(false)
      setCurrentPosition(getRenderPosition())
      setDragChangedPosition(0)
    }
  }, [dragCompleted])

  // targetPosition change effect, reflect it by updating current position
  React.useEffect(() => {
    if (targetPosition >= pxToScreenLeft) {
      // put center of the highlight bar to the target position
      setCurrentPosition(normalizeLeftPosition(targetPosition - pxToScreenLeft - width * viewPct / 2))
    }
  }, [targetPosition])

  //  effect to receive new position and update scroll bar position accordingly
  React.useEffect(() => {
    const hasDistance = Math.abs(positionPct - currentPosition / width) > 0.01
    const showTrigger = width > 0 && !dragging && hasDistance && !scrollingToIndex
    if (showTrigger) {
      // put center of the highlight bar to the target position
      const newPosition = normalizeLeftPosition(width * positionPct)
      setCurrentPosition(newPosition)
    }
  }, [positionPct])


  // normalize px left position of foreground bar
  const normalizeLeftPosition = (position: number) => {
    if (position < 0) return 0
    const MaxPosition = width * (1 - viewPct)
    if (position > MaxPosition) return MaxPosition
    return position
  }

  // actual integer position of foreground bar for rendering
  const getRenderPosition = () => {
    return Math.trunc(normalizeLeftPosition(currentPosition + dragChangedPosition))
  }

  const renderPosition = getRenderPosition()

  // effect to reflect scrollbar change to the onMove receiver
  // meanwhile need to avoid triggering onMove if it's triggered
  // by scroll bar interaction of foreground bar dragging and
  // background bar click
  React.useEffect(() => {
    if (width <= 0 || !dragging && targetPosition < 0) return;
    if (onMove) {
      const percentage = renderPosition / (width * (1 - viewPct))
      const targetIndex = Math.min(steps- 1, Math.floor(steps * percentage))
      if (targetIndex != currentStep) {
        onMove(targetIndex)
        setCurrentStep(targetIndex)
      }
      if (targetPosition != -1) {
        setTargetPosition(-1)
      }
    }
  }, [renderPosition])

  return (
    <View
      style={[
        style,
        {
          backgroundColor: theme.backgroundColor,
        }
      ]}
      onLayout={(e: LayoutChangeEvent) => {
        const { width } = e.nativeEvent.layout
        setWidth(width)
      }}
    >
      <View
        style={{
          height: thickness,
          borderRadius: 8,
          backgroundColor: theme.primaryBackgroundColor,
          left: renderPosition,
          width: `${viewPct * 100}%`,
          zIndex: 20,
          ...Platform.select({ web: { userSelect: 'none' } })
        }}
        {...foregroundBarPanResponder.panHandlers}
      />
      <View
        style={{
          position: 'absolute',
          left: 0,
          width: '100%',
          height: thickness,
          borderRadius: 8,
          zIndex: 19,
        }}
        {...backgroundBarPanResponder.panHandlers}
      />
    </View>
  )
}