import { FeedCreation, guid, mapSourceIdToName, FeedSource } from '@devhub/core'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Keyboard, View } from 'react-native'
import { useDispatch, useStore } from 'react-redux'

import { DataExpressionEditorContainer } from '../../containers/DataExpressionEditorContainer'
import { useReduxState } from '../../hooks/use-redux-state'
import { Platform } from '../../libs/platform'
import * as actions from '../../redux/actions'
import * as selectors from '../../redux/selectors'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { ModalColumn } from '../columns/ModalColumn'
import { AccordionView } from '../common/AccordionView'
import { Button } from '../common/Button'
import { H3 } from '../common/H3'
import { Separator } from '../common/Separator'
import { Spacer } from '../common/Spacer'
import { SubHeader } from '../common/SubHeader'
import { TouchableWithoutFeedback } from '../common/TouchableWithoutFeedback'
import { ProgressBar } from '../common/ProgressBar'
import { DialogConsumer, DialogProviderState } from '../context/DialogContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import {
  ThemedTextInput,
  ThemedTextInputProps,
} from '../themed/ThemedTextInput'
import { NewsSubtypesWithFilter } from './partials/NewsSubtypesWithFilter'
import { SELECT_ALL, UNSELECT_ALL } from '../../resources/strings'
import { useFeedCreatedByCurrentUser } from '../../hooks/use-feed-created-by-current-user'
import { ErrorBoundary } from '../../libs/bugsnag'
import { IconButton } from '../common/IconButton'
import {
  isLogicalExpressionValid,
  normalizeLogicalExpression,
  dataExpressionToLogicalExpression,
  logicalExpressionToDataExpression,
  appendEmptyNodeToDataExpression,
} from '../../utils/dataExpression'
import { ThemedText } from '../themed/ThemedText'

export interface AddFeedDetailsModalProps {
  showBackButton: boolean

  // If we're modifying attribute of an existing column, we should pass the
  // columnId of that column.
  columnId?: string
  feedId?: string
}

export const AddFeedDetailsModal = React.memo(
  (props: AddFeedDetailsModalProps) => {
    const { showBackButton, columnId, feedId } = props
    const store = useStore()
    const column = selectors.columnSelector(store.getState(), columnId ?? '')
    const feed = selectors.feedSelector(store.getState(), feedId ?? '')
    const subscribeOnly =
      !useFeedCreatedByCurrentUser(feedId ?? '') &&
      feed?.visibility === 'PRIVATE'

    const idToSourceOrSubSourceMap = useReduxState(
      selectors.idToSourceOrSubSourceMapSelector,
    )
    const availableNewsFeedSources = useReduxState(
      selectors.availableNewsFeedSourcesSelector,
    )
    const allSubsourcesCount = useReduxState(
      selectors.availableNewsFeedSubsourcesCountSelecter,
    )

    const [showExpressionEditor, setShowExpressionEditor] = useState(false)
    const [expressIsInvalid, setExpressIsInvalid] = useState(false)

    // openedSource determines which source dropdown is selected. If
    // openedSource is empty string, it means all options are closed. At any
    // given time, there could only be a single dropdown opened.
    const [openedSource, setOpenedSource] = useState('')
    const [selectedSubSourcesCount, setSelectedSubSourcesCount] = useState(0)
    const allSubSourcesSelected = selectedSubSourcesCount >= allSubsourcesCount

    // Get all main sources.
    const allSources = availableNewsFeedSources.map((source) => source.sourceId)

    const newsFeedAttributes = selectors.feedSelector(
      store.getState(),
      feedId ? feedId : '',
    )

    // Construct form's initial value. It's either empty, when we're adding a
    // brand new feed, or populated with existing feed's attribute, when we
    // are modifying attributes of one existing feed.
    function getFormInitialValues(feedId?: string): Record<string, any> {
      const res: Record<string, any> = {
        name: '',
        // Create a creator expression by default.
        dataExpression: {
          id: guid(),
        },
        visibility: column?.visibility || 'PRIVATE',
        columnId,
      }
      for (const source of allSources) {
        res[source] = []
      }

      // If no feed id is provided, just populate with default value.
      if (!feedId) return res

      // else.. it must be an existing feed and thus we need to pre-populate
      // form values with existing data.
      if (!newsFeedAttributes) {
        console.warn('Edit existing feed, but attribute is undefined')
        return res
      }

      // Feed doesn't contain sources, instead Feeds inside of feed contains sources
      for (const feedSource of newsFeedAttributes.sources) {
        res[feedSource.sourceId] = feedSource.subSourceIds
      }

      return {
        ...res,
        columnId,
        creator: newsFeedAttributes.creator,
        name: newsFeedAttributes.title,
        // Make a deepcopy, otherwise every addtion or removal is happening on
        // the real redux object.
        dataExpression: _.cloneDeep(newsFeedAttributes.dataExpression),
        visibility: newsFeedAttributes.visibility,
      }
    }

    const updateSubSourcesInFormValues = (option: 'empty' | 'selectAll') => {
      const formValues: Record<string, any> = {
        name: formikProps.values.name,
        dataExpression: formikProps.values.dataExpression,
      }
      allSources.map((source, index) => {
        if (option === 'selectAll') {
          formValues[source] = availableNewsFeedSources[index].subSourceIds
        } else {
          formValues[source] = []
        }
      })

      return formValues
    }

    const formInitialValues: Record<string, any> = getFormInitialValues(feedId)

    const dialogRef = useRef<DialogProviderState>()
    const dispatch = useDispatch()

    const formikProps = useFormik({
      initialValues: formInitialValues,
      onSubmit(formValues, formikActions) {
        Keyboard.dismiss()
        // Create a feed.
        const currentTime = new Date().toISOString()
        const feedCreation: FeedCreation = {
          title: formValues['name'],
          id: feedId ? feedId : guid(),
          columnId: columnId || '',
          updatedAt: currentTime,
          refreshedAt: currentTime,
          isUpdate: !!feedId,
          itemListIds: [],
          creator: newsFeedAttributes?.creator,
          sources: getFeedSourcesFromFormValues(formValues),
          dataExpression: formValues['dataExpression'],
          // default to private for now before shared feed feature
          visibility: formValues['visibility'],
          addToColumn: false,
        }
        dispatch(actions.addFeed(feedCreation))
      },
      validateOnBlur: true,
      validateOnChange: true,

      // avoid multiple setFieldValue at the same time
      // otherwise validate is not guaranteed to called with latest values
      validate: (values) => {
        let newSelectedSubSourcesCount = 0
        for (const key of allSources) {
          newSelectedSubSourcesCount += values[key]?.length || 0
        }

        if (newSelectedSubSourcesCount !== selectedSubSourcesCount) {
          setSelectedSubSourcesCount(newSelectedSubSourcesCount)
        }
        if (newSelectedSubSourcesCount <= 0) {
          return { err: 'no source selected' }
        }
        if (!values.name || values.name === '') {
          return { name: 'name is required' }
        }
        return undefined
      },
    })

    const [expressionText, setExpressText] = useState(
      dataExpressionToLogicalExpression(formikProps.values['dataExpression']) ||
        '',
    )

    const debouncedUpdateDataExpression = useRef(
      _.debounce((exprStr) => updateDataExpression(exprStr), 500),
    ).current
    const updateDataExpression = (value: string) => {
      const normalizedValue = normalizeLogicalExpression(value)
      const isValid = isLogicalExpressionValid(normalizedValue)
      setExpressIsInvalid(!isValid)
      const newDataExpression =
        logicalExpressionToDataExpression(normalizedValue)
      const dataExpressionForForm = isValid
        ? appendEmptyNodeToDataExpression(newDataExpression)
        : newDataExpression
      formikProps.setFieldValue('dataExpression', dataExpressionForForm)
    }

    const onLogicalExpressionTextChange = (value: string) => {
      setExpressText(value)
      debouncedUpdateDataExpression(value)
    }

    const submitButtonDisabled =
      !formikProps.isValid || formikProps.isSubmitting

    useEffect(() => {
      void formikProps.validateForm()
    }, [])

    function renderHeader(title: string) {
      return (
        <SubHeader icon={undefined} title={title}>
          {(() => {
            return (
              <View style={[sharedStyles.flex, sharedStyles.horizontal]}>
                <Spacer flex={1} />

                <ThemedIcon
                  color="foregroundColorMuted65"
                  family="material"
                  name={'article'}
                  size={18 * scaleFactor}
                  {...Platform.select({
                    web: {
                      title: title,
                    },
                  })}
                />
              </View>
            )
          })()}
        </SubHeader>
      )
    }

    // Show the current selection status. e.g. "Twitter (1/10)""
    function getNumberOfSelectionLabel(
      selected: string[],
      source: FeedSource,
    ): string {
      if (selected.length === 0) {
        return ''
      }
      return ` (${selected.length}/${source.subSourceIds.length})`
    }

    // When submitting the form, extract sources and subtypes from the
    // formValues.
    function getFeedSourcesFromFormValues(
      formValues: typeof formInitialValues,
    ): FeedSource[] {
      const sources: FeedSource[] = []
      for (const key of allSources) {
        if (formValues[key].length === 0) continue
        sources.push({
          sourceId: key,
          subSourceIds: formValues[key],
        })
      }
      return sources
    }

    // Renders Source and Sub sources. For example this could be Weibo with a
    // list of users.
    function renderSingleSourceOptions(source: FeedSource) {
      const isOptionsOpened = openedSource === source.sourceId

      return (
        <View key={`add-news-feed-details-source-${source.sourceId}`}>
          <TouchableWithoutFeedback
            onPress={() => {
              formikProps.setFieldTouched(source.sourceId)

              // If we're clicking the already opened source, reset the
              // openedSource state and collapse all options. Otherwise we
              // should replace the openedSource.
              setOpenedSource(isOptionsOpened ? '' : source.sourceId)
            }}
          >
            <View>
              <View style={[sharedStyles.flex, sharedStyles.horizontal]}>
                <H3>
                  {`${mapSourceIdToName(
                    source.sourceId,
                    idToSourceOrSubSourceMap,
                  )}${getNumberOfSelectionLabel(
                    formikProps.values[source.sourceId],
                    source,
                  )}`}
                </H3>
                <Spacer flex={1} />
                <ThemedIcon
                  color="foregroundColorMuted65"
                  family="material"
                  name={isOptionsOpened ? 'expand-more' : 'chevron-left'}
                  size={18 * scaleFactor}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>

          <AccordionView isOpen={isOptionsOpened}>
            <Spacer height={contentPadding} />
            <NewsSubtypesWithFilter
              source={source}
              formikProps={formikProps}
              editable={!subscribeOnly}
            />
          </AccordionView>
        </View>
      )
    }

    function renderSelectAll() {
      if (subscribeOnly) return null
      return (
        <View style={{ paddingBottom: contentPadding }}>
          <Button
            disabled={subscribeOnly}
            type={allSubSourcesSelected ? 'danger' : 'neutral'}
            onPress={() => {
              formikProps.setValues(
                updateSubSourcesInFormValues(
                  allSubSourcesSelected ? 'empty' : 'selectAll',
                ),
              )
            }}
          >
            {allSubSourcesSelected ? UNSELECT_ALL : SELECT_ALL}
          </Button>
        </View>
      )
    }

    function renderSourceAndSubtypesSelectors() {
      return (
        <View style={{ paddingHorizontal: contentPadding }}>
          {renderSelectAll()}
          {availableNewsFeedSources.map((formItem, formItemIndex) => {
            const content = renderSingleSourceOptions(formItem)

            if (!content) {
              if (__DEV__) {
                // eslint-disable-next-line no-console
                console.warn(
                  `[AddFeedDetailsModal] No form defined for "${formItem}"`,
                )
              }
              return null
            }

            return (
              <Fragment
                key={`add-feed-details-modal-formik-item-${formItem}-${formItemIndex}`}
              >
                {content}
                <Spacer height={contentPadding} />
                {idToSourceOrSubSourceMap[formItem.sourceId].state ==
                'loading' ? (
                  <ProgressBar indeterminate />
                ) : (
                  <Separator horizontal />
                )}
                <Spacer height={contentPadding} />
              </Fragment>
            )
          })}
        </View>
      )
    }

    function renderDataExpressionEditor() {
      return (
        <View style={{ paddingHorizontal: contentPadding }}>
          <DataExpressionEditorContainer
            formikProps={formikProps}
            onChange={() => {
              if (showExpressionEditor) {
                setShowExpressionEditor(false)
              }
            }}
          />
        </View>
      )
    }

    // Render the text input box that let user to name their feed.
    function renderFeedNameTextInput() {
      const defaultTextInputProps: Partial<ThemedTextInputProps> = {
        autoCapitalize: 'none',
        autoCorrect: false,
        autoFocus: false,
        blurOnSubmit: false,
        placeholder: 'Feed Name',
      }

      // Show error if string doesn't have value but is touched.
      function shouldShowError() {
        if (!formikProps.touched['name']) {
          return false
        }
        return !formikProps.values['name']
      }

      const borderColor = subscribeOnly ? 'gray' : 'green'

      return (
        <>
          <SubHeader icon={undefined} title={'Feed Name'}>
            {(() => {
              return (
                <View style={[sharedStyles.flex, sharedStyles.horizontal]}>
                  <Spacer flex={1} />

                  <ThemedIcon
                    color="foregroundColorMuted65"
                    family="material"
                    name={'title'}
                    size={18 * scaleFactor}
                    {...Platform.select({
                      web: {
                        title: 'Feed Name',
                      },
                    })}
                  />
                </View>
              )
            })()}
          </SubHeader>

          <View style={sharedStyles.paddingHorizontal}>
            <ThemedTextInput
              editable={!subscribeOnly}
              selectTextOnFocus={!subscribeOnly}
              textInputKey={`add-feed-details-feed-name-text-input`}
              borderThemeColor={
                shouldShowError()
                  ? 'lightRed'
                  : !!formikProps.values['name']
                  ? borderColor
                  : undefined
              }
              borderHoverThemeColor={
                shouldShowError()
                  ? 'lightRed'
                  : !!formikProps.values['name']
                  ? borderColor
                  : undefined
              }
              borderFocusThemeColor={
                shouldShowError()
                  ? 'lightRed'
                  : !!formikProps.values['name']
                  ? borderColor
                  : undefined
              }
              {...defaultTextInputProps}
              onBlur={() => {
                formikProps.setFieldTouched('name')
              }}
              onChangeText={(value) => {
                formikProps.setFieldValue('name', value)
              }}
              value={
                subscribeOnly
                  ? `${formikProps.values['name']}(${formikProps.values['creator'].name})`
                  : formikProps.values['name']
              }
            />
          </View>
          <Spacer height={contentPadding / 2} />
        </>
      )
    }

    return (
      <ModalColumn
        name="ADD_FEED_DETAILS"
        showBackButton={showBackButton}
        title={feedId ? 'Edit Feed Attributes' : 'Add Feed'}
      >
        <DialogConsumer>
          {(Dialog) => {
            dialogRef.current = Dialog

            return (
              <>
                {renderFeedNameTextInput()}
                {renderHeader('Sources')}

                <Separator horizontal />
                <Spacer height={contentPadding} />

                <View style={sharedStyles.fullWidth}>
                  {renderSourceAndSubtypesSelectors()}
                </View>

                {/* {renderHeader('Feed Icon (Optional)')}
                <DropDownIconPicker data={[]} formikProps={formikProps} /> */}

                {renderHeader('News Expression (Optional)')}
                <View style={sharedStyles.fullWidth}>
                  {expressIsInvalid ? <></> : renderDataExpressionEditor()}
                </View>
                <View style={sharedStyles.paddingHorizontal}>
                  <View
                    style={[
                      sharedStyles.horizontal,
                      sharedStyles.flex,
                      sharedStyles.alignItemsCenter,
                    ]}
                  >
                    <IconButton
                      color="foregroundColor"
                      family="material"
                      name="edit"
                      onPress={() => {
                        // update logical expression text on showing
                        if (!showExpressionEditor) {
                          const logicalExpression =
                            dataExpressionToLogicalExpression(
                              formikProps.values['dataExpression'],
                            )
                          setExpressText(logicalExpression)
                          setExpressIsInvalid(
                            !isLogicalExpressionValid(logicalExpression),
                          )
                        }
                        setShowExpressionEditor(!showExpressionEditor)
                      }}
                      size={18 * scaleFactor}
                    />
                    {showExpressionEditor && (
                      <ThemedText
                        color={'foregroundColorMuted65'}
                        style={{ paddingBottom: 4 * scaleFactor }}
                      >
                        {'Example: tesla&(2022|2021)&!twitter'}
                      </ThemedText>
                    )}
                  </View>
                  {showExpressionEditor && (
                    <ErrorBoundary>
                      <ThemedTextInput
                        style={{
                          height: 100 * scaleFactor,
                        }}
                        borderThemeColor={
                          expressIsInvalid ? 'lightRed' : undefined
                        }
                        borderHoverThemeColor={
                          expressIsInvalid ? 'lightRed' : undefined
                        }
                        borderFocusThemeColor={
                          expressIsInvalid ? 'lightRed' : undefined
                        }
                        multiline
                        numberOfLines={10}
                        editable={true}
                        selectTextOnFocus={true}
                        textInputKey={`add-feed-details-data-expression-text-input`}
                        onBlur={() => {
                          formikProps.setFieldTouched('dataExpression')
                        }}
                        onChangeText={onLogicalExpressionTextChange}
                        value={expressionText}
                      />
                    </ErrorBoundary>
                  )}
                  <Spacer height={contentPadding} />
                  <Separator horizontal />
                  <Spacer height={contentPadding} />
                </View>
                <Spacer height={contentPadding} />

                <View style={sharedStyles.paddingHorizontal}>
                  <Button
                    analyticsLabel="add_or_set_feed"
                    disabled={expressIsInvalid || submitButtonDisabled}
                    onPress={formikProps.submitForm}
                    type="primary"
                  >
                    {feedId ? 'Save Feed Attributes' : 'Add Feed'}
                  </Button>
                </View>

                <Spacer height={contentPadding / 2} />
              </>
            )
          }}
        </DialogConsumer>
      </ModalColumn>
    )
  },
)

AddFeedDetailsModal.displayName = 'AddFeedDetailsModal'
