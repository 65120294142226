import { ColumnCreation, Feed, FeedCreation, guid } from '@devhub/core'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, { Fragment, useEffect, useRef } from 'react'
import { Keyboard, View } from 'react-native'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Checkbox } from '../common/Checkbox'

import { Platform } from '../../libs/platform'
import * as actions from '../../redux/actions'
import * as selectors from '../../redux/selectors'
import { sharedStyles } from '../../styles/shared'
import { contentPadding, scaleFactor } from '../../styles/variables'
import { ModalColumn } from '../columns/ModalColumn'
import { Button } from '../common/Button'
import { H3 } from '../common/H3'
import { Separator } from '../common/Separator'
import { Spacer } from '../common/Spacer'
import { SubHeader } from '../common/SubHeader'
import { DialogConsumer, DialogProviderState } from '../context/DialogContext'
import { ThemedIcon } from '../themed/ThemedIcon'
import {
  ThemedTextInput,
  ThemedTextInputProps,
} from '../themed/ThemedTextInput'
import { useColumnCreatedByCurrentUser } from '../../hooks/use-column-created-by-current-user'
import { ThemedText } from '../themed/ThemedText'
import { ThemedTouchableOpacity } from '../themed/ThemedTouchableOpacity'
import { RootState } from '../../redux/types'
import { useReduxState } from '../../hooks/use-redux-state'

export interface AddColumnDetailsModalProps {
  showBackButton: boolean

  // If we're modifying attribute of an existing column, we should pass the
  // columnId of that column.
  columnId?: string
}

const checkboxSize = 18

export const AddColumnDetailsModal = React.memo(
  (props: AddColumnDetailsModalProps) => {
    const { showBackButton, columnId } = props
    const store = useStore()
    const storeState = store.getState()
    const column = selectors.columnSelector(storeState, columnId || '')
    const allColumnIds = selectors.columnIdsSelector(storeState)
    const subscribed = columnId != null && allColumnIds.includes(columnId)
    const subscribeOnly =
      !useColumnCreatedByCurrentUser(columnId ?? '') && !subscribed
    const dialogRef = useRef<DialogProviderState>()
    const dispatch = useDispatch()
    const newsFeedColumnAttributes = selectors.columnSelector(
      store.getState(),
      columnId ? columnId : '',
    )
    const feeds = selectors.feedsSelector(
      store.getState(),
      column ? column.feedIds : [],
    )

    const user = useReduxState(selectors.currentUserSelector)
    const enableFeedShare = !column?.creator || column?.creator?.id === user?.id

    const favoriteFeeds = useSelector((state: RootState) =>
      selectors.favoriteFeedsSelector(state),
    )
    const favoriteFeedIds = favoriteFeeds.map((f) => f.id)

    // Construct form's initial value. It's either empty, when we're adding a
    // brand new column, or populated with existing column's attribute, when we
    // are modifying attributes of one existing column.
    function getFormInitialValues(columnId?: string): Record<string, any> {
      const res: Record<string, any> = {
        name: '',
        icon: {
          family: 'material',
          name: 'rss-feed',
        },
        removedFeedIds: [],
      }

      // If no column id is provided, just populate with default value.
      if (!columnId) return res

      // else.. it must be an existing column and thus we need to pre-populate
      // form values with existing data.
      if (!newsFeedColumnAttributes) {
        console.warn('Edit existing column, but attribute is undefined')
        return res
      }

      return {
        ...res,
        columnId,
        creator: newsFeedColumnAttributes.creator,
        name: newsFeedColumnAttributes.title,
        icon: newsFeedColumnAttributes.icon,
        visibility: newsFeedColumnAttributes.visibility,
        subscriberCount: newsFeedColumnAttributes.subscriberCount,
      }
    }

    const formikProps = useFormik({
      initialValues: getFormInitialValues(columnId),
      onSubmit(formValues, formikActions) {
        Keyboard.dismiss()
        dispatch(actions.closeAllModals())

        // Create a empty column.
        const columnCreation: ColumnCreation = {
          subscribeOnly,
          title: formValues['name'],
          icon: formValues['icon'],
          type: 'COLUMN_TYPE_NEWS_FEED',
          id: columnId ? columnId : guid(),
          feedIds: feeds
            .map((feed) => feed.id)
            .filter((id) => !formValues['removedFeedIds'].includes(id)),
          isUpdate: !!columnId,
          itemListIds: newsFeedColumnAttributes?.itemListIds ?? [],
          delayedNewItemIds: [],
          showDelayedNewItems: false,
          oldestItemId: newsFeedColumnAttributes?.oldestItemId ?? '',
          newestItemId: newsFeedColumnAttributes?.newestItemId ?? '',
          creator: newsFeedColumnAttributes?.creator,
          state: 'not_loaded',
          options: newsFeedColumnAttributes?.options ?? {
            // show unread by default.
            enableAppIconUnreadIndicator: true,
            webNotification: false,
            mobileNotification: false,
          },
          visibility: formValues['visibility'] ?? 'PRIVATE',
          subscriberCount: formValues['subscriberCount'] ?? 1,
        }
        dispatch(actions.addColumn(columnCreation))
      },
      validateOnBlur: true,
      validateOnChange: true,

      // avoid multiple setFieldValue at the same time
      // otherwise validate is not guaranteed to called with latest values
      validate: (values) => {
        if (!values.name || values.name === '') {
          return { name: 'name is required' }
        }
        return undefined
      },
    })

    // validate on mount
    useEffect(() => {
      void formikProps.validateForm()
    }, [])

    // for after updating/creating feed
    useEffect(() => {
      void formikProps.validateForm()
    }, [feeds.length])

    const navigateToFeedDetailsModal =
      (columnId?: string, feedId?: string) => () => {
        dispatch(
          actions.pushModal({
            name: 'ADD_FEED_DETAILS',
            params: {
              columnId,
              feedId,
            },
          }),
        )
      }

    const toggleFeedFavoriate = (feedId: string, isFavorite: boolean) => () => {
      dispatch(actions.toggleFeedFavorite({ feedId, isFavorite }))
    }

    const addFeedToColumn = (feed: FeedCreation, columnId?: string) => () => {
      if (columnId === null || columnId === undefined) {
        console.error('column id is needed to add feed', feed.id)
        return
      }
      dispatch(
        actions.addFeed({
          ...feed,
          columnId,
          visibility: 'PRIVATE',
          addToColumn: true,
        }),
      )
    }

    const removeFavFeedFromColumn =
      (feedId: string, columnId?: string) => () => {
        if (columnId === null || columnId === undefined) {
          console.error('column id is needed to add feed', feedId)
          return
        }
        dispatch(actions.removeFeedFromColumn({ feedId, columnId }))
      }

    const removeFeedFromColumn =
      (
        removedFeedIdsInForm: string[],
        feedId: string,
        recover: boolean,
        columnId?: string,
      ) =>
      () => {
        if (columnId == null) {
          console.error('column id is needed to delete feed ', feedId)
          return
        }
        let updatedRemovedFeedIds
        if (recover) {
          updatedRemovedFeedIds = removedFeedIdsInForm.filter(
            (id) => id !== feedId,
          )
        } else {
          updatedRemovedFeedIds = [...removedFeedIdsInForm, feedId]
        }
        formikProps.setFieldValue('removedFeedIds', updatedRemovedFeedIds)
      }

    const removedFeedIdsInForm: string[] = formikProps.values['removedFeedIds']
    const feedsToKeep = feeds.filter(
      (feed) => !removedFeedIdsInForm.includes(feed.id),
    )
    const submitButtonDisabled =
      !formikProps.isValid ||
      formikProps.isSubmitting ||
      column === undefined ||
      column.id === '' ||
      feedsToKeep.length === 0

    function renderHeader(title: string) {
      return (
        <SubHeader icon={undefined} title={title}>
          {(() => {
            return (
              <View style={[sharedStyles.flex, sharedStyles.horizontal]}>
                <Spacer flex={1} />

                <ThemedIcon
                  color="foregroundColorMuted65"
                  family="material"
                  name={'article'}
                  size={18 * scaleFactor}
                  {...Platform.select({
                    web: {
                      title: title,
                    },
                  })}
                />
              </View>
            )
          })()}
        </SubHeader>
      )
    }

    // Render the text input box that let user to name their column.
    function renderColumnNameTextInput() {
      const defaultTextInputProps: Partial<ThemedTextInputProps> = {
        autoCapitalize: 'none',
        autoCorrect: false,
        autoFocus: false,
        blurOnSubmit: false,
        placeholder: 'Column Name',
      }

      // Show error if string doesn't have value but is touched.
      function shouldShowError() {
        if (!formikProps.touched['name']) {
          return false
        }
        return !formikProps.values['name']
      }

      const borderColor = subscribeOnly ? 'gray' : 'green'

      return (
        <>
          <SubHeader icon={undefined} title={'Column Name'}>
            {(() => {
              return (
                <View style={[sharedStyles.flex, sharedStyles.horizontal]}>
                  <Spacer flex={1} />

                  <ThemedIcon
                    color="foregroundColorMuted65"
                    family="material"
                    name={'title'}
                    size={18 * scaleFactor}
                    {...Platform.select({
                      web: {
                        title: 'Column Name',
                      },
                    })}
                  />
                </View>
              )
            })()}
          </SubHeader>

          <View style={sharedStyles.paddingHorizontal}>
            <ThemedTextInput
              editable={!subscribeOnly}
              selectTextOnFocus={!subscribeOnly}
              textInputKey={`add-column-details-column-name-text-input`}
              borderThemeColor={
                shouldShowError()
                  ? 'lightRed'
                  : !!formikProps.values['name']
                  ? borderColor
                  : undefined
              }
              borderHoverThemeColor={
                shouldShowError()
                  ? 'lightRed'
                  : !!formikProps.values['name']
                  ? borderColor
                  : undefined
              }
              borderFocusThemeColor={
                shouldShowError()
                  ? 'lightRed'
                  : !!formikProps.values['name']
                  ? borderColor
                  : undefined
              }
              {...defaultTextInputProps}
              onBlur={() => {
                formikProps.setFieldTouched('name')
              }}
              onChangeText={(value) => {
                formikProps.setFieldValue('name', value)
              }}
              value={
                subscribeOnly
                  ? `${formikProps.values['name']}(${formikProps.values['creator'].name})`
                  : formikProps.values['name']
              }
            />
          </View>

          <Spacer height={contentPadding / 2} />
        </>
      )
    }

    function renderFavoriteFeeds() {
      const favFeedsToShow = favoriteFeeds.filter(
        (f) => !feeds.map((f) => f.id).includes(f.id),
      )
      return (
        <View style={{ paddingHorizontal: contentPadding }}>
          {favFeedsToShow.map(({ id, title }, index) => {
            const showSeparator = index < feeds.length - 1
            const toBeRemoved = removedFeedIdsInForm.includes(id)
            return (
              <Fragment key={`add-column-details-modal-feed-${id}`}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <ThemedTouchableOpacity
                    style={{
                      flex: 10,
                      paddingTop: contentPadding,
                      paddingBottom: contentPadding,
                    }}
                    backgroundColor="backgroundColor"
                    onPress={navigateToFeedDetailsModal(columnId, id)}
                  >
                    <ThemedText
                      color="foregroundColorMuted65"
                      style={toBeRemoved && sharedStyles.lineThrough}
                    >
                      {title + (toBeRemoved ? ' (to delete)' : '')}
                    </ThemedText>
                  </ThemedTouchableOpacity>

                  <ThemedTouchableOpacity
                    style={{
                      flex: 1,
                      paddingTop: contentPadding,
                      paddingBottom: contentPadding,
                    }}
                    backgroundColor="backgroundColor"
                    onPress={addFeedToColumn(
                      { ...favFeedsToShow[index], addToColumn: true },
                      columnId ?? '',
                    )}
                  >
                    <ThemedIcon
                      color="foregroundColorMuted65"
                      family="material"
                      name={'add'}
                      size={18 * scaleFactor}
                      {...Platform.select({
                        web: {
                          title,
                        },
                      })}
                    />
                  </ThemedTouchableOpacity>

                  <ThemedTouchableOpacity
                    style={{
                      paddingTop: contentPadding,
                      paddingBottom: contentPadding,
                    }}
                    backgroundColor="backgroundColor"
                    onPress={toggleFeedFavoriate(id, false)}
                  >
                    <ThemedIcon
                      color="foregroundColorMuted65"
                      family="material"
                      name={'star'}
                      size={18 * scaleFactor}
                      {...Platform.select({
                        web: {
                          title,
                        },
                      })}
                    />
                  </ThemedTouchableOpacity>
                </View>
                {showSeparator && <Separator horizontal />}
              </Fragment>
            )
          })}
        </View>
      )
    }

    function renderColumnFeeds() {
      const removedFeedIdsInForm: string[] =
        formikProps.values['removedFeedIds']
      return (
        <View style={{ paddingHorizontal: contentPadding }}>
          {feeds.map(({ id, title }, index) => {
            const showSeparator = index < feeds.length - 1
            const toBeRemoved = removedFeedIdsInForm.includes(id)
            const isFavorite = favoriteFeedIds.includes(id)
            return (
              <Fragment key={`add-column-details-modal-feed-${id}`}>
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <ThemedTouchableOpacity
                    style={{
                      flex: 10,
                      paddingTop: contentPadding,
                      paddingBottom: contentPadding,
                    }}
                    backgroundColor="backgroundColor"
                    onPress={navigateToFeedDetailsModal(columnId, id)}
                  >
                    <ThemedText
                      color="foregroundColorMuted65"
                      style={toBeRemoved && sharedStyles.lineThrough}
                    >
                      {title + (toBeRemoved ? ' (to delete)' : '')}
                    </ThemedText>
                  </ThemedTouchableOpacity>

                  {enableFeedShare ? (
                    <ThemedTouchableOpacity
                      style={{
                        visible: column?.creator?.id === user?.id,
                        flex: 1,
                        paddingTop: contentPadding,
                        paddingBottom: contentPadding,
                      }}
                      backgroundColor="backgroundColor"
                      onPress={toggleFeedFavoriate(id, !isFavorite)}
                    >
                      <ThemedIcon
                        color="foregroundColorMuted65"
                        family="material"
                        name={isFavorite ? 'star' : 'star-border'}
                        size={18 * scaleFactor}
                        {...Platform.select({
                          web: {
                            title,
                          },
                        })}
                      />
                    </ThemedTouchableOpacity>
                  ) : (
                    <></>
                  )}

                  <ThemedTouchableOpacity
                    style={{
                      paddingTop: contentPadding,
                      paddingBottom: contentPadding,
                    }}
                    backgroundColor="backgroundColor"
                    onPress={
                      isFavorite
                        ? removeFavFeedFromColumn(id, columnId)
                        : removeFeedFromColumn(
                            removedFeedIdsInForm,
                            id,
                            toBeRemoved,
                            columnId,
                          )
                    }
                  >
                    <ThemedIcon
                      color="foregroundColorMuted65"
                      family="material"
                      name={toBeRemoved ? 'undo' : 'delete'}
                      size={18 * scaleFactor}
                      {...Platform.select({
                        web: {
                          title,
                        },
                      })}
                    />
                  </ThemedTouchableOpacity>
                </View>
                {showSeparator && <Separator horizontal />}
              </Fragment>
            )
          })}
        </View>
      )
    }

    function renderCreateFeedsButton() {
      return (
        <Button
          style={[sharedStyles.marginHorizontal, sharedStyles.marginVertical]}
          analyticsLabel="add_or_set_feed"
          onPress={navigateToFeedDetailsModal(columnId)}
        >
          {'Create Feed'}
        </Button>
      )
    }

    function renderSubmitButton() {
      let errorText
      if (feedsToKeep.length === 0) {
        errorText = (
          <View style={sharedStyles.center}>
            <ThemedText color={'red'}>{'Please add feed'}</ThemedText>
          </View>
        )
      }

      let buttonText = 'Add Column'
      if (columnId != null) {
        buttonText = subscribed
          ? 'Save Column Attribute'
          : 'Subscribe to Column'
      }

      return (
        <View style={sharedStyles.paddingHorizontal}>
          <Button
            analyticsLabel="add_or_set_column"
            disabled={submitButtonDisabled}
            onPress={formikProps.submitForm}
            type="primary"
          >
            {buttonText}
          </Button>
          {errorText}
        </View>
      )
    }

    return (
      <ModalColumn
        name="ADD_COLUMN_DETAILS"
        showBackButton={showBackButton}
        title={
          subscribeOnly
            ? 'Subscribe to Column'
            : columnId
            ? 'Edit News Column Attribute'
            : 'Add News Column'
        }
      >
        <DialogConsumer>
          {(Dialog) => {
            dialogRef.current = Dialog

            return (
              <>
                {renderColumnNameTextInput()}
                {renderHeader('Feeds')}
                <Separator horizontal />
                {renderColumnFeeds()}
                {renderCreateFeedsButton()}
                {renderHeader('Visibility')}
                <View
                  style={[
                    sharedStyles.horizontal,
                    sharedStyles.paddingHorizontal,
                    sharedStyles.paddingVerticalHalf,
                  ]}
                >
                  <H3>Set Column Public</H3>
                  <Spacer width={contentPadding / 2} />
                  <ThemedIcon
                    family="material"
                    name="group"
                    color="foregroundColorMuted65"
                    style={
                      formikProps.values['visibility'] === 'GLOBAL'
                        ? sharedStyles.alignSelfCenter
                        : sharedStyles.displayNone
                    }
                  />
                  <Spacer width={contentPadding / 4} />
                  <ThemedText
                    color="foregroundColorMuted65"
                    style={
                      formikProps.values['visibility'] === 'GLOBAL'
                        ? sharedStyles.alignItemsCenter
                        : sharedStyles.displayNone
                    }
                  >
                    {formikProps.values['subscriberCount'] ?? '0'}
                  </ThemedText>
                  <Spacer flex={1} />
                  <Checkbox
                    containerStyle={{
                      height: checkboxSize * scaleFactor,
                      width: checkboxSize * scaleFactor,
                    }}
                    squareContainerStyle={{
                      height: checkboxSize * scaleFactor,
                      width: checkboxSize * scaleFactor,
                    }}
                    analyticsLabel="column_option_in_feed_sharing_settings"
                    checked={formikProps.values['visibility'] === 'GLOBAL'}
                    defaultValue
                    disabled={subscribeOnly}
                    onChange={(value) => {
                      formikProps.setFieldValue(
                        'visibility',
                        value ? 'GLOBAL' : 'PRIVATE',
                      )
                    }}
                  />
                </View>
                <Separator horizontal />
                {enableFeedShare ? renderHeader('Saved Feeds') : <></>}
                {enableFeedShare ? <Separator horizontal /> : <></>}
                {enableFeedShare ? renderFavoriteFeeds() : <></>}
                <Spacer flex={1} />
                {renderSubmitButton()}
                <Spacer height={contentPadding / 2} />
              </>
            )
          }}
        </DialogConsumer>
      </ModalColumn>
    )
  },
)

AddColumnDetailsModal.displayName = 'AddColumnDetailsModal'
