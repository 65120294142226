import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { View, Text, Image, Linking } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDateSmallText,
  getFullDateText,
  NewsFeedPost,
  Attachment,
} from '@devhub/core'
import { Platform } from '../../libs/platform'
import ViewShot from '../../libs/react-native-view-shot'
import { sharedStyles } from '../../styles/shared'
import {
  avatarSize,
  largeTextSize,
  mediumAvatarSize,
  scaleFactor,
  smallAvatarSize,
  smallTextSize,
} from '../../styles/variables'
import { Avatar } from '../common/Avatar'
import { useSubSource } from '../../hooks/use-sub-source'
import { IntervalRefresh } from '../common/IntervalRefresh'
import { Spacer } from '../common/Spacer'
import { ThemedIcon } from '../themed/ThemedIcon'
import { ThemedText } from '../themed/ThemedText'
import { BaseCardProps, getCardPropsForItem, sizes } from './BaseCard.shared'
import { TouchableHighlight } from '../common/TouchableHighlight'
import { useTheme } from '../context/ThemeContext'
import ImageViewer from '../../libs/image-viewer'
import FileDownloader from '../../libs/file-downloader'
import { useHistory } from '../../libs/react-router'
import {
  capatureView,
  setItemSavedStatus,
  setItemDuplicationReadStatus,
  setItemsReadStatus,
  saveImageToAlbum,
  setPostToShare,
} from '../../redux/actions'
import { Link } from '../common/Link'
import { RouteConfiguration } from '../../navigation/AppNavigator'
import { Button } from '../common/Button'
import { useItem } from '../../hooks/use-item'
import { viewCapturingItemNodeIdSelector } from '../../redux/selectors'
import { TouchableWithoutFeedback } from '../common/TouchableWithoutFeedback'
import { getHighlightedText, parseTextWithLinks } from './helpers'
import { baseCardStyles } from './BaseCardStyles'

const SIGNAL_RESET_MAX = 100
// Number of characters to render show more button.
const LENGTH_TO_SHOW_MORE = 70

const styles = baseCardStyles

export const BaseCard = React.memo((props: BaseCardProps) => {
  const {
    type,
    nodeIdOrId,
    columnId,
    isRetweeted,
    shareMode = false,
    hideActions,
    rootRef,
    rootNodeIdOrId,
    setThreadVisibility,
    defaultExpand,
    highlightText,
  } = props
  const dispatch = useDispatch()
  const item = useItem(nodeIdOrId)
  const rootItem: NewsFeedPost | undefined = !!rootNodeIdOrId
    ? useItem(rootNodeIdOrId)
    : undefined

  if (!item) return null

  const {
    attachments,
    subSourceId,
    time,
    isRead,
    isSaved,
    link,
    text,
    title,
    repostedFrom,
    duplicateIds,
    isDuplicationRead,
    tags,
  } = getCardPropsForItem(type, columnId, item)

  if (!subSourceId) return null

  const shouldExpand =
    defaultExpand || item.subSource?.profileURL?.includes('twitter.com')
  // Whether has thread to show.
  const hasThread = !!item.thread && item.thread.length > 0
  const timestamp = time ? Date.parse(time) : new Date().toISOString()
  const parentShowMoreSignal = props.showMoreSignal

  const [textShown, setTextShown] = useState(false)
  const [showThread, setShowThread] = useState(shareMode)
  const [showMoreSignal, setShowMoreSignal] = useState<number>(0)

  // index of -1 will hide the image viewer, otherwise it's the image index to show
  const [imageIndexToView, setImageIndexToView] = useState<number>(-1)
  const viewShotref = useRef<ViewShot>(null)
  const ref = useRef<View>(null)
  const history = useHistory()

  const toggleShowMoreText = () => {
    setTextShown(!textShown)
    markAsRead()
  }

  const subSource = useSubSource(subSourceId)
  let profileUrl = subSource?.profileURL
  if (!profileUrl?.startsWith('http')) {
    profileUrl = `http://${profileUrl}`
  }

  const markAsRead = () => {
    dispatch(
      setItemsReadStatus({
        itemNodeIds: !!rootNodeIdOrId
          ? [nodeIdOrId, rootNodeIdOrId]
          : [nodeIdOrId],
        read: true,
        syncup: true,
      }),
    )
    handleAvatarClick
  }

  // Whether we should show "show more" button for the text. We calculate this
  // flag before rendering so that we don't need to render twice. This would
  // greatly save frontend resources.
  const hasMore = !!text && text.length > LENGTH_TO_SHOW_MORE && !shouldExpand

  // Hide or show duplication status for a single post.
  const [showDuplication, setShowDuplication] = useState<boolean>(false)

  const theme = useTheme()
  const isCapturingView =
    useSelector(viewCapturingItemNodeIdSelector) === nodeIdOrId
  const hasTitle: boolean = title != null && title !== ''
  const hasText: boolean = text != null && text !== ''
  const isWeb: boolean = Platform.OS === 'web'
  const largeMode = shareMode

  const images: Attachment[] =
    attachments?.filter((a) => a.dataType === 'img') ?? []
  const files: Attachment[] =
    attachments?.filter((a) => a.dataType === 'file') ?? []

  const textStyle = largeMode && sharedStyles.extraLargeText
  const memorizedHighlightedTitle = useMemo(
    () => getHighlightedText(title, highlightText),
    [title, highlightText],
  )

  // Show a text summary snippet when text is too long, otherwise show the
  // entire text.
  // Text is highlighted if highlightText is provided.
  // Special characters are parsed as well.
  const memorizedParsedAndHighlightedText = useMemo(() => {
    let textToShow = 'empty content'
    if (text) {
      textToShow =
        textShown || shareMode || !hasMore
          ? text
          : `${text.substring(0, LENGTH_TO_SHOW_MORE)}...`
    }
    return parseTextWithLinks(textToShow, textStyle, markAsRead, highlightText)
  }, [text, highlightText, textShown, shareMode, hasMore, textStyle])

  const routeToSharedPostPage = useCallback(() => {
    markAsRead()
    history.push(
      RouteConfiguration.sharedPost.replace(
        ':id',
        rootNodeIdOrId ?? nodeIdOrId,
      ),
    )
  }, [nodeIdOrId, rootNodeIdOrId])

  // 0 initial, adding up to trigger expand
  useEffect(() => {
    if (
      showMoreSignal !== 0 ||
      (!!parentShowMoreSignal && parentShowMoreSignal !== 0)
    ) {
      setTextShown(true)
      if (setThreadVisibility) setThreadVisibility()
    }
  }, [showMoreSignal, parentShowMoreSignal])

  // If user explicitly wants to hide actions, return false. Othe
  function shouldHideActions(): boolean {
    return hideActions || isRetweeted || shareMode || hasThread
  }

  function renderDeduplicationBar() {
    return (
      <View style={{ width: '100%' }}>
        <Button
          round={false}
          type={'custom'}
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            height: 24 * scaleFactor,
          }}
          colors={{
            backgroundThemeColor: 'backgroundColorLess2',
            foregroundThemeColor: 'foregroundColor',
            backgroundHoverThemeColor: 'backgroundColorLess3',
            foregroundHoverThemeColor: 'foregroundColor',
          }}
          contentContainerStyle={{ alignItems: 'flex-end' }}
          onPress={() => {
            setShowDuplication(!showDuplication)
            // Click on the deduplication bar means:
            // 1. user already read all duplication messages
            // 2. user must also read the original message
            dispatch(
              setItemDuplicationReadStatus({
                itemNodeId: nodeIdOrId,
                read: true,
                syncup: true,
              }),
            )
            markAsRead()
          }}
        >
          <View
            style={[sharedStyles.horizontal, sharedStyles.alignItemsCenter]}
          >
            {!isDuplicationRead && (
              <ThemedIcon
                family="octicon"
                name="dot-fill"
                color={'primaryBackgroundColor'}
                size={smallTextSize}
              />
            )}
            <Spacer width={sizes.horizontalSpaceSize} />
            <ThemedText color={'foregroundColor'}>
              {showDuplication ? 'hide' : 'show'}
              <ThemedText color={'foregroundColor'} style={[styles.title]}>
                {` ${duplicateIds?.length} `}
              </ThemedText>
              similar message(s)
            </ThemedText>
            <Spacer width={sizes.horizontalSpaceSize} />
            <ThemedIcon
              style={
                showDuplication ? { transform: [{ rotate: '180deg' }] } : {}
              }
              family="material"
              name={'arrow-drop-down-circle'}
              color={'foregroundColor'}
              size={largeTextSize}
            />
          </View>
        </Button>
      </View>
    )
  }

  const handleAvatarClick = useCallback(() => {
    if (profileUrl) {
      Linking.openURL(profileUrl).catch((err) =>
        console.error('An error occurred', err),
      )
    }
  }, [profileUrl])

  const handleClickCameraOnWeb = useCallback(() => {
    if (isCapturingView) return
    setShowMoreSignal((showMoreSignal % SIGNAL_RESET_MAX) + 1)
    markAsRead()
    dispatch(
      capatureView({
        itemNodeId: nodeIdOrId,
        viewRef: rootRef ? rootRef : ref,
      }),
    )
  }, [nodeIdOrId, ref, theme, isCapturingView, rootRef])

  const handleClickCameraOnNative = useCallback(() => {
    setShowMoreSignal((showMoreSignal % SIGNAL_RESET_MAX) + 1)
    markAsRead()
    // give it time to re-render after expanding show more
    setTimeout(() => {
      if (viewShotref?.current?.capture) {
        viewShotref.current
          .capture()
          .then((uri) => {
            dispatch(saveImageToAlbum({ uri }))
          })
          .catch((e) => {
            console.error('failed to capture view shot', e)
          })
      }
    }, 50)
  }, [viewShotref, showMoreSignal])

  function getAvatarSize(): number {
    return (
      (largeMode && (!isRetweeted ? avatarSize : mediumAvatarSize)) ||
      smallAvatarSize
    )
  }

  function renderBaseCardSummary() {
    if (!item || !item.thread || item?.thread.length == 0) return null
    return (
      <BaseCard
        columnId={columnId}
        nodeIdOrId={item.thread[0].id}
        isRetweeted={isRetweeted}
        shareMode={shareMode}
        type="COLUMN_TYPE_NEWS_FEED"
        rootRef={ref}
        rootNodeIdOrId={nodeIdOrId}
        defaultExpand={true}
        setThreadVisibility={() => setShowThread(true)}
        highlightText={highlightText}
      />
    )
  }

  function renderBaseCard() {
    if (!item) return null
    const markAsRead = () => {
      dispatch(
        setItemsReadStatus({
          itemNodeIds: !!rootNodeIdOrId
            ? [nodeIdOrId, rootNodeIdOrId]
            : [nodeIdOrId],
          read: true,
          syncup: true,
        }),
      )
    }
    return (
      <ViewShot ref={viewShotref} options={{ format: 'jpg', quality: 0.8 }}>
        <View
          ref={rootRef ? undefined : ref}
          style={{
            backgroundColor: isRetweeted
              ? 'transparent'
              : theme.isDark
              ? theme.backgroundColorLess1
              : theme.backgroundColor,
          }}
        >
          <TouchableWithoutFeedback onPress={() => markAsRead()}>
            {!!item.thread &&
              item.thread?.map((data, idx) =>
                idx == 0 ? (
                  <View key={`thread-card-${nodeIdOrId}-${data.id}`}>
                    <BaseCard
                      columnId={columnId}
                      nodeIdOrId={data.id}
                      isRetweeted={isRetweeted}
                      shareMode={shareMode}
                      type="COLUMN_TYPE_NEWS_FEED"
                      rootRef={ref}
                      rootNodeIdOrId={nodeIdOrId}
                      defaultExpand={true}
                      highlightText={highlightText}
                    />
                  </View>
                ) : (
                  <View key={`thread-card-${nodeIdOrId}-${data.id}`}>
                    <BaseCard
                      columnId={columnId}
                      nodeIdOrId={data.id}
                      isRetweeted={isRetweeted}
                      shareMode={shareMode}
                      hideActions={true}
                      type="COLUMN_TYPE_NEWS_FEED"
                      rootRef={ref}
                      defaultExpand={true}
                      highlightText={highlightText}
                    />
                  </View>
                ),
              )}
            <View
              key={`base-card-container-${type}-${nodeIdOrId}-inner`}
              style={{
                backgroundColor: !isRetweeted
                  ? 'transparent'
                  : theme.backgroundColorLess2,
                overflow: 'hidden',
              }}
            >
              <ImageViewer
                images={images}
                index={imageIndexToView}
                setIndex={setImageIndexToView}
              />
              <View style={[styles.innerContainer]}>
                {/* Render Header part of the card */}
                <View
                  style={[
                    sharedStyles.horizontal,
                    sharedStyles.marginVerticalQuarter,
                    isWeb && !hasTitle && sharedStyles.marginBottomHalf,
                  ]}
                >
                  <View
                    style={
                      largeMode && !isRetweeted
                        ? styles.avatarContainer
                        : styles.smallAvatarContainer
                    }
                  >
                    <Avatar
                      avatarUrl={subSource ? subSource.avatarURL : ''}
                      disableLink={false}
                      linkURL={profileUrl}
                      style={styles.avatar}
                      size={getAvatarSize()}
                    />
                  </View>
                  <ThemedText
                    color="foregroundColorMuted65"
                    numberOfLines={1}
                    style={[
                      styles.authorName,
                      sharedStyles.alignSelfCenter,
                      largeMode &&
                        (isRetweeted
                          ? sharedStyles.largeText
                          : sharedStyles.extraLargeText),
                    ]}
                    {...Platform.select({
                      web: { title: getFullDateText(timestamp) },
                    })}
                    onPress={() => {
                      markAsRead()
                      handleAvatarClick()
                    }}
                  >
                    {subSource ? subSource.name : ''}
                  </ThemedText>

                  <View
                    style={[
                      sharedStyles.horizontal,
                      sharedStyles.alignItemsCenter,
                    ]}
                  >
                    {!isRead &&
                      !isRetweeted &&
                      !shareMode &&
                      !rootItem?.isRead && (
                        <ThemedIcon
                          family="octicon"
                          name="dot-fill"
                          color={'primaryBackgroundColor'}
                          size={smallTextSize}
                        />
                      )}

                    <IntervalRefresh interval={60000} date={timestamp}>
                      {() => {
                        const dateText = getDateSmallText(timestamp)
                        if (!dateText) return null

                        return (
                          <>
                            <Text>{'  '}</Text>
                            <Link
                              analyticsCategory="card_action"
                              analyticsLabel={'card_link'}
                              enableUnderlineHover
                              href={
                                link?.startsWith('http') ||
                                link?.startsWith('https')
                                  ? link
                                  : `http://${link}`
                              }
                              onPress={() => markAsRead()}
                              textProps={{
                                color: 'foregroundColorMuted65',
                                style: { fontSize: smallTextSize },
                              }}
                            >
                              <ThemedText
                                color="foregroundColorMuted65"
                                numberOfLines={1}
                                style={[
                                  styles.timestampText,
                                  largeMode && sharedStyles.largeText,
                                ]}
                                {...Platform.select({
                                  web: { title: getFullDateText(timestamp) },
                                })}
                              >
                                {dateText.toLowerCase()}
                              </ThemedText>
                            </Link>
                          </>
                        )
                      }}
                    </IntervalRefresh>
                    {!shouldHideActions() && (
                      <>
                        <ThemedIcon
                          family="material"
                          name={
                            isCapturingView && isWeb ? 'camera' : 'camera-alt'
                          }
                          color={'foregroundColorMuted65'}
                          size={smallTextSize}
                          style={styles.actionIcon}
                          // markAsRead is called in handleClickCamera funcs
                          onPress={
                            isWeb
                              ? handleClickCameraOnWeb
                              : handleClickCameraOnNative
                          }
                        />
                        <ThemedIcon
                          family="material"
                          name={'share'}
                          color={'foregroundColorMuted65'}
                          size={smallTextSize}
                          style={styles.actionIcon}
                          // markAsRead is called in routeToSharedPostPage
                          onPress={routeToSharedPostPage}
                        />
                        <ThemedIcon
                          family="material"
                          name={'chat-bubble-outline'}
                          color={'foregroundColorMuted65'}
                          size={smallTextSize}
                          style={styles.actionIcon}
                          onPress={() => {
                            dispatch(setPostToShare({ postId: nodeIdOrId }))
                            markAsRead()
                          }}
                        />
                        <ThemedIcon
                          family="octicon"
                          name={isSaved ? 'bookmark-fill' : 'bookmark'}
                          color={isSaved ? 'orange' : 'foregroundColorMuted65'}
                          size={smallTextSize}
                          style={styles.actionIcon}
                          onPress={() => {
                            markAsRead()
                            dispatch(
                              setItemSavedStatus({
                                itemNodeId: nodeIdOrId,
                                save: !isSaved,
                              }),
                            )
                          }}
                        />
                      </>
                    )}
                  </View>
                </View>

                {/* Render Non Header part of the card */}
                <View>
                  <View style={[sharedStyles.vertical]}>
                    {hasTitle && (
                      <View
                        style={[
                          sharedStyles.horizontal,
                          sharedStyles.marginVerticalQuarter,
                        ]}
                      >
                        <View
                          style={[
                            sharedStyles.flex,
                            sharedStyles.alignSelfCenter,
                          ]}
                        >
                          <View
                            style={sharedStyles.horizontalAndVerticallyAligned}
                          >
                            <ThemedText
                              color="foregroundColor"
                              style={[
                                styles.title,
                                sharedStyles.flex,
                                largeMode && sharedStyles.extraLargeText,
                              ]}
                              onPress={() => {
                                markAsRead()
                                link &&
                                  Linking.openURL(
                                    link.startsWith('http')
                                      ? link
                                      : `http://${link}`,
                                  )
                              }}
                            >
                              {memorizedHighlightedTitle}
                            </ThemedText>
                          </View>
                        </View>
                      </View>
                    )}

                    {hasText && (
                      <View
                        style={[
                          sharedStyles.horizontal,
                          sharedStyles.marginTopQuarter,
                        ]}
                      >
                        <View
                          style={[
                            sharedStyles.flex,
                            sharedStyles.alignSelfCenter,
                          ]}
                        >
                          <View
                            style={sharedStyles.horizontalAndVerticallyAligned}
                          >
                            <ThemedText
                              color="foregroundColorMuted65"
                              style={[
                                // somehow during the screenshot library miscalculates
                                // the normal line height, more lines missing more vertical
                                // space, thus the height for last line is not enough.
                                // Specifying line height as number can fix the issue.
                                largeMode
                                  ? styles.lineHeightNormalForNormalText
                                  : styles.lineHeightNormalForExtraLargeText,
                                largeMode && sharedStyles.extraLargeText,
                              ]}
                              numberOfLines={9999}
                            >
                              {memorizedParsedAndHighlightedText}
                            </ThemedText>
                          </View>
                          {!shareMode && hasMore && !hasThread && (
                            <View
                              style={[
                                sharedStyles.horizontalAndVerticallyAligned,
                                styles.marginTop6,
                              ]}
                            >
                              <ThemedText
                                color="primaryBackgroundColor"
                                // markAsRead is called in toggleShowMoreText
                                onPress={toggleShowMoreText}
                                style={[
                                  styles.showMoreOrLessText,
                                  sharedStyles.flex,
                                ]}
                              >
                                {textShown ? 'show less' : 'show more'}
                              </ThemedText>
                            </View>
                          )}
                        </View>
                      </View>
                    )}

                    {/* {!isRetweeted && tags && tags.length > 0 && (
              <ButtonGroup data={tags.map((tag) => ({ id: tag, name: tag }))} />
            )} */}

                    {images.length > 0 && (
                      <View style={[styles.fileContainer]}>
                        {images.map((image, i) => {
                          return (
                            <TouchableHighlight
                              onPress={() => {
                                markAsRead()
                                setImageIndexToView(i)
                              }}
                              key={image.id}
                            >
                              <Image
                                source={{
                                  uri: image.tinyImageUrl || image.url,
                                }}
                                style={{
                                  width: 60 * scaleFactor,
                                  height: 60 * scaleFactor,
                                  margin: (shareMode ? 4 : 2) * scaleFactor,
                                }}
                                resizeMode="cover"
                              />
                            </TouchableHighlight>
                          )
                        })}
                      </View>
                    )}

                    {files.length > 0 && (
                      <View style={[styles.fileContainer]}>
                        {files.map((file) => {
                          return <FileDownloader key={file.id} file={file} />
                        })}
                      </View>
                    )}

                    {!!repostedFrom && (
                      <View>
                        <Spacer height={sizes.verticalSpaceSize} />
                        <BaseCard
                          {...repostedFrom}
                          rootNodeIdOrId={nodeIdOrId}
                          columnId={columnId}
                          isRetweeted={true}
                          showMoreSignal={showMoreSignal}
                          defaultExpand={shouldExpand}
                          shareMode={shareMode}
                          highlightText={highlightText}
                        />
                      </View>
                    )}

                    {!isRetweeted &&
                      !!duplicateIds &&
                      duplicateIds.length > 0 &&
                      !isCapturingView && (
                        <View style={[styles.deduplicationBarContainer]}>
                          {renderDeduplicationBar()}
                        </View>
                      )}

                    <Spacer height={sizes.verticalSpaceSize} />
                    {/*
          {!!renderCardActions && !isRetweeted && (
            <>
              <CardActions
                commentsCount={
                  undefined
                  // issueOrPullRequest ? issueOrPullRequest.comments : undefined
                }
                commentsLink={link}
                isRead={!!isRead}
                isSaved={!!isSaved}
                itemNodeId={nodeIdOrId}
                type={type}
              />

              <Spacer height={sizes.verticalSpaceSize} />
            </>
          )} */}
                  </View>
                </View>
              </View>
            </View>

            {(hasThread || !!setThreadVisibility) && (
              <View style={[styles.innerContainer]}>
                <View style={[sharedStyles.horizontal]}>
                  <View style={[sharedStyles.horizontalAndVerticallyAligned]}>
                    <ThemedText
                      color="primaryBackgroundColor"
                      onPress={() => {
                        markAsRead()
                        !!setThreadVisibility
                          ? setThreadVisibility()
                          : setShowThread(!showThread)
                      }}
                      style={[styles.showMoreOrLessText, sharedStyles.flex]}
                    >
                      {!!setThreadVisibility ? 'show thread' : 'hide thread'}
                    </ThemedText>
                  </View>
                </View>
              </View>
            )}

            {!isRetweeted &&
              showDuplication &&
              duplicateIds?.map((id, idx) => (
                <View
                  key={`duplication-card-${nodeIdOrId}-${id}`}
                  style={
                    idx === 0
                      ? {
                          shadowRadius: 10,
                          shadowColor: theme.foregroundColorMuted40,
                        }
                      : {
                          borderTopColor: theme.backgroundColorTransparent05,
                          borderTopWidth: 1 * scaleFactor,
                        }
                  }
                >
                  <BaseCard
                    columnId={columnId}
                    nodeIdOrId={id}
                    isRetweeted={true}
                    type="COLUMN_TYPE_NEWS_FEED"
                    highlightText={highlightText}
                  />
                </View>
              ))}
          </TouchableWithoutFeedback>
        </View>
      </ViewShot>
    )
  }

  return hasThread
    ? showThread
      ? renderBaseCard()
      : renderBaseCardSummary()
    : renderBaseCard()
})

BaseCard.displayName = 'BaseCard'
