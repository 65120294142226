import { NewsFeedPost } from '@devhub/core'
import moment from 'moment'

// return true if t1 is newer than t2
export function isNewerTimestamp(
  t1: string | undefined,
  t2: string | undefined,
) {
  if (!t1 || !t2) return false
  return moment(t1).valueOf() > moment(t2).valueOf()
}

// return true if t1 is older than t2
export function isOlderTimestamp(
  t1: string | undefined,
  t2: string | undefined,
) {
  if (!t1 || !t2) return false
  return moment(t1).valueOf() < moment(t2).valueOf()
}

export function postSortByLatest(p1: NewsFeedPost, p2: NewsFeedPost) {
  const p1Time = p1.postTime ?? p1.crawledTime ?? null
  const p2Time = p2.postTime ?? p2.crawledTime ?? null
  if (p1Time && p2Time) {
    const p1Date = new Date(p1Time)
    const p2Date = new Date(p2Time)
    if (p1Date > p2Date) return -1
    if (p1Date < p2Date) return 1
  }
  return 0
}
