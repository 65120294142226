import { AppViewMode } from '@devhub/core'
import { useMemo } from 'react'

import { useAppLayout } from '../components/context/LayoutContext'

export function useAppViewMode(): { appViewMode: AppViewMode } {
  const { appOrientation } = useAppLayout()

  // instead of using size name from layout, use orientation instead
  // to keep consistency of sideOrButtomMenuBar
  const appViewMode = appOrientation === 'portrait' ? 'single-column' : 'multi-column'

  return useMemo(() => ({ appViewMode }), [appViewMode])
}
