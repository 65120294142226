import React from 'react'
import { REGEX_IS_URL } from '@devhub/core/src/utils/constants'
import { ThemedText } from '../themed/ThemedText'
import { Linking } from 'react-native-web'

const highlightBackgroundColor = 'primaryBackgroundColor' // 'gold'
const highlightTextColor = 'primaryForegroundColor' // 'black'

export const getHighlightedText = (
  text?: string,
  highlightText?: string,
): string | (JSX.Element | string)[] => {
  if (!text) {
    return ''
  }
  if (!highlightText) {
    return text
  }
  return text
    .split(new RegExp(`(${highlightText})`, 'gi'))
    .map((segment, idx) =>
      segment.toLowerCase() === highlightText.toLowerCase() ? (
        <ThemedText
          key={idx}
          backgroundColor={highlightBackgroundColor}
          color={highlightTextColor}
        >
          {segment}
        </ThemedText>
      ) : (
        segment
      ),
    )
}

const parseSpecialCharacterAndHighlight = (
  text: string,
  textStyle: any,
  highlightText?: string,
) => {
  // Replace special characters
  text = text.replace('&amp;', '&')
  return text.split('\\n').map((txt, i, row) => {
    // If highlightText is provided, split the txt into segments and highlight accordingly
    const content = getHighlightedText(txt, highlightText)
    return (
      <ThemedText
        color="foregroundColorMuted65"
        key={`${txt} + ${i}`}
        style={textStyle}
      >
        {content}
        {i + 1 === row.length ? '' : '\n'}
      </ThemedText>
    )
  })
}

export const parseTextWithLinks = (
  text: string,
  textStyle: any,
  markAsRead: () => void,
  highlightText?: string,
) => {
  let prev = 0
  let match: RegExpExecArray | null = null
  const res: Array<JSX.Element[] | JSX.Element> = []

  while ((match = REGEX_IS_URL.exec(text ?? 'no content')) !== null) {
    const textLink = text.slice(match.index, match.index + match[0].length)
    res.push(
      parseSpecialCharacterAndHighlight(
        text.slice(prev, match.index),
        textStyle,
        highlightText,
      ),
    )
    res.push(
      <ThemedText
        color="red"
        key={res.length}
        style={textStyle}
        // assume most website will redirect http to https
        onPress={() => {
          markAsRead()
          void Linking.openURL(
            textLink.startsWith('http') || textLink.startsWith('https')
              ? textLink
              : `http://${textLink}`,
          )
        }}
      >
        {textLink}
      </ThemedText>,
    )
    prev = match.index + match[0].length
  }
  res.push(
    parseSpecialCharacterAndHighlight(
      text.slice(prev),
      textStyle,
      highlightText,
    ),
  )
  return res
}
