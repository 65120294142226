// test website: https://d1lv3sq2mzrrv8.cloudfront.net/index.html
import { Buffer } from 'buffer'
import { scaleFactor } from '../styles/variables';

const DefaultS3Bucket = 'newsfeed-crawler-image-output'
const DefaultImageWidth = 60 * scaleFactor
const DefaultImageHeight = DefaultImageWidth
const CloudFrontImageRegex = new RegExp('^(http|https)://(.*)cloudfront.net/(.*)')
const CloudFrontDomainForResize = 'https://d2cana2fc4gv86.cloudfront.net'

type ImageRequest = {
  bucket: string
  key: string
  edits: {
    resize?: ResizeOption
    flatten?: FlattenOption
  }
}

type ResizeMode = 'cover' | 'contain' | 'fill' | 'inside' | 'outside'

export type ResizeOption = {
  width: number
  height: number
  fit: ResizeMode
  background?: RGBA
}

type RGBA = {
  r: number
  g: number
  b: number
  alpha: null | number
}

type FlattenOption = {
  background?: RGBA
}

const defaultResizeOption: ResizeOption = {
  width: DefaultImageWidth,
  height: DefaultImageHeight,
  fit: 'cover',
  background: {
    r: 255,
    g: 255,
    b: 255,
    alpha: 255
  }
}

export const getResizedImageUrl = (url: string, resizeOption: ResizeOption = defaultResizeOption) => {
  const groups = url.match(CloudFrontImageRegex)
  if(!groups) {
    return url
  }
  // [
  //   "https://d20uffqoe1h0vv.cloudfront.net/328fc525afe7cb77da87719587c0780f.jpg",
  //   "https",
  //   "d20uffqoe1h0vv.",
  //   "328fc525afe7cb77da87719587c0780f.jpg",
  // ]
  if (groups.length !== 4) {
    console.error('matched url should have 5 groups', url)
    return url
  }
  try {
    const image = groups[3]
    const request: ImageRequest = {
      "bucket": DefaultS3Bucket,
      "key": image,
      "edits": {
        "resize": resizeOption
      }
    }

    const stringifiedImageRequest = JSON.stringify(request)
    const newurl = `${CloudFrontDomainForResize}/${Buffer.from(stringifiedImageRequest).toString('base64')}`
    return newurl
  } catch (e) {
    console.error('exception happens for getResizedImageUrl ', e)
    return url
  }
}
