import { NewsFeedPost } from '@devhub/core'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  NewsFeedCards,
  EventCardsProps,
} from '../components/cards/NewsFeedCards'
import * as actions from '../redux/actions'
import { useColumn } from '../hooks/use-column'
import { useColumnData } from '../hooks/use-column-data'
import { useReduxState } from '../hooks/use-redux-state'
import { useLoginHelpers } from '../components/context/LoginHelpersContext'
import * as selectors from '../redux/selectors'

export interface NewsFeedCardsContainerProps
  extends Omit<
    EventCardsProps,
    | 'column'
    | 'errorMessage'
    | 'fetchNextPage'
    | 'getItemByNodeIdOrId'
    | 'isShowingOnlyBookmarks'
    | 'itemNodeIdOrIds'
    | 'delayedItemNodeIds'
    | 'showDelayedNewItems'
    | 'lastFetchSuccessAt'
    | 'refresh'
  > {
  columnId: string
}

export const NewsFeedCardsContainer = React.memo(
  (props: NewsFeedCardsContainerProps) => {
    const { columnId, ...otherProps } = props

    const {
      filteredItemsIds,
      getItemByNodeIdOrId,
      allDelayedNewItemsIds,
      showDelayedNewItems,
      searchQuery,
      hasMoreItems,
    } = useColumnData<NewsFeedPost>(columnId, { mergeSimilar: false })

    const { isLoggingIn } = useLoginHelpers()
    const dispatch = useDispatch()

    const appToken = useReduxState(selectors.appTokenSelector)
    const { column, hasCrossedColumnsLimit } = useColumn(columnId)

    if (!column) return null
    const fetchNextPage = useCallback(() => {
      dispatch(
        actions.fetchColumnDataRequest({
          columnId,
          direction: 'OLD',
          notifyOnNewPosts: false,
        }),
      )
    }, [columnId])

    const fetchLatestPage = useCallback(() => {
      dispatch(
        actions.fetchColumnDataRequest({
          columnId,
          direction: 'NEW',
          notifyOnNewPosts: false,
        }),
      )
    }, [columnId])

    return (
      <NewsFeedCards
        {...otherProps}
        key={`event-cards-${columnId}`}
        columnId={columnId}
        errorMessage={''}
        fetchNextPage={fetchNextPage}
        hasMoreItems={hasMoreItems}
        getItemByNodeIdOrId={getItemByNodeIdOrId}
        isShowingOnlyBookmarks={!!(column.filters && column.filters.saved)}
        itemNodeIdOrIds={filteredItemsIds}
        delayedItemNodeIds={allDelayedNewItemsIds}
        showDelayedNewItems={showDelayedNewItems}
        lastFetchSuccessAt={''}
        refresh={fetchLatestPage}
        highlightText={searchQuery}
      />
    )
  },
)

NewsFeedCardsContainer.displayName = 'NewsFeedCardsContainer'
