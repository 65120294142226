import { HeaderDetails } from '@devhub/core'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'

import * as selectors from '../redux/selectors'
import { useReduxState } from './use-redux-state'

export function useFeed(feedId: string) {
  const feed = useReduxState(
    useCallback((state) => selectors.feedSelector(state, feedId), [feedId]),
  )

  const headerDetails: HeaderDetails = {
    title: feed ? feed.title : '',
  }

  return useMemo(
    () => ({
      feed,
      headerDetails,
    }),
    [feed, headerDetails],
  )
}
