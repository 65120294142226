import { BannerMessage, SeedState, Signal } from '@devhub/core'
import { SlackChannel } from '../../utils/constants'
import { createAction } from '../helpers'

export function closeBannerMessage(id: string) {
  return createAction('CLOSE_BANNER_MESSAGE', id)
}

export function updateSeedState(seedState: SeedState) {
  return createAction('UPDATE_SEED_STATE', seedState)
}

export function setBannerMessage(banner: BannerMessage) {
  return createAction('SET_BANNER_MESSAGE', banner)
}

export function resetBannerMessage() {
  return createAction('RESET_BANNER_MESSAGE')
}

export function handleSignal(signal: Signal) {
  return createAction('HANDLE_SIGNAL', signal)
}

export function setPostToShare(payload: { postId: string }) {
  return createAction('SET_POST_TO_SHARE', payload)
}

export function clearPostToShare() {
  return createAction('CLEAR_POST_TO_SHARE')
}

export function sharePostToSlack(payload: {
  comment: string
  slackChannel: SlackChannel
}) {
  return createAction('SHARE_POST_TO_SLACK', payload)
}
