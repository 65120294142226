import { Column, constants, getDateSmallText, NewsFeedPost } from '@devhub/core'
import React, { useCallback, useMemo, useRef } from 'react'
import { Dimensions, View } from 'react-native'
import { useDispatch } from 'react-redux'

import {
  getCardPropsForItem,
  getCardSizeForProps,
} from '../components/cards/BaseCard.shared'
import {
  CardsFooter,
  CardsFooterProps,
  getCardsFooterSize,
} from '../components/cards/CardsFooter'
import {
  CardsOwnerFilterBar,
  cardsOwnerFilterBarTotalHeight,
} from '../components/cards/CardsOwnerFilterBar'
import { CardsSearchHeader } from '../components/cards/CardsSearchHeader'
import { EmptyCards } from '../components/cards/EmptyCards'
import { columnHeaderHeight } from '../components/columns/ColumnHeader'
import { ColumnLoadingIndicator } from '../components/columns/ColumnLoadingIndicator'
import { Button } from '../components/common/Button'
import { RefreshControl } from '../components/common/RefreshControl'
import { useAppLayout } from '../components/context/LayoutContext'
import { ThemedText } from '../components/themed/ThemedText'
import { ThemedTouchableHighlight } from '../components/themed/ThemedTouchableHighlight'
import { ThemedView } from '../components/themed/ThemedView'
import { emitter } from '../libs/emitter'
import { OneListProps } from '../libs/one-list'
import { useSafeArea } from '../libs/safe-area-view'
import {
  cleanOrInsertColumnDelayedNewPosts,
  setColumnVisibleItems,
  setItemsReadStatus,
  toggleColumnDelayedNewPostsVisibility,
} from '../redux/actions'
import * as selectors from '../redux/selectors'
import { sharedStyles } from '../styles/shared'
import { normalTextSize, smallTextSize } from '../styles/variables'
import { useColumn } from './use-column'
import { useColumnLoadingState } from './use-column-loading-state'
import { useReduxState } from './use-redux-state'

export type DataItemT = string

export function useCardsProps<ItemT extends NewsFeedPost>({
  columnId,
  fetchNextPage,
  getItemByNodeIdOrId,
  itemNodeIdOrIds,
  delayedItemNodeIds,
  lastFetchSuccessAt,
  showDelayedNewItems,
  refresh,
  type,
  hasMoreItems,
}: {
  columnId: Column['id'] | undefined
  fetchNextPage: CardsFooterProps['fetchNextPage']
  getItemByNodeIdOrId: (nodeIdOrId: string) => ItemT | undefined
  itemNodeIdOrIds: string[] | undefined
  delayedItemNodeIds: string[]
  showDelayedNewItems: boolean
  lastFetchSuccessAt: string | undefined
  refresh: CardsFooterProps['refresh']
  type: 'COLUMN_TYPE_NEWS_FEED'
  hasMoreItems: boolean
}) {
  const visibleItemIndexesRef = useRef({ from: -1, to: -1 })

  const appSafeAreaInsets = useSafeArea()
  const { appOrientation } = useAppLayout()
  const { column, columnIndex, isOverMaxColumnLimit } = useColumn(
    columnId || '',
  )

  const dispatch = useDispatch()
  const appToken = useReduxState(selectors.appTokenSelector)

  const data: DataItemT[] = itemNodeIdOrIds || []
  const firstVisibleItemId = column?.firstVisibleItemId
  const loadingState = useColumnLoadingState(columnId || '')

  const getItemSize = useCallback<
    NonNullable<OneListProps<DataItemT>['getItemSize']>
  >(
    (nodeIdOrId) => {
      const item = getItemByNodeIdOrId(nodeIdOrId)
      if (!item) return 0

      const itemCardProps = getCardPropsForItem(type, columnId || '', item)
      if (!itemCardProps) return 0

      return getCardSizeForProps(itemCardProps)
    },
    [columnId, getCardSizeForProps, type],
  )

  const itemSeparator = undefined

  const toggleDelayedNewPostsVisibility = useCallback(() => {
    if (columnId) {
      dispatch(toggleColumnDelayedNewPostsVisibility({ columnId }))
      emitter.emit('SCROLL_TOP_COLUMN', {
        columnId,
      })
    }
  }, [columnId])

  const markDelayedNewPostsAsRead = useCallback(() => {
    if (columnId) {
      dispatch(toggleColumnDelayedNewPostsVisibility({ columnId }))
      emitter.emit('SCROLL_TOP_COLUMN', {
        columnId,
      })

      if (delayedItemNodeIds.length > 0) {
        // todo: if needed dispatch set read only for unread ones
        dispatch(
          setItemsReadStatus({
            itemNodeIds: delayedItemNodeIds,
            read: true,
            syncup: true,
          }),
        )
        dispatch(cleanOrInsertColumnDelayedNewPosts({ columnId }))
      }
    }
  }, [columnId, delayedItemNodeIds.join(',')])

  const fixedHeaderComponent = useMemo(
    () =>
      !!column && (
        <View style={[sharedStyles.relative, sharedStyles.fullWidth]}>
          <CardsSearchHeader
            key={`cards-search-header-column-${column.id}`}
            columnId={column.id}
          />
          {delayedItemNodeIds.length > 0 && (
            <ThemedView
              backgroundColor="backgroundColorLess2"
              style={[
                sharedStyles.fullWidth,
                sharedStyles.paddingHalf,
                sharedStyles.paddingHorizontal,
                sharedStyles.justifyContentSpaceBetween,
                sharedStyles.displayFlex,
                sharedStyles.horizontal,
              ]}
            >
              <ThemedText
                color={'foregroundColor'}
                style={[
                  sharedStyles.alignItemsCenter,
                  sharedStyles.displayFlex,
                  sharedStyles.alignSelfCenter,
                ]}
              >
                {showDelayedNewItems
                  ? `Showing ${delayedItemNodeIds.length} delayed posts`
                  : `${delayedItemNodeIds.length} delayed posts received`}
              </ThemedText>
              <ThemedTouchableHighlight>
                <Button
                  backgroundColor={'red'}
                  onPress={
                    showDelayedNewItems
                      ? markDelayedNewPostsAsRead
                      : toggleDelayedNewPostsVisibility
                  }
                  withBorder
                  size={normalTextSize * 2}
                >
                  <ThemedText
                    color={'foregroundColor'}
                    style={{ lineHeight: smallTextSize }}
                  >
                    {showDelayedNewItems ? 'Mark as read' : 'Show'}
                  </ThemedText>
                </Button>
              </ThemedTouchableHighlight>
            </ThemedView>
          )}
          <ColumnLoadingIndicator columnId={column.id} />
        </View>
      ),
    [column && column.id, delayedItemNodeIds.join(','), showDelayedNewItems],
  )

  const header = useMemo<OneListProps<DataItemT>['header']>(() => {
    const renderOwnerFilterBar = false

    const size = column
      ? renderOwnerFilterBar
        ? cardsOwnerFilterBarTotalHeight
        : 0
      : 0

    return {
      size,
      sticky: false,
      Component() {
        return (
          <View style={[sharedStyles.fullWidth, { height: size }]}>
            {!!column && (
              <>
                {!!renderOwnerFilterBar && (
                  <CardsOwnerFilterBar
                    key={`cards-owner-filter-bar-column-${column.id}`}
                    columnId={column.id}
                  />
                )}
              </>
            )}
          </View>
        )
      },
    }
  }, [column && column.id, column && column.type, !!(data || []).length])

  const cardsFooterProps: CardsFooterProps = {
    clearedAt: 'DUMMY_CLEAR_AT',
    columnId: (column && column.id)!,
    fetchNextPage,
    isEmpty: !((data || []).length > 0),
    hasMore: hasMoreItems,
    refresh,
    topSpacing: (!data.length && header && header.size) || 0,
  }

  let _tempTotalOffset = 0
  const sticky = !!(
    !fetchNextPage &&
    cardsFooterProps.clearedAt &&
    itemNodeIdOrIds &&
    !itemNodeIdOrIds.some((nodeIdOrId, index) => {
      const itemSize = getItemSize(nodeIdOrId, index)
      if (!itemSize) return

      _tempTotalOffset += itemSize

      if (
        _tempTotalOffset >
        Dimensions.get('window').height -
          ((header && header.size) || 0) -
          columnHeaderHeight
      ) {
        return true
      }
    })
  )

  const footer = useMemo<OneListProps<DataItemT>['footer']>(() => {
    if (isOverMaxColumnLimit) return undefined

    return {
      size: getCardsFooterSize({
        clearedAt: cardsFooterProps.clearedAt,
        hasFetchNextPage: !!cardsFooterProps.fetchNextPage,
        isEmpty: cardsFooterProps.isEmpty,
        topSpacing: cardsFooterProps.topSpacing,
      }),
      sticky,
      Component() {
        return <CardsFooter {...cardsFooterProps} />
      },
    }
  }, [
    (!data.length && header && header.size) || 0,
    cardsFooterProps.clearedAt,
    cardsFooterProps.columnId,
    cardsFooterProps.fetchNextPage,
    cardsFooterProps.isEmpty,
    cardsFooterProps.refresh,
    cardsFooterProps.hasMore,
    isOverMaxColumnLimit,
    sticky,
  ])

  const safeAreaInsets: OneListProps<DataItemT>['safeAreaInsets'] = useMemo(
    () => ({
      bottom: appOrientation === 'landscape' ? appSafeAreaInsets.bottom : 0,
    }),
    [appOrientation, appSafeAreaInsets.bottom],
  )

  const onVisibleItemsChanged = useCallback<
    NonNullable<OneListProps<DataItemT>['onVisibleItemsChanged']>
  >(
    (from, to) => {
      visibleItemIndexesRef.current = { from, to }
      dispatch(
        setColumnVisibleItems({
          columnId,
          firstVisibleItemId: data[from],
          lastVisibleItemId: data[to],
        }),
      )
    },
    [data],
  )

  const refreshControl = useMemo(
    () => (
      <RefreshControl
        intervalRefresh={lastFetchSuccessAt}
        onRefresh={refresh}
        refreshing={loadingState === 'loading'}
        title={
          lastFetchSuccessAt
            ? `Last updated ${getDateSmallText(lastFetchSuccessAt, {
                includeExactTime: true,
              })}`
            : 'Pull to refresh'
        }
      />
    ),
    [lastFetchSuccessAt, refresh, loadingState],
  )

  const OverrideRender = useMemo<{
    Component: React.ComponentType | undefined
    overlay?: boolean
  }>(() => {
    if (!(column && column.id)) return { Component: undefined, overlay: false }

    if (isOverMaxColumnLimit) {
      return {
        Component() {
          return (
            <EmptyCards
              columnId={column.id}
              errorMessage={`You have reached the limit of ${constants.COLUMNS_LIMIT} columns. This is to maintain a healthy usage of the GitHub API.`}
              errorTitle="Too many columns"
              fetchNextPage={undefined}
              loadState="error"
              refresh={undefined}
            />
          )
        },
        overlay: false,
      }
    }

    return { Component: undefined, overlay: false }
  }, [column && column.id, columnIndex, isOverMaxColumnLimit, appToken])

  return useMemo(
    () => ({
      OverrideRender,
      data,
      fixedHeaderComponent,
      footer,
      getItemSize,
      header,
      itemSeparator,
      onVisibleItemsChanged,
      refreshControl,
      safeAreaInsets,
      visibleItemIndexesRef,
      firstVisibleItemId,
    }),
    [
      OverrideRender,
      data,
      fixedHeaderComponent,
      footer,
      getItemSize,
      header,
      itemSeparator,
      onVisibleItemsChanged,
      refreshControl,
      safeAreaInsets,
      visibleItemIndexesRef,
    ],
  )
}
