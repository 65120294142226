import { HeaderDetails } from '@devhub/core'
import React, { useMemo } from 'react'

import { NewsFeedCardsContainerProps } from '../../containers/NewsFeedCardsContainer'
import { IconProp } from '../../libs/vector-icons'
import { ColumnRenderer, ColumnRendererProps } from './ColumnRenderer'
import { SearchFeedCardsContainer } from '../../containers/SearchFeedCardsContainer'

export interface SearchPostsColumnProps
  extends Omit<NewsFeedCardsContainerProps, 'ownerIsKnown' | 'repoIsKnown'> {
  columnIndex: number
  headerDetails: HeaderDetails
  pagingEnabled?: boolean
}

export const SearchPostsColumn = React.memo((props: SearchPostsColumnProps) => {
  const {
    columnId,
    columnIndex,
    headerDetails,
    pagingEnabled,
    pointerEvents,
    swipeable,
    hasMoreItems,
    emptyText,
  } = props

  const Children = useMemo<ColumnRendererProps['children']>(
    () => (
      <SearchFeedCardsContainer
        key={`event-cards-container-${columnId}`}
        columnId={columnId}
        pointerEvents={pointerEvents}
        swipeable={swipeable}
        hasMoreItems={hasMoreItems}
        emptyText={emptyText}
      />
    ),
    [columnId, columnIndex, pointerEvents, swipeable],
  )

  if (!headerDetails) return null

  return (
    <ColumnRenderer
      key={`event-column-${columnId}-inner`}
      avatarImageURL={
        headerDetails.avatarProps && headerDetails.avatarProps.imageURL
      }
      avatarLinkURL={
        headerDetails.avatarProps && headerDetails.avatarProps.linkURL
      }
      columnId={columnId}
      columnType="COLUMN_TYPE_SEARCH"
      columnIndex={columnIndex}
      icon={headerDetails.icon as IconProp}
      pagingEnabled={pagingEnabled}
      subtitle={headerDetails.subtitle}
      title={headerDetails.title}
    >
      {Children}
    </ColumnRenderer>
  )
})

SearchPostsColumn.displayName = 'SearchPostsColumn'
