import React from 'react'

import { NewsFeedColumn } from '../components/columns/NewsFeedColumn'
import { useColumn } from '../hooks/use-column'
import { bugsnag } from '../libs/bugsnag'
import { SearchPostsColumn } from '../components/columns/SearchPostsColumn'
import {
  LOADING,
  NEWS_FEED_COLUMN_EMPTY,
  SEARCH_COLUMN_NO_RESULT,
  SEARCH_COLUMN_TYPE_TO_SEARCH,
} from '../resources/strings'

export interface ColumnContainerProps {
  columnId: string
  pagingEnabled?: boolean
  swipeable: boolean
}

export const ColumnContainer = React.memo((props: ColumnContainerProps) => {
  const { columnId, pagingEnabled, swipeable } = props

  const { column, columnIndex, headerDetails } = useColumn(columnId)

  if (!(column && columnIndex >= 0 && headerDetails)) return null

  switch (column.type) {
    case 'COLUMN_TYPE_NEWS_FEED': {
      const emptyText =
        column.state === 'loading' ? LOADING : NEWS_FEED_COLUMN_EMPTY
      return (
        <NewsFeedColumn
          key={`event-column-${column.id}`}
          columnId={column.id}
          columnIndex={columnIndex}
          headerDetails={headerDetails}
          pagingEnabled={pagingEnabled}
          swipeable={swipeable}
          hasMoreItems={!column.reachEnd}
          emptyText={emptyText}
        />
      )
    }

    case 'COLUMN_TYPE_SEARCH': {
      let emptyText = LOADING
      if (column.state !== 'loading') {
        emptyText = !!column.filters?.query
          ? SEARCH_COLUMN_NO_RESULT
          : SEARCH_COLUMN_TYPE_TO_SEARCH
      }
      return (
        <SearchPostsColumn
          key={`event-column-${column.id}`}
          columnId={column.id}
          columnIndex={columnIndex}
          headerDetails={headerDetails}
          pagingEnabled={pagingEnabled}
          swipeable={swipeable}
          hasMoreItems={!column.reachEnd}
          emptyText={emptyText}
        />
      )
    }

    default: {
      const message = `Invalid Column type: ${column && column.type}`
      console.error(message, { column })
      bugsnag.notify(new Error(message))
      return null
    }
  }
})

ColumnContainer.displayName = 'ColumnContainer'
