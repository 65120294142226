import { Modal, Pressable, TextInput, View, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import { ThemedText } from '../themed/ThemedText'
import { SlackChannel, SLACK_WEBHOOKS } from '../../utils/constants'
import { useDispatch } from 'react-redux'
import { clearPostToShare, sharePostToSlack } from '../../redux/actions'
import { useReduxState } from '../../hooks/use-redux-state'
import { postIDToShare } from '../../redux/selectors'
import { useTheme } from '../context/ThemeContext'
import Picker from '../../libs/picker'

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  input: {
    height: 40,
    margin: 12,
    width: 200,
    borderWidth: 1,
    padding: 13,
    borderRadius: 5,
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  rowView: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  picker: {
    width: 200,
  },
  columnView: {
    marginTop: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    borderRadius: 3,
    padding: 10,
    elevation: 2,
  },
  buttonTextStyle: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  container: {
    overflow: 'hidden',
  },
})
const DEFAULT_SLACK_CHANNEL: SlackChannel = 'ukraine'

export const SlackShareModal = () => {
  const [selectedSlackChannel, setSelectedSlackChannel] =
    useState<SlackChannel>(DEFAULT_SLACK_CHANNEL)
  const [comment, setComment] = useState('')
  const dispatch = useDispatch()
  const postId = useReduxState(postIDToShare)
  const theme = useTheme()
  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={!!postId}
      onRequestClose={() => {
        dispatch(clearPostToShare)
      }}
    >
      <View style={styles.centeredView}>
        <View
          style={[
            styles.modalView,
            styles.columnView,
            { backgroundColor: theme.backgroundColor },
          ]}
        >
          <Picker
            prompt="channel"
            style={[styles.picker]}
            selectedValue={selectedSlackChannel}
            onValueChange={(itemValue) => setSelectedSlackChannel(itemValue)}
          >
            {Object.entries(SLACK_WEBHOOKS).map(([key]: [string, string]) => {
              return <Picker.Item key={key} label={key} value={key} />
            })}
          </Picker>
          <TextInput
            style={[styles.input, { color: theme.foregroundColorMuted65 }]}
            onChangeText={setComment}
            value={comment}
            placeholder="comment(optional)"
            keyboardType="numeric"
          />
          <View style={styles.rowView}>
            <Pressable
              style={[
                styles.button,
                { backgroundColor: theme.backgroundColorDarker2 },
              ]}
              onPress={() => {
                dispatch(
                  sharePostToSlack({
                    comment,
                    slackChannel: selectedSlackChannel,
                  }),
                )
                setComment('')
              }}
            >
              <ThemedText
                color={theme.foregroundColor}
                style={[styles.buttonTextStyle]}
              >
                Share to Slack
              </ThemedText>
            </Pressable>
            <Pressable
              style={[
                styles.button,
                { backgroundColor: theme.backgroundColorDarker1 },
              ]}
              onPress={() => {
                dispatch(clearPostToShare())
              }}
            >
              <ThemedText
                color={theme.foregroundColorMuted65}
                style={[styles.buttonTextStyle]}
              >
                Hide
              </ThemedText>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  )
}
