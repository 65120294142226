import {
  ColumnCreation,
  FeedCreation,
  FeedFilter,
  NewsFeedColumn,
  NewsFeedPost,
} from '@devhub/core'
import { EmitterTypes } from '../../libs/emitter'
import { createAction } from '../helpers'
import {
  FeedResponse,
  ColumnResponse,
  FeedSubSourceResponse,
} from '../types/column'

export function subscribeColumn(payload: { columnId: string }) {
  return createAction('SUBSCRIBE_COLUMN', payload)
}

export function removeSubscriptionFromColumn(payload: {
  columnId: string
  subscriptionId: string
}) {
  return createAction('REMOVE_SUBSCRIPTION_FROM_COLUMN', payload)
}

export function deleteColumn(payload: {
  columnId: string
  columnIndex: number
}) {
  return createAction('DELETE_COLUMN', payload)
}

export function removeFeedFromColumn(payload: {
  columnId: string
  feedId: string
}) {
  return createAction('REMOVE_FEED_FROM_COLUMN', payload)
}

export function moveColumn(
  payload: {
    columnId: string
    columnIndex: number
  } & Omit<EmitterTypes['FOCUS_ON_COLUMN'], 'focusOnVisibleItem'>,
) {
  return createAction('MOVE_COLUMN', payload)
}

export function addColumn(payload: ColumnCreation) {
  return createAction('ADD_COLUMN', payload)
}

export function addFeed(payload: FeedCreation) {
  return createAction('ADD_FEED', payload)
}

export function setSharedColumns(payload: {
  columns: NewsFeedColumn[]
  feeds: FeedResponse[]
}) {
  return createAction('SET_SHARED_COLUMNS', payload)
}

export function setFavoriteFeeds(payload: { favoriteFeeds: FeedResponse[] }) {
  return createAction('SET_FAVORITE_FEEDS', payload)
}

export function clearColumnFilters(payload: { columnId: string }) {
  return createAction('CLEAR_COLUMN_FILTERS', payload)
}

export function setColumnSavedFilter(payload: {
  columnId: string
  saved?: boolean
  unread?: boolean
}) {
  return createAction('SET_COLUMN_SAVED_FILTER', payload)
}

export function setColumnParticipatingFilter(payload: {
  columnId: string
  participating: boolean
}) {
  return createAction('SET_COLUMN_PARTICIPATING_FILTER', payload)
}

export function setColumnLabelFilter(payload: {
  columnId: string
  label: string
  value: boolean | null
  removeIfAlreadySet?: boolean
  removeOthers?: boolean
}) {
  return createAction('SET_COLUMN_LABEL_FILTER', payload)
}

export function setColumnInvolvesFilter(payload: {
  columnId: string
  user: string
  value: boolean | null
}) {
  return createAction('SET_COLUMN_INVOLVES_FILTER', payload)
}

export function replaceColumnWatchingFilter(payload: {
  columnId: string
  owner: string | null
}) {
  return createAction('REPLACE_COLUMN_WATCHING_FILTER', payload)
}

export function setColumnWatchingFilter(payload: {
  columnId: string
  owner: string
  value: boolean | null
}) {
  return createAction('SET_COLUMN_WATCHING_FILTER', payload)
}

export function replaceColumnOwnerFilter(payload: {
  columnId: string
  owner: string | null
}) {
  return createAction('REPLACE_COLUMN_OWNER_FILTER', payload)
}

export function replaceColumnFilters(payload: {
  columnId: string
  filter: FeedFilter
}) {
  return createAction('REPLACE_COLUMN_FILTER', payload)
}

export function replaceSearchColumnFilters(payload: {
  columnId: string
  filter: FeedFilter
}) {
  return createAction('REPLACE_SEARCH_COLUMN_FILTER', payload)
}

export function setColumnOwnerFilter(payload: {
  columnId: string
  owner: string
  value: boolean | null
}) {
  return createAction('SET_COLUMN_OWNER_FILTER', payload)
}

// ???
export function setColumnRepoFilter(payload: {
  columnId: string
  owner: string
  repo: string
  value: boolean | null
}) {
  return createAction('SET_COLUMN_REPO_FILTER', payload)
}

// ???
export function setColumnClearedAtFilter(payload: {
  columnId: string
  clearedAt: string | null
}) {
  return createAction('SET_COLUMN_CLEARED_AT_FILTER', payload)
}

export function clearAllColumnsWithConfirmation(
  payload: { clearedAt?: string | null } = {},
) {
  return createAction('CLEAR_ALL_COLUMNS_WITH_CONFIRMATION', payload)
}

export function clearAllColumns(payload: { clearedAt?: string | null } = {}) {
  return createAction('CLEAR_ALL_COLUMNS', payload)
}

export function changeIssueNumberFilter(payload: {
  columnId: string
  issueNumber: number
  value: boolean | null
  removeIfAlreadySet?: boolean
  removeOthers?: boolean
}) {
  return createAction('CHANGE_ISSUE_NUMBER_FILTER', payload)
}

// updateColumnId should only be called when creating a new column. Frontend will
// create a placeholder guid for fast response when user creates a column. Then,
// once the backend responded with a new uuid, frontend should update the
// columnId correspondingly.
export function updateColumnId(payload: { prevId: string; updatedId: string }) {
  return createAction('UPDATE_COLUMN_ID', payload)
}

export function updateFeed(payload: {
  prevId: string
  updatedId: string
  prevColumnId: string
  updatedColumnId: string
  name: string
  filterDataExpression: string
  subSources: FeedSubSourceResponse[]
  visibility: string
  addToColumn: boolean
}) {
  return createAction('UPDATE_FEED', payload)
}

// export function updateFeedIdWithColumnId(payload: {
//   prevId: string
//   updatedId: string
//   prevColumnId: string
//   updatedColumnId: string
// }) {
//   return createAction('UPDATE_FEED_ID_WITH_COLUMN_ID', payload)
// }

export function setColumnLoading(payload: { columnId: string }) {
  return createAction('SET_COLUMN_LOADING', payload)
}

export function fetchColumnDataRequest(payload: {
  // columnId is the subject column we're fetching data for
  columnId: string
  // NEW stands for "refresh", OLD stands for "load more". The initial fetch or
  // the first fetch after column attribute change is denoted as OLD.
  direction: 'NEW' | 'OLD'
  // this is only working on web
  notifyOnNewPosts: boolean
  force?: boolean // ignore loading state and fetch anyway
}) {
  return createAction('FETCH_COLUMN_DATA_REQUEST', payload)
}

export function fetchSearchPostsRequest(payload: {
  // NEW stands for "refresh", OLD stands for "load more". The initial fetch or
  // the first fetch after column attribute change is denoted as OLD.
  direction: 'NEW' | 'OLD'
  // the associated column id, so far we only have one column for search
  // with this field we could have multiple columns for search in the future
  columnId: string
  force?: boolean // ignore loading state and fetch anyway
}) {
  return createAction('FETCH_SEARCH_POSTS_REQUEST', payload)
}

export function fetchColumnDataFailure(payload: { columnId: string }) {
  return createAction('FETCH_COLUMN_DATA_FAILURE', payload)
}

export function fetchColumnDataSuccess(payload: {
  // columnId is the subject column we're fetching data for
  columnId: string
  // NEW stands for "refresh", OLD stands for "load more". The initial fetch or
  // the first fetch after column attribute change is denoted as OLD.
  direction: 'NEW' | 'OLD'
  // feeds associated with the column, each feed contains posts
  column: ColumnResponse
  // posts data
  data: NewsFeedPost[]
  // delayed new posts data,
  delayedNewPosts: NewsFeedPost[]
  // a list of NewsFeedData that we fetched
  // data: NewsFeedPost[]
  // Timestamp in miliseconds. Backend should return updatedAt to make sure the
  // frontend is always up to date with this timestamp.
  updatedAt: string
  // the caller should decide whether to drop existing data in this column.
  // e.g. when the data fetched reached the limit, it's very likely that there's
  // a "gap" between existing data and newly fetched data, and thus should drop
  // the existing ones.
  dropExistingData: boolean
  // providing access to the data store, which is needed to get current cursor.
  dataByNodeId: Record<string, NewsFeedPost>
  // optionally, pass data expression to update the column attributes.
  // dataExpression?: NewsFeedDataExpressionWrapper
  // optionall, pass in sources which can update the existing column's attribute
  // sources?: FeedSource[]
  // enable remote notification for mobile devices
  mobileNotification: boolean
  // enable web notificationo in browsers
  webNotification: boolean
  // show unread indicator at side/bottom bar for a column icon/button
  enableAppIconUnreadIndicator: boolean
}) {
  return createAction('FETCH_COLUMN_DATA_SUCCESS', payload)
}

// success received search result from backend
export function fetchSearchPostsSuccess(payload: {
  // columnId is the subject column we're fetching data for, in case we have multiple search columns
  columnId: string
  // NEW stands for "refresh", OLD stands for "load more". The initial fetch or
  // the first fetch after column attribute change is denoted as OLD.
  direction: 'NEW' | 'OLD'
  // posts data
  data: NewsFeedPost[]
  // the caller should decide whether to drop existing data in this column.
  // e.g. when the data fetched reached the limit, it's very likely that there's
  // a "gap" between existing data and newly fetched data, and thus should drop
  // the existing ones.
  dropExistingData: boolean
}) {
  return createAction('FETCH_SEARCH_POSTS_SUCCESS', payload)
}

// request to update all column notification settings
export function setColumnNotificationSettingsRequested(payload: {
  columnId: string
  enableMobileNotification: boolean
  enableWebNotification: boolean
  enableAppIconUnreadIndicator: boolean
}) {
  return createAction('SET_COLUMN_NOTIFICATION_SETTINGS_REQUESTED', payload)
}

// update column's notification settings to redux store
export function setColumnNotificationSettingsSuccess(payload: {
  columnId: string
  enableMobileNotification: boolean
  enableWebNotification: boolean
  enableAppIconUnreadIndicator: boolean
}) {
  return createAction('SET_COLUMN_NOTIFICATION_SETTINGS_SUCCESS', payload)
}

// clean old posts ids from a column and update corresponding feeds
export function cleanColumnOldPosts(payload: { columnId: string }) {
  return createAction('CLEAN_COLUMN_OLD_POSTS', payload)
}

// clean data from store, including posts data and feed data
export function cleanUnusedPostsData() {
  return createAction('CLEAN_UNUSED_POSTS_DATA')
}

export function cleanUnusedFeedsData() {
  return createAction('CLEAN_UNUSED_FEEDS_DATA')
}

export function toggleColumnDelayedNewPostsVisibility(payload: {
  columnId: string
}) {
  return createAction('TOGGLE_COLUMN_DELAYED_NEW_POSTS_VISIBILITTY', payload)
}

export function cleanOrInsertColumnDelayedNewPosts(payload: {
  columnId: string
}) {
  return createAction('CLEAN_OR_INSERT_COLUMN_DELAYED_NEW_POSTS', payload)
}

export function toggleFeedFavorite(payload: {
  feedId: string
  isFavorite: boolean
}) {
  return createAction('TOGGLE_FEED_FAVORITE', payload)
}

export function toggleSearchColumn() {
  return createAction('TOGGLE_SEARCH_COLUMN')
}
