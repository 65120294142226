import _ from 'lodash'
import { StyleSheet } from 'react-native'
import { sizes } from '../components/cards/BaseCard.shared'

import {
  contentPadding,
  extraLargeTextSize,
  largeTextSize,
  mutedOpacity,
  superMutedOpacity,
} from './variables'

export const sharedStyles = StyleSheet.create({
  absolute: { position: 'absolute' },
  absoluteFill: StyleSheet.absoluteFillObject,
  relative: { position: 'relative' },
  flex: { flex: 1 },
  flexGrow: { flexGrow: 1 },
  flexNoGrow: { flexGrow: 0 },
  flexWrap: { flexWrap: 'wrap' },
  flexNoWrap: { flexWrap: 'nowrap' },
  flexShrink0: { flexShrink: 0 },
  flexShrink1: { flexShrink: 1 },
  horizontal: { flexDirection: 'row' },
  horizontalReverse: { flexDirection: 'row-reverse' },
  vertical: { flexDirection: 'column' },
  verticalReverse: { flexDirection: 'column-reverse' },
  alignItemsCenter: { alignItems: 'center' },
  alignItemsFlexEnd: { alignItems: 'flex-end' },
  alignItemsFlexStart: { alignItems: 'flex-start' },
  alignSelfCenter: { alignSelf: 'center' },
  alignSelfFlexEnd: { alignSelf: 'flex-end' },
  alignSelfFlexStart: { alignSelf: 'flex-start' },
  alignSelfStretch: { alignSelf: 'stretch' },
  displayFlex: { display: 'flex' },
  displayNone: { display: 'none' },
  fullHeight: { height: '100%' },
  fullMinWidth: { minWidth: '100%' },
  fullMaxWidth: { maxWidth: '100%' },
  fullWidth: { width: '100%' },
  lineThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  justifyContentCenter: { justifyContent: 'center' },
  justifyContentFlexEnd: { justifyContent: 'flex-end' },
  justifyContentFlexStart: { justifyContent: 'flex-start' },
  justifyContentSpaceBetween: { justifyContent: 'space-between' },
  justifyContentSpaceEvenly: { justifyContent: 'space-evenly' },
  margin: { margin: contentPadding },
  marginHalf: { margin: contentPadding / 2 },
  marginHorizontal: { marginHorizontal: contentPadding },
  marginHorizontalHalf: { marginHorizontal: contentPadding / 2 },
  marginHorizontalQuarter: { marginHorizontal: contentPadding / 4 },
  marginVertical: { marginVertical: contentPadding },
  marginVerticalHalf: { marginVertical: contentPadding / 2 },
  marginVerticalQuarter: { marginVertical: contentPadding / 4 },
  marginTopQuarter: { marginTop: contentPadding / 4 },
  marginBottomHalf: { marginBottom: contentPadding / 2 },
  marginBottom: { marginBottom: contentPadding },
  marginRightHalf: { marginRight: contentPadding / 2 },
  marginLeftHalf: { marginLeft: contentPadding / 2 },
  muted: { opacity: mutedOpacity },
  superMuted: { opacity: superMutedOpacity },
  opacity0: { opacity: 0 },
  opacity100: { opacity: 1 },
  overflowHidden: { overflow: 'hidden' },
  overflowVisible: { overflow: 'visible' },
  overflowScroll: { overflow: 'scroll' },
  padding: { padding: contentPadding },
  paddingHalf: { padding: contentPadding / 2 },
  paddingHorizontal: { paddingHorizontal: contentPadding },
  paddingHorizontalHalf: { paddingHorizontal: contentPadding / 2 },
  paddingHorizontalQuarter: { paddingHorizontal: contentPadding / 4 },
  paddingHorizontalNone: { paddingHorizontal: 0 },
  paddingVertical: { paddingVertical: contentPadding },
  paddingVerticalHalf: { paddingVertical: contentPadding / 2 },
  paddingVerticalQuarter: { paddingVertical: contentPadding / 4 },
  paddingVerticalNone: { paddingVertical: 0 },
  textCenter: { textAlign: 'center' },

  center: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },

  horizontalAndVerticallyAligned: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },

  largeText: {
    fontSize: largeTextSize,
    lineHeight: sizes.largeTitleLineHeight,
  },

  extraLargeText: {
    fontSize: extraLargeTextSize,
    lineHeight: sizes.extraLargeTitleLineHeight,
  },
})
