import React, { Component } from "react";
import { View, ViewStyle } from "react-native";

interface CaptureOptions {
  width?: number;
  height?: number;
  format?: 'jpg' | 'png' | 'webm' | 'raw';
  quality?: number;
  result?: 'tmpfile' | 'base64' | 'data-uri' | 'zip-base64';
  snapshotContentContainer?: boolean;
}

interface ViewShotProperties {
  options?: CaptureOptions;
  captureMode?: 'mount' | 'continuous' | 'update';
  onCapture?(uri: string): void;
  onCaptureFailure?(error: Error): void;
  style?: ViewStyle;
}

// interface placeholder
export default class ViewShot extends Component<ViewShotProperties> {
  capture = (): Promise<string> => new Promise((resolve, reject) => {resolve('')})
  render() {
    const { children, style } = this.props;
    return (
      <View
        style={style}
      >
        {children}
      </View>
    );
  }
}
