import axios from 'axios'
import { Buffer } from 'buffer'
import { SearchMetricInput } from '../../components/metric'
import { Measurement } from './type'
import { Platform } from '../platform'

const url =
  'https://us-east-1-1.aws.cloud2.influxdata.com/api/v2/write?org=pano&bucket=metrics&precision=s'

const headers = {
  // this token is append only to the influxdb metrics bucket
  Authorization:
    'Token dRPJ-Gc_v_HiM7tPGchpnmacjvHQvRKi8hHub1jEoHc1yllJjVfoN-w3LWwiZqsB-fYDKziAC3QXzH6GuyKvfQ==',
  'Content-Type': 'text/plain; charset=utf-8',
  Accept: 'application/json',
}

/**
 * Send metrics to influxdb
 * @param input
 * @param error
 * @returns
 *
 * input.columnName could have space in it
 */
export const _sendMetrics = async (
  input: SearchMetricInput,
  error: boolean,
) => {
  try {
    const cleanedColumnName = cleanString(input.columnName)
    const cleanedKeyword = cleanString(input.keyword)
    const cleanedUserName = cleanString(input.userName)
    const data = Buffer.from(
      `${Measurement.ColumnSearch},keyword=${cleanedKeyword},column_id=${
        input.columnId
      },column_name=${cleanedColumnName},username=${cleanedUserName},platform=${
        Platform.OS
      } latency=${input.latency},result_count=${input.resultCount},success=${
        error ? 0 : 1
      },error=${error ? 1 : 0} ${Math.floor(Date.now() / 1000)}`,
    )
    return axios.post(url, data, { headers })
  } catch (err) {
    // metrics error should not break redux flow
    console.log('sendMetrics error', err)
  }
  return
}

export const sendMetrics = async (input: SearchMetricInput) => {
  return _sendMetrics(input, false)
}

export const recordError = async (input: SearchMetricInput) => {
  return _sendMetrics(input, true)
}
/**
 * Replace , and space with underscore. , and space are not allowed in influxdb measurement name
 * @param str
 */
const cleanString = (str: string) => {
  return str.replace(/,| /g, '_')
}
