import * as selectors from '../redux/selectors'
import { useReduxState } from './use-redux-state'
import { useFeed } from './use-feed'

export function useFeedCreatedByCurrentUser(feedId: string): boolean {
  const currentUserId = useReduxState(selectors.currentUserIdSelector)
  const feed = useFeed(feedId)
  return (
    // return true if there is no creator or creator is current user
    !feed?.feed?.creator?.id || feed.feed.creator.id === currentUserId
  )
}
