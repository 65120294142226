import { AppState, InteractionManager } from 'react-native'
import {
  all,
  call,
  put,
  delay,
  select,
  fork,
  takeEvery,
  takeLatest,
  take,
} from 'typed-redux-saga'
import { Task } from 'redux-saga'
import { spawn } from 'redux-saga/effects'
import axios, { AxiosResponse } from 'axios'
import { jsonToGraphQLQuery, EnumType } from 'json-to-graphql-query'

import { ColumnCreation, constants, NewsFeedColumn, Feed } from '@devhub/core'
import { COLUMN_OUT_OF_SYNC_TIME_IN_MILLI_SECOND } from '@devhub/core/src/utils/constants'

import { emitter } from '../../libs/emitter'
import * as actions from '../actions'
import * as selectors from '../selectors'
import { ExtractActionFromActionCreator } from '../types/base'
import { WrapUrlWithToken } from '../../utils/api'
import { notify } from '../../utils/notify'
import { saveViewToClipboard } from '../../libs/html-to-image'
import { COLUMN_POSTS_LIMIT } from '../../utils/constants'
import { recordError, sendMetrics } from '../../libs/influx-db'
import {
  EncodeDataExpressionFromFeedCreation,
  ExtractSubSourceIdsFromFeedCreation,
  constructColumnRequest,
  constructFetchPostByIdRequest,
  constructSearchPostsRequest,
  constructSetColumnNotificationSettingsRequest,
  constructSetFeedFavorite,
  constructSetItemsReadStatusRequest,
  convertColumnsResponseToNewsFeedPosts,
  getInitialSearchMetricsInput,
  getUpsertColumnRequest,
  getUpsertFeedRequest,
  postToNewsFeedPost,
  shouldDropExistingData,
} from '../helpers/column'
import {
  ColumnsResponse,
  FeedResponse,
  Post,
  SearchPostsResponse,
} from '../types'

function* initialRefreshAllOutdatedColumn() {
  yield* refreshAllOutdatedColumn({ notifyOnNewPosts: false })
  yield* fetchSharedColumns()
  yield* fetchFavoriteFeeds()
}

function* refreshAllOutdatedColumn({ notifyOnNewPosts = false }) {
  const allColumnsWithRefreshTimeAndNotifySetting = yield* select(
    selectors.columnsWithRefreshTimeAndNotifySettingSelector,
  )

  yield* all(
    allColumnsWithRefreshTimeAndNotifySetting.map(function* (
      columnWithRefreshTimeAndNofitySetting,
    ) {
      if (!columnWithRefreshTimeAndNofitySetting) return
      const timeDiff =
        Date.now() -
        Date.parse(columnWithRefreshTimeAndNofitySetting.refreshedAt)

      if (timeDiff < COLUMN_OUT_OF_SYNC_TIME_IN_MILLI_SECOND) {
        return
      }

      yield put(
        actions.fetchColumnDataRequest({
          notifyOnNewPosts:
            notifyOnNewPosts &&
            columnWithRefreshTimeAndNofitySetting.notifyOnNewPosts,
          columnId: columnWithRefreshTimeAndNofitySetting.id,
          direction: 'NEW',
        }),
      )
    }),
  )
}

// columnRefresher is a saga that indefinetly refresh columns if it's outdated.
function* columnRefresher() {
  while (true) {
    // Try refresh all columns every 10 seconds.
    yield delay(10 * 1000)

    // Refresh all outdated column
    yield* refreshAllOutdatedColumn({ notifyOnNewPosts: true })
  }
}

function* onAddFeed(
  action: ExtractActionFromActionCreator<typeof actions.addFeed>,
) {
  const placeHolderOrFeedId = action.payload.id
  const isUpdate = !!action.payload.isUpdate
  const placeHolderOrColumnId = action.payload.columnId
  const subscribed = yield* select(
    selectors.columnSubscribedSelector,
    action.payload.columnId,
  )

  // what does it do?
  if (AppState.currentState === 'active')
    yield* call(InteractionManager.runAfterInteractions)

  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  if (!userId) {
    yield put(actions.authFailure(Error('no user id found')))
    return
  }
  const filterDataExpressionInReq = EncodeDataExpressionFromFeedCreation(
    action.payload,
  )
  const subSourceIdsInReq = ExtractSubSourceIdsFromFeedCreation(action.payload)

  let updatedName = ''
  let updatedId = placeHolderOrFeedId
  let updatedColumnId = placeHolderOrColumnId
  let updatedFilterDataExpression = ''
  let updatedSubSources = []
  let updatedVisibility = action.payload.visibility

  try {
    const createFeedResponse: AxiosResponse = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
      {
        query: getUpsertFeedRequest(
          action.payload,
          userId,
          isUpdate,
          filterDataExpressionInReq,
          subSourceIdsInReq,
        ),
      },
    )

    const errors = createFeedResponse.data?.errors
    if (errors && errors.length > 0) {
      const errorMsg = errors[0].message
      console.error(errorMsg)
      // throw new Error(errorMsg)
    }

    const { id, columns, name, filterDataExpression, subSources, visibility } =
      createFeedResponse.data.data.upsertFeed
    updatedId = id
    updatedName = name
    updatedFilterDataExpression = filterDataExpression
    updatedSubSources = subSources
    updatedVisibility = visibility
    if (columns && columns.length > 0) {
      updatedColumnId = columns[0].id
    }
  } catch (err) {
    console.error(err)
    return
  }

  // Update feed id to be the id returned from backend. In the case of feed
  // update, this action is no-op.
  yield put(
    actions.updateFeed({
      prevId: placeHolderOrFeedId,
      updatedId,
      prevColumnId: placeHolderOrColumnId,
      updatedColumnId,
      name: updatedName,
      filterDataExpression: updatedFilterDataExpression,
      subSources: updatedSubSources,
      visibility: updatedVisibility,
      addToColumn: action.payload.addToColumn,
    }),
  )

  yield put(actions.closeAllModals())
  yield put(
    actions.pushModal({
      name: 'ADD_COLUMN_DETAILS',
      params: {
        columnId: updatedColumnId,
      },
    }),
  )
  // subscribe column only if column id is not in allColumnIds
  // corner case: create a new column, add second new feed will still give
  // subscribed is false, considering this is actually not causing any issue,
  // stil use it as a quick fix.
  if (!subscribed) {
    yield put(actions.subscribeColumn({ columnId: updatedColumnId }))
  }
}

function* onAddColumn(
  action: ExtractActionFromActionCreator<typeof actions.addColumn>,
) {
  const placeHolderOrColumnId = action.payload.id

  const isUpdate = !!action.payload.isUpdate
  const subscribeOnly = !!action.payload.subscribeOnly

  if (AppState.currentState === 'active')
    yield* call(InteractionManager.runAfterInteractions)

  emitter.emit('FOCUS_ON_COLUMN', {
    animated: true,
    columnId: placeHolderOrColumnId,
    highlight: true,
    scrollTo: true,
  })

  yield* put(actions.setColumnLoading({ columnId: placeHolderOrColumnId }))

  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  if (!userId) {
    yield put(actions.authFailure(Error('no user id found')))
    return
  }

  let updatedId = ''
  try {
    // 1. Upsert Feed and get new/old feed Id
    // For subscribe case, we reuse the columnId created by others
    if (subscribeOnly) {
      updatedId = placeHolderOrColumnId
    } else {
      const createColumnResponse: AxiosResponse = yield axios.post(
        WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
        {
          query: getUpsertColumnRequest(action.payload, userId, isUpdate),
        },
      )

      const { id } = createColumnResponse.data.data.upsertColumn
      updatedId = id
    }

    // 2. Subscribe to the column if column is subscribedOnly or it's a new column
    if (!isUpdate || subscribeOnly) {
      yield put(actions.subscribeColumn({ columnId: updatedId }))
    }
  } catch (err) {
    console.error(err)

    // Fail to create should trigger feed deletion.
    if (!isUpdate) {
      const allIds = yield* select(selectors.columnIdsSelector)
      const columnIndex = allIds.indexOf(placeHolderOrColumnId)
      yield put(
        actions.deleteColumn({ columnId: placeHolderOrColumnId, columnIndex }),
      )
    }

    return
  }

  // Update column id to be the id returned from backend. In the case of feed
  // update, this action is no-op.
  yield put(
    actions.updateColumnId({
      prevId: placeHolderOrColumnId,
      updatedId: updatedId,
    }),
  )

  // no need to fetch data here since the ColumnRenderer will
  // trigger column data fetch when it is mounted
}

function* onMoveColumn(
  action: ExtractActionFromActionCreator<typeof actions.moveColumn>,
) {
  const ids: string[] = yield* select(selectors.columnIdsSelector)
  if (!(ids && ids.length)) return

  const columnIndex = Math.max(
    0,
    Math.min(action.payload.columnIndex, ids.length - 1),
  )
  if (Number.isNaN(columnIndex)) return

  yield delay(100) // allow columns to re-render with new orders

  emitter.emit('FOCUS_ON_COLUMN', {
    animated: true,
    highlight: false,
    scrollTo: true,
    ...action.payload,
    focusOnVisibleItem: true,
  })

  // Column ordering is seedState, sync up
  yield* put(actions.syncUp())
}

function* onRemoveFeedFromColumn(
  action: ExtractActionFromActionCreator<typeof actions.removeFeedFromColumn>,
) {
  // call backend for feed deletion.
  const { columnId, feedId } = action.payload
  const column: NewsFeedColumn | undefined = yield* select(
    selectors.columnSelector,
    columnId,
  )
  if (!column) {
    console.error(`column ${columnId} does't exist`)
    return
  }
  const columnCreation: ColumnCreation = {
    ...column,
    itemListIds: [],
    feedIds: column.feedIds.filter((id) => id !== feedId),
    isUpdate: true,
  }
  try {
    // use same upsertFeed API to remove feed id from column
    // which doesn't actually delete feed from DB,
    // backend has a routine to clean isolated feeds
    yield put(actions.addColumn(columnCreation))
  } catch (e) {
    console.error(`onRemoveFeedFromColumn`, e)
  }
}

function* onDeleteColumn(
  action: ExtractActionFromActionCreator<typeof actions.deleteColumn>,
) {
  const ids: string[] = yield* select(selectors.columnIdsSelector)
  if (ids && ids.length) {
    // Fixes blank screen on Android after removing the last column.
    // If removed the last column,
    // scroll to the new last valid column
    if (action.payload.columnIndex > ids.length - 1) {
      emitter.emit('FOCUS_ON_COLUMN', {
        animated: false,
        columnId: ids[ids.length - 1],
        highlight: false,
        scrollTo: true,
      })
    }
  }

  // call backend for feed deletion.
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  try {
    const deleteColumnResponse: AxiosResponse = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
      {
        query: jsonToGraphQLQuery({
          mutation: {
            deleteColumn: {
              __args: {
                input: {
                  userId: userId,
                  columnId: action.payload.columnId,
                },
              },
              id: true,
            },
          },
        }),
      },
    )
  } catch (err) {
    // intentionally not handling delete feed error. Reason being that fail to
    // delete won't be a big problem. The feed might comeback in the next
    // seedState push, but user could just try again to force the delete.
    // Otherwise, we'll need to handle a very complex delete-reversion.
    console.error('fail to delete feed ', action.payload.columnId, err)
  }
}

function* onClearColumnOrColumns(
  action: ExtractActionFromActionCreator<
    typeof actions.setColumnClearedAtFilter | typeof actions.clearAllColumns
  >,
) {
  if (action.payload.clearedAt === null) return
  yield put(actions.cleanupArchivedItems())
}

// fetchColumnData is the unified saga that handles column request.
// There are 4 scenarios this saga is executed:
// 1. At normal feed refresh time:
// In this case, requesting updatedAt is getting from redux store, direction is
// set to NEW, and cursor is set as the largest published time of the post in feed. If the coming request
// contains ${feedRefreshLimit} items, it (most likely) means there's a gap
// between current data and returning data, and frontend should drop all
// existing data by setting ${dropExistingData}.
// 2. At normal feed load more:
// This case requesting updatedAt is getting from redux store, and direction is
// set to OLD, with cursor setting as smallest published time of the post in feed. Frontend should just
// append the incoming items to the data list of the column under action.
// 3. At column creation time:
// In this case, requesting updatedAt is undefined, and direction is set to OLD,
// with cursor setting as integer.MAX. Response is a bit slow in this case
// because it requires on-the-fly database join.
// 4. At column update time:
// Requesting with updatedAt getting from Redux store, direction is set to NEW
// and cursor is set to largest in feed. If the returning response contains
// different updatedAt, it means local feed's attributes/content is out-of-sync,
// and frontend should drop all existing data by setting ${dropExistingData} for
// the column under action.
// 5. At column successful load latest posts, if total posts number is more than
// certain number, dispatch an action to clean old posts for the column and update
// feeds cursors associated with the column
function* onFetchColumnDataRequest(
  action: ExtractActionFromActionCreator<typeof actions.fetchColumnDataRequest>,
) {
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  const column: NewsFeedColumn | undefined = yield* select(
    selectors.columnSelector,
    action.payload.columnId,
  )

  if (!column || !userId || !appToken) {
    yield put(
      actions.fetchColumnDataFailure({ columnId: action.payload.columnId }),
    )
    return
  }
  const feeds: Feed[] = yield* select(selectors.feedsSelector, column.feedIds)
  const dataByNodeId = yield* select(selectors.dataByNodeIdOrId)

  try {
    const query = constructColumnRequest(
      userId,
      column,
      feeds,
      action.payload.direction,
      dataByNodeId,
    )

    const fetchDataResponse: AxiosResponse<ColumnsResponse> = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
      {
        query,
      },
    )

    if (
      !fetchDataResponse.data.data ||
      fetchDataResponse.data.data.columns.length < 1
    ) {
      yield put(actions.fetchColumnDataFailure({ columnId: column.id }))
      return
    }

    const columnsFromResponse = fetchDataResponse.data.data.columns
    const columnFromResponse = columnsFromResponse[0]

    const [posts, delayedNewPosts] =
      convertColumnsResponseToNewsFeedPosts(columnsFromResponse)
    // trigger the web notificatiton
    if (action.payload.notifyOnNewPosts && action.payload.direction === 'NEW') {
      for (const post of posts) {
        notify(post)
      }
    }

    yield put(
      actions.fetchColumnDataSuccess({
        columnId: column.id,
        column: columnFromResponse,
        data: posts,
        delayedNewPosts,
        updatedAt: columnFromResponse.updatedAt,
        direction: action.payload.direction,
        dropExistingData: shouldDropExistingData(
          columnFromResponse,
          column.updatedAt,
          columnFromResponse.updatedAt,
          action.payload.direction,
        ),
        dataByNodeId,
        mobileNotification: columnFromResponse.mobileNotification,
        webNotification: columnFromResponse.webNotification,
        enableAppIconUnreadIndicator:
          columnFromResponse.showUnreadIndicatorOnIcon,
      }),
    )

    // trigger column posts clean which will update column itemListIds,
    // corresponding feeds itemListIds and oldest/newest items.
    // triggered action will not actually remove the post data from
    // dataById.
    const estimatedNewLength = column.itemListIds.length + posts.length
    if (
      action.payload.direction === 'NEW' &&
      estimatedNewLength > COLUMN_POSTS_LIMIT
    ) {
      yield put(actions.cleanColumnOldPosts({ columnId: column.id }))
    }
  } catch (err) {
    yield put(actions.fetchColumnDataFailure({ columnId: column.id }))
    console.error(err)
  }
}

function* onFetchSearchColumnDataRequest(
  action: ExtractActionFromActionCreator<
    typeof actions.fetchSearchPostsRequest
  >,
) {
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  const column: NewsFeedColumn | undefined = yield* select(
    selectors.columnSelector,
    action.payload.columnId,
  )

  if (!column || !userId || !appToken) {
    yield put(
      actions.fetchColumnDataFailure({ columnId: action.payload.columnId }),
    )
    return
  }
  const dataByNodeId = yield* select(selectors.dataByNodeIdOrId)

  try {
    const query = constructSearchPostsRequest(
      userId,
      column,
      action.payload.direction,
      dataByNodeId,
      true,
    )

    const searchPostsResponse: AxiosResponse<SearchPostsResponse> =
      yield axios.post(WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken), {
        query,
      })

    if (
      !searchPostsResponse.data.data ||
      !searchPostsResponse.data.data.posts
    ) {
      yield put(actions.fetchColumnDataFailure({ columnId: column.id }))
      return
    }

    const postsFromResponse = searchPostsResponse.data.data.posts
    const posts = postsFromResponse.map((post) => postToNewsFeedPost(post))

    yield put(
      actions.fetchSearchPostsSuccess({
        columnId: column.id,
        direction: action.payload.direction,
        data: posts,
        // to simplify we drop data whenever direction is NEW
        dropExistingData: action.payload.direction === 'NEW',
      }),
    )

    // trigger column posts clean which will update column itemListIds,
    // corresponding feeds itemListIds and oldest/newest items.
    // triggered action will not actually remove the post data from
    // dataById.
    if (action.payload.direction === 'NEW') {
      yield put(actions.cleanColumnOldPosts({ columnId: column.id }))
    }
  } catch (err) {
    yield put(actions.fetchColumnDataFailure({ columnId: column.id }))
    console.error(err)
  }
}

/**
 * On filter changes, including filter query, unread filter, etc. It handles NewsFeedColumn only
 * @param action
 * @returns
 */
function* onReplaceColumnFilter(
  action: ExtractActionFromActionCreator<typeof actions.replaceColumnFilters>,
) {
  const column: NewsFeedColumn | undefined = yield* select(
    selectors.columnSelector,
    action.payload.columnId,
  )
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)

  if (!column || !userId || !appToken) {
    yield put(
      actions.fetchColumnDataFailure({ columnId: action.payload.columnId }),
    )
    return
  }

  const feeds: Feed[] = yield* select(selectors.feedsSelector, column.feedIds)
  const dataByNodeId = yield* select(selectors.dataByNodeIdOrId)
  const searchQuery = action.payload.filter.query
  const startTime = Date.now()
  const userName = yield* select(selectors.currentUserNameSelector)

  const searchMetricsInput = getInitialSearchMetricsInput(
    userId,
    column.id,
    column.title,
    searchQuery,
    userName,
  )
  try {
    const query = constructColumnRequest(
      userId,
      column,
      feeds,
      'NEW',
      dataByNodeId,
      true,
    )

    yield put(actions.setColumnLoading({ columnId: action.payload.columnId }))
    const fetchDataResponse: AxiosResponse<ColumnsResponse> = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
      {
        query,
      },
    )

    searchMetricsInput.latency = Date.now() - startTime
    if (
      !fetchDataResponse.data.data ||
      fetchDataResponse.data.data.columns.length < 1
    ) {
      if (searchQuery) {
        yield recordError(searchMetricsInput)
      }
      yield put(actions.fetchColumnDataFailure({ columnId: column.id }))
      return
    }

    const columnsFromResponse = fetchDataResponse.data.data.columns
    const columnFromResponse = columnsFromResponse[0]

    const [posts, delayedNewPosts] =
      convertColumnsResponseToNewsFeedPosts(columnsFromResponse)

    yield put(
      actions.fetchColumnDataSuccess({
        columnId: column.id,
        column: columnFromResponse,
        data: posts,
        delayedNewPosts,
        updatedAt: columnFromResponse.updatedAt,
        direction: 'NEW', // use NEW since filter query changed
        dropExistingData: true, // always drop since filter query changed
        dataByNodeId,
        mobileNotification: column.options.mobileNotification,
        webNotification: column.options.webNotification,
        enableAppIconUnreadIndicator:
          column.options.enableAppIconUnreadIndicator,
      }),
    )
    if (searchQuery) {
      searchMetricsInput.resultCount = posts.length
      yield sendMetrics(searchMetricsInput)
    }
  } catch (err) {
    yield recordError(searchMetricsInput)
    console.error(err)
  }
}

/**
 * On filter changes, including filter query, unread filter, etc. It handles SearchPostsColumn only
 * @param action
 * @returns
 */
function* onReplaceSearchColumnFilter(
  action: ExtractActionFromActionCreator<
    typeof actions.replaceSearchColumnFilters
  >,
) {
  const column: NewsFeedColumn | undefined = yield* select(
    selectors.columnSelector,
    action.payload.columnId,
  )
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)

  if (!column || !userId || !appToken) {
    yield put(
      actions.fetchColumnDataFailure({ columnId: action.payload.columnId }),
    )
    return
  }

  const dataByNodeId = yield* select(selectors.dataByNodeIdOrId)
  const searchQuery = action.payload.filter.query
  const startTime = Date.now()
  const userName = yield* select(selectors.currentUserNameSelector)

  const searchMetricsInput = getInitialSearchMetricsInput(
    userId,
    column.id,
    column.title,
    searchQuery,
    userName,
  )

  if (!searchQuery) {
    console.warn('search query is empty')
    return
  }

  try {
    const query = constructSearchPostsRequest(
      userId,
      column,
      'NEW',
      dataByNodeId,
      true, // reset cursor
    )

    yield put(actions.setColumnLoading({ columnId: action.payload.columnId }))
    const searchPostsResponse: AxiosResponse<SearchPostsResponse> =
      yield axios.post(WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken), {
        query,
      })
    searchMetricsInput.latency = Date.now() - startTime

    // search posts response is different from newsfeed column response, it only contains posts
    if (
      !searchPostsResponse.data.data ||
      !searchPostsResponse.data.data.posts
    ) {
      if (searchQuery) {
        yield recordError(searchMetricsInput)
      }
      yield put(actions.fetchColumnDataFailure({ columnId: column.id }))
      return
    }

    const postsFromResponse = searchPostsResponse.data.data.posts

    const posts = postsFromResponse.map((post) => postToNewsFeedPost(post))

    yield put(
      actions.fetchSearchPostsSuccess({
        columnId: column.id,
        direction: 'NEW', // use NEW since filter query changed
        data: posts,
        dropExistingData: true, // always drop since filter query changed
      }),
    )
    if (searchQuery) {
      searchMetricsInput.resultCount = posts.length
      yield sendMetrics(searchMetricsInput)
    }
  } catch (err) {
    yield recordError(searchMetricsInput)
    console.error(err)
  }
}

function* fetchFavoriteFeeds() {
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  try {
    const favoriteFeeds: AxiosResponse = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
      {
        query: jsonToGraphQLQuery({
          query: {
            favoriteFeeds: {
              __args: {
                input: {
                  userId,
                },
              },
              id: true,
              name: true,
              filterDataExpression: true,
              subSources: {
                id: true,
                name: true,
                source: {
                  id: true,
                },
                externalIdentifier: true,
              },
              creator: {
                id: true,
                name: true,
              },
              updatedAt: true,
            },
          },
        }),
      },
    )
    yield put(
      actions.setFavoriteFeeds({
        favoriteFeeds: favoriteFeeds.data.data.favoriteFeeds,
      }),
    )
  } catch (err) {
    console.error(err)
  }
}

function* fetchSharedColumns() {
  const appToken = yield* select(selectors.appTokenSelector)
  try {
    const visibleColumns: AxiosResponse = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
      {
        // todo use columns
        query: jsonToGraphQLQuery({
          query: {
            allVisibleColumns: {
              id: true,
              name: true,
              creator: {
                id: true,
                name: true,
              },
              updatedAt: true,
              feeds: {
                id: true,
                name: true,
                filterDataExpression: true,
                subSources: {
                  id: true,
                  name: true,
                  source: {
                    id: true,
                  },
                  externalIdentifier: true,
                },
                creator: {
                  id: true,
                  name: true,
                },
                updatedAt: true,
                visibility: true,
              },
              visibility: true,
              subscriberCount: true,
            },
          },
        }),
      },
    )

    const feedIdSet = new Set<string>()
    const feeds: FeedResponse[] = []
    const columns = visibleColumns.data.data.allVisibleColumns?.map(
      (f: any) => {
        const columnFeeds: FeedResponse[] = f.feeds
        columnFeeds.forEach((columnFeed) => {
          if (!feedIdSet.has(columnFeed.id)) {
            feedIdSet.add(columnFeed.id)
            feeds.push(columnFeed)
          }
        })
        return {
          ...f,
          title: f.name,
          type: 'COLUMN_TYPE_NEWS_FEED',
          icon: {
            family: 'material',
            name: 'rss-feed',
          },
          creator: f.creator,
          feedIds: columnFeeds.map((feed) => feed.id),
          refreshedAt: '',
          state: 'not_loaded',
          options: { enableAppIconUnreadIndicator: true },
          subscriberCount: f.subscriberCount,
        }
      },
    )

    yield put(
      actions.setSharedColumns({
        columns,
        feeds,
      }),
    )
  } catch (err) {
    console.error(err)
  }
}

const DEFAULT_ERROR_MESSAGE = 'Failed to save to clipboard'
const DEFAULT_SUCCESS_MESSAGE = 'Copied to clipboard'

function* onCaptureItemView(
  action: ExtractActionFromActionCreator<typeof actions.capatureView>,
) {
  try {
    yield delay(50) // wait for potential show more rerender
    yield saveViewToClipboard(action.payload.viewRef)
    yield put(
      actions.capatureViewCompleted({
        itemNodeId: action.payload.itemNodeId,
      }),
    )
    yield put(
      actions.setBannerMessage({
        id: 'clipboard',
        type: 'BANNER_TYPE_SUCCESS',
        message: DEFAULT_SUCCESS_MESSAGE,
        autoClose: true,
      }),
    )
  } catch (e) {
    let message = DEFAULT_ERROR_MESSAGE
    if (e instanceof Error) {
      message = e.message
    }
    yield put(
      actions.capatureViewCompleted({
        itemNodeId: action.payload.itemNodeId,
      }),
    )
    yield put(
      actions.setBannerMessage({
        id: 'clipboard',
        type: 'BANNER_TYPE_ERROR',
        message: message,
        autoClose: true,
      }),
    )
  }
}

function* onFetchPostById(
  action: ExtractActionFromActionCreator<typeof actions.fetchPost>,
) {
  const id = action.payload.id
  const appToken = yield* select(selectors.appTokenSelector)

  try {
    const response: AxiosResponse = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken || ''),
      { query: constructFetchPostByIdRequest(id) },
    )
    const post: Post = response.data.data.post
    const data = postToNewsFeedPost(post)
    yield put(
      actions.fetchPostSuccess({
        id,
        data,
      }),
    )
  } catch (e) {
    yield put(
      actions.fetchPostFailure({
        id,
      }),
    )
    console.error(e)
  }
}

function* onToggleFeedFavorite(
  action: ExtractActionFromActionCreator<typeof actions.toggleFeedFavorite>,
) {
  const { feedId, isFavorite } = action.payload
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  if (!userId) {
    yield put(actions.authFailure(Error('no user id found')))
    return
  }

  try {
    yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken || ''),
      {
        query: constructSetFeedFavorite(feedId, userId, isFavorite),
      },
    )
  } catch (e) {
    console.error(e)
  }
}

function* onSetItemsReadStatus(
  action: ExtractActionFromActionCreator<typeof actions.setItemsReadStatus>,
) {
  if (!action.payload.syncup) {
    return
  }

  const { itemNodeIds, read } = action.payload
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  if (!userId) {
    yield put(actions.authFailure(Error('no user id found')))
    return
  }

  try {
    yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken || ''),
      {
        query: constructSetItemsReadStatusRequest(
          itemNodeIds,
          userId,
          read,
          new EnumType('POST'),
        ),
      },
    )
  } catch (e) {
    console.error(e)
  }
}

function* onsetItemDuplicationReadStatus(
  action: ExtractActionFromActionCreator<
    typeof actions.setItemDuplicationReadStatus
  >,
) {
  if (!action.payload.syncup) {
    return
  }

  const itemNodeId = action.payload.itemNodeId
  const read = action.payload.read
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  if (!userId) {
    yield put(actions.authFailure(Error('no user id found')))
    return
  }

  try {
    yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken || ''),
      {
        query: constructSetItemsReadStatusRequest(
          [itemNodeId],
          userId,
          read,
          new EnumType('DUPLICATION'),
        ),
      },
    )
  } catch (e) {
    console.error(e)
  }
}

function* onSetColumnNotificationSettings(
  action: ExtractActionFromActionCreator<
    typeof actions.setColumnNotificationSettingsRequested
  >,
) {
  const {
    columnId,
    enableMobileNotification,
    enableWebNotification,
    enableAppIconUnreadIndicator,
  } = action.payload
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  if (!userId) {
    yield put(actions.authFailure(Error('no user id found')))
    return
  }
  try {
    yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken || ''),
      {
        query: constructSetColumnNotificationSettingsRequest(
          columnId,
          userId,
          enableMobileNotification,
          enableWebNotification,
          enableAppIconUnreadIndicator,
        ),
      },
    )
    yield put(
      // set values from the request, assuming backend update it successfully
      actions.setColumnNotificationSettingsSuccess({
        columnId,
        enableMobileNotification,
        enableWebNotification,
        enableAppIconUnreadIndicator,
      }),
    )
  } catch (e) {
    console.error(e)
  }
}

function* onSubscribeColumn(
  action: ExtractActionFromActionCreator<typeof actions.subscribeColumn>,
) {
  const { columnId } = action.payload
  const appToken = yield* select(selectors.appTokenSelector)
  const userId = yield* select(selectors.currentUserIdSelector)
  try {
    const subscribeFeedResponse: AxiosResponse = yield axios.post(
      WrapUrlWithToken(constants.GRAPHQL_ENDPOINT, appToken),
      {
        query: jsonToGraphQLQuery({
          mutation: {
            subscribe: {
              __args: {
                input: {
                  userId,
                  columnId,
                },
              },
              id: true,
              name: true,
            },
          },
        }),
      },
    )
  } catch (e) {
    console.error(e)
  }
}

export function* onColumnFetchSuccess() {
  yield put(actions.cleanUnusedPostsData())
}

/**
 * It watches for the FETCH_COLUMN_DATA_REQUEST action and starts a new task for each columnId.
 * If the task is already running, it will not start a new one. Unless the force flag is set to true.
 * @returns
 */
function* watchFetchColumnDataRequest(): Generator<
  any,
  void,
  ExtractActionFromActionCreator<typeof actions.fetchColumnDataRequest>
> {
  // Store the ongoing tasks for each columnId
  const columnNewDirectionTasks: Record<string, any> = {}
  const columnOldDirectionTasks: Record<string, any> = {}

  while (true) {
    // Wait for the FETCH_COLUMN_DATA_REQUEST action
    const action: ExtractActionFromActionCreator<
      typeof actions.fetchColumnDataRequest
    > = yield take('FETCH_COLUMN_DATA_REQUEST')
    const { columnId, direction, force } = action.payload
    const columnTasks =
      direction === 'NEW' ? columnNewDirectionTasks : columnOldDirectionTasks

    if (
      columnTasks[columnId] &&
      (columnTasks[columnId] as Task).isRunning?.()
    ) {
      if (force) {
        // Cancel the ongoing task before starting a new one
        ;(columnTasks[columnId] as Task).cancel?.()
        console.log(
          `Column ${columnId} is already loading data with direction ${action.payload.direction}, force to cancel it`,
        )
      } else {
        // do nothing and return
        console.log(
          `Column ${columnId} is already loading data with direction ${action.payload.direction}`,
        )
        continue
      }
    }

    // Start a new task for fetching the column data
    // fork will still block receiving the next action somehow, so we use spawn here
    const task = yield spawn(onFetchColumnDataRequest, action)
    columnTasks[columnId] = task
  }
}

export function* columnsSagas() {
  yield* all([
    yield* fork(columnRefresher),
    yield* takeEvery('UPDATE_SEED_STATE', initialRefreshAllOutdatedColumn),
    yield* takeEvery('ADD_COLUMN', onAddColumn),
    yield* takeEvery('ADD_FEED', onAddFeed),
    watchFetchColumnDataRequest(),
    yield* takeLatest('FETCH_COLUMN_DATA_SUCCESS', onColumnFetchSuccess),
    yield* takeEvery('REPLACE_COLUMN_FILTER', onReplaceColumnFilter),
    yield* takeEvery(
      'REPLACE_SEARCH_COLUMN_FILTER',
      onReplaceSearchColumnFilter,
    ),
    yield* takeLatest(
      'FETCH_SEARCH_POSTS_REQUEST',
      onFetchSearchColumnDataRequest,
    ),
    yield* takeEvery('MOVE_COLUMN', onMoveColumn),
    yield* takeEvery('DELETE_COLUMN', onDeleteColumn),
    yield* takeEvery('REMOVE_FEED_FROM_COLUMN', onRemoveFeedFromColumn),
    yield* takeEvery('TOGGLE_FEED_FAVORITE', onToggleFeedFavorite),
    yield* takeLatest(
      ['SET_COLUMN_CLEARED_AT_FILTER', 'CLEAR_ALL_COLUMNS'],
      onClearColumnOrColumns,
    ),
    yield* takeLatest('SUBSCRIBE_COLUMN', onSubscribeColumn),
    yield* takeLatest('CAPTURE_VIEW', onCaptureItemView),
    yield* takeEvery('FETCH_POST', onFetchPostById),
    yield* takeEvery('SET_ITEMS_READ_STATUS', onSetItemsReadStatus),
    yield* takeEvery(
      'SET_ITEM_DUPLICATION_READ_STATUS',
      onsetItemDuplicationReadStatus,
    ),
    yield* takeEvery(
      'SET_COLUMN_NOTIFICATION_SETTINGS_REQUESTED',
      onSetColumnNotificationSettings,
    ),
  ])
}
