import CameraRoll from '../../libs/react-native-community/cameraroll'
import { all, put, takeEvery } from 'typed-redux-saga'
import { saveImageToAlbum, setBannerMessage } from '../actions'
import { ExtractActionFromActionCreator } from '../types/base'

const DEFAULT_ALBUM_SUCCESS_MESSAGE = 'Saved to album'
const DEFAULT_ALBUM_FAILURE_MESSAGE = 'Failed to save to album'

function* onSaveImageToAlbum(
  action: ExtractActionFromActionCreator<typeof saveImageToAlbum>,
) {
  const { uri } = action.payload
  try {
    yield CameraRoll.save(uri)
    yield put(
      setBannerMessage({
        id: 'album',
        type: 'BANNER_TYPE_SUCCESS',
        message: DEFAULT_ALBUM_SUCCESS_MESSAGE,
        autoClose: true,
      }),
    )
  } catch (e) {
    let message = DEFAULT_ALBUM_FAILURE_MESSAGE
    if (e instanceof Error) {
      message = e.message
    }
    yield put(
      setBannerMessage({
        id: 'clipboard',
        type: 'BANNER_TYPE_ERROR',
        message: message,
        autoClose: true,
      }),
    )
  }
}

export function* dataSagas() {
  yield* all([yield* takeEvery('SAVE_IMAGE_TO_ALBUM', onSaveImageToAlbum)])
}
